import { TransitionDirection } from "../scripts/transition-factory";
import { TimelineMax } from "gsap";

export function fadeTransition(obj, dir) {
  if (dir === TransitionDirection.ENTER) {
    enter(obj);
  } else {
    leave(obj);
  }
};

function enter(obj) {
  var tl = new TimelineMax();
  tl.to(obj, 1, {opacity: 1});
};

function leave(obj) {
  var tl = new TimelineMax();
  tl.to(obj, 1, {opacity: 0});
};