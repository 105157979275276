export const DataSource = {
  __responseJson: null,
  __fetchInProgress: null,

  init(url) {
    this.url = url;
  },

  fetch(data) {
    if (this.__responseJson === null) {
      return new Promise(resolve => {
        var fetchPromise = this.__fetchInProgress || fetch(this.url);
        this.__fetchInProgress = this.__fetchInProgress || fetchPromise;
        fetchPromise.then(response => response.clone().json()).then(json => { this.__responseJson = json; resolve(data ? json[data] : json); });
      });
    }
    
    return Promise.resolve(data ? this.__responseJson[data] : this.__responseJson);
  }
};