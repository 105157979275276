export function getColor(value, min, max, opacity, dark) {
  const colors =
  dark
  ? [`rgba(69,0,6,${opacity})`, `rgba(86,3,1,${opacity})`, `rgba(82,25,0,${opacity})`, `rgba(76,47,0,${opacity})`, `rgba(77,77,4,${opacity})`, `rgba(40,91,9,${opacity})`, `rgba(0,75,22,${opacity})`, `rgba(0,63,19,${opacity})`]
  : [`rgba(232,0,22,${opacity})`, `rgba(251,48,43,${opacity})`, `rgba(254,93,22,${opacity})`, `rgba(255,158,0,${opacity})`, `rgba(242,242,30,${opacity})`, `rgba(152,239,98,${opacity})`, `rgba(1,251,76,${opacity})`, `rgba(1,211,64,${opacity})`];
  const range = max - min;
  value -= min;
  return colors[Math.min(colors.length - 1, Math.max(0, Math.round(value/range * (colors.length -1))))];
}

export function getContrastYIQ(color, opacity) {
  let r, g, b, a;
  if (typeof color !== 'string') {
    r = color[0];
    g = color[1];
    b = color[2];
    a = color[3];
  } else {
    if (color.startsWith('rgb')) {
      const parts = color.substr(color.indexOf('(') + 1, color.indexOf(')') - 1).split(',');
      r = parseInt(parts[0]);
      g = parseInt(parts[1]);
      b = parseInt(parts[2]);
      a = parts.length > 3 ? parseFloat(parts[3]) : 1.00;
    } else if (color.startsWith('#')) {
      r = parseInt(color.substring(1, 3), 16);
      g = parseInt(color.substring(3, 5), 16);
      b = parseInt(color.substring(5, 7), 16);
      a = 1.00;
    } else {
      r = parseInt(color.substring(0, 2), 16);
      g = parseInt(color.substring(2, 4), 16);
      b = parseInt(color.substring(4, 6), 16);
      a = 1.00;
    }
  }

  if (a <= 0.5) {
    return `rgba(0, 0, 0, ${opacity})`;
  }
  let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 160) ? `rgba(0, 0, 0, ${opacity})` : `rgba(255, 255, 255, ${opacity})`;
}

export function splitIntoLines(txtElement) {
  var lines = [];
  var currentLine;
  var lastY = -1;
  txtElement.childNodes.forEach((node, index) => {
    var rect = node.getBoundingClientRect();

    if (rect.top > lastY) {
      lastY = rect.top;
      currentLine && lines.push(currentLine);
      currentLine = [];
    }

    if (currentLine) {
      currentLine.push(node);

      if (index === txtElement.childNodes.length - 1) {
        lines.push(currentLine);
      }
    }
  });

  return lines;
}

export function get2DCoords(position, camera, width, height) {
  var vector = position.project(camera);
  vector.x = (vector.x + 1) / 2 * width;
  vector.y = -(vector.y - 1) / 2 * height;
  return vector;
}

export function isMobile() {
  try{ document.createEvent("TouchEvent"); return true; }
  catch(e){ return false; }
}

export function isPortrait() {
  return window.matchMedia("(orientation: portrait)").matches;
}

let timeouts = [];
export function timeout(fn, delay) {
  const id = setTimeout(fn, delay);
  if (delay) {
    timeouts.push(id);
  }
  return id;
}

export function clearAllTimeouts() {
  timeouts.forEach(timeout => clearTimeout(timeout));
}

export function blackOrWhiteColor() {
  return 'black';
  // return window.location.href.indexOf('?white') > -1 ? 'black' : 'white';
}

export function blackOrWhiteBackground() {
  return 'white';
  // return window.location.href.indexOf('?white') > -1 ? 'white' : 'black';
}

export function blackOrWhiteBackgroundWithOpacity(opacity) {
  return `rgba(255, 255, 255, ${opacity})`;
  // return window.location.href.indexOf('?white') > -1 ? `rgba(255, 255, 255, ${opacity})` : `rgba(0, 0, 0, ${opacity})`;
}

export function blackOrWhiteColorWithOpacity(opacity) {
  return `rgba(0, 0, 0, ${opacity})`;
  // return window.location.href.indexOf('?white') > -1 ? `rgba(0, 0, 0, ${opacity})` : `rgba(255, 255, 255, ${opacity})`;
}

export function stockOrFund() {
  return window.location.href.indexOf('-fund') > -1;
}