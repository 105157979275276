import './typewriter.animation.scss';
import { TimelineMax } from 'gsap';
import { timeout } from '../../scripts/util';

export function typewriterAnimation(txtElement, opts) {
  opts = opts || {};
  opts.delay = opts.delay || 1;
  opts.letterDelay = opts.letterDelay || 20;
  txtElement.innerHTML = txtElement.innerText.replace(/([^\x00-\x80]|.)/g, "<span class='typewriter-letter'>$&</span>");

  timeout(() => {
    var i = 0;
    txtElement.childNodes.forEach(node => {
      animate(node, opts.letterDelay * i++);
    });
  }, opts.delay);
};

function animate(node, delay) {
  timeout(() => {
    var tl = new TimelineMax();
    tl.to(node, 0.5, {opacity: 1}, '0');
    tl.to(node, 0.1, {top: 0}, '0');
  }, delay);
}