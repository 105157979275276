import { GTAGHandler } from "./gtaghandler";

export function dropdown(select, noremove) {
  let minWidth = 0;
  let selectedIndex;
  let full = select.hasAttribute('full');
  const onItemRemovedCallbacks = [];
  const onSelectToggledCallbacks = [];
  let clickHandler = () => {
    api.toggle();
  };
  const keyHandler = (event) => {
    if (selectedIndex !== undefined) {
      items.children[selectedIndex].className = 'dropdown-item-wrapper';
    }
    if (event.keyCode === 40) {
      if (selectedIndex === undefined) {
        selectedIndex = 0;
      } else {
        selectedIndex++;
        if (selectedIndex === items.children.length) {
          selectedIndex = 0;
        }
      }
    } else if (event.keyCode === 38) {
      if (selectedIndex === undefined) {
        selectedIndex = 0;
      } else {
        selectedIndex--;
        if (selectedIndex === -1) {
          selectedIndex = items.children.length - 1;
        }
      }
    } else if (selectedIndex !== undefined && event.keyCode === 13) {
      items.children[selectedIndex].click();
    }
    if (selectedIndex !== undefined) {
      items.children[selectedIndex].className = 'dropdown-item-wrapper selected';
    }
  };
  const wrapper = document.createElement('div');
  wrapper.className = 'dropdown-wrapper';
  wrapper.id = `${select.id}-wrapper`;
  wrapper.style.maxWidth = full ? '' : '270px';
  const selectedItem = document.createElement('div');
  selectedItem.className = `dropdown-selected-item ${full ? 'full' : ''}`;
  selectedItem.addEventListener('click', (e) => {
    e.stopImmediatePropagation();
    api.toggle();
  });
  wrapper.append(selectedItem);
  const items = document.createElement('div');
  items.className = 'dropdown-item-list';
  //items.style.display = 'none';
  wrapper.append(items);
  select.parentNode.append(wrapper);
  
  select.style.display = 'none';
  let height = 0;

  const api = {
    remove: (idx) => {
      delete select.options[idx];
      select.value = select.childNodes[select.selectedIndex].innerHTML;
      minWidth = 0;
      items.style.minWidth = full ? `${select.parentNode.offsetWidth - 10}px` : '0px';
      setTimeout(() => {
        api.createDropdown(true, true);
        const wrapperRect = wrapper.getBoundingClientRect();
        items.style.minWidth = full ? `${select.parentNode.offsetWidth - 10}px` : `${wrapper.offsetWidth}px`;
        //items.style.top = `${wrapperRect.y + wrapperRect.height}px`;
        //items.style.left = `${wrapperRect.x}px`;
        api.toggle();
      });
      onItemRemovedCallbacks.forEach(callbackFn => callbackFn(idx));
    },

    createDropdown: (selectFirst, resetHeight) => {
      height = resetHeight ? 0 : height;
      const tempHeight = items.style.height || '0';
      items.style.height = '';
      //items.style.display = 'block';
      items.innerHTML = '';
      let first;
      for (let idx = 0; idx < select.options.length; idx++) {
        const node = select.options[idx];
        const item = document.createElement('div');
        if (idx === 0) {
          first = item;
        }
        item.className = 'dropdown-item-wrapper';
        item.innerHTML = `
          <div class="dropdown-item-label ${full ? 'full' : ''}">${node.innerHTML}</div>
          <span style="flex: 1 auto"></span>
          <div class="dropdown-item-remove-btn">
            <i class="material-icons">remove_circle_outline</i>
          </div>
        `;
        items.appendChild(item);
        item.addEventListener('click', (e) => {
          e.stopImmediatePropagation();
          select.value = node.innerHTML;
          let event = document.createEvent('Event');
          event.initEvent('change', false, true);
          select.dispatchEvent(event);
          api.toggle();
        });
        minWidth = full ? select.parentNode.offsetWidth : Math.max(minWidth, item.offsetWidth - 10);
        const removeBtn = item.getElementsByClassName('dropdown-item-remove-btn')[0];
        if (noremove) {
          removeBtn.style.display = 'none';
        }
        removeBtn.addEventListener('click', (e) => {
          GTAGHandler.addClickEvent(e);
          e.stopImmediatePropagation();
          api.remove(idx);
        });
      };
      if (selectFirst) {
        setTimeout(() => first.click());
      }
      selectedItem.innerHTML = `<span>${select.value}</span><span style="flex: 1 auto"></span><span>▾</span>`;
      //minWidth = Math.max(minWidth, selectedItem.offsetWidth);
      selectedItem.style.minWidth = full ? `${select.parentNode.offsetWidth - 10}px` : `${minWidth}px`;
      height = height || items.offsetHeight;
      items.style.height = tempHeight;
      wrapper.style.minWidth = full ? `${select.parentNode.offsetWidth}px` : '';
    },

    toggle: () => {
      onSelectToggledCallbacks.forEach(callbackFn => callbackFn(parseInt(items.style.height) !== 0));
      if (parseInt(items.style.height) === 0) {
        const wrapperRect = wrapper.getBoundingClientRect();
        items.style.top = `${wrapperRect.y + wrapperRect.height}px`;
        items.style.left = `${wrapperRect.x}px`;
        items.style.height = `${height}px`;
        window.addEventListener('click', clickHandler);
        window.addEventListener('keyup', keyHandler);
      } else {
        window.removeEventListener('click', clickHandler);
        window.removeEventListener('keyup', keyHandler);
        items.style.height = '0';
      }
      selectedItem.innerHTML = `<span>${select.value}</span><span style="flex: 1 auto"></span><span>${parseInt(items.style.height) === 0 ? '▾' : '▴'}</span>`;
      items.style.minWidth = full ? `${select.parentNode.offsetWidth - 10}px` : `${wrapper.offsetWidth}px`;
    },

    onItemRemoved: (callbackFn) => {
      onItemRemovedCallbacks.push(callbackFn);
    },

    onSelectToggled: (callbackFn) => {
      onSelectToggledCallbacks.push(callbackFn);
    },

    isOpen: () => {
      console.log(parseInt(items.style.height));
      return parseInt(items.style.height) !== 0;
    }
  };

  api.createDropdown();

  return api;
}
