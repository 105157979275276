import { getContrastYIQ } from "../../scripts/util";

export function Ball({color, logo, weight, endrotation, growth, bottom, right}, space, sumWeight) {
	this.color = color;
	this.logo = logo;
	this.growth = growth;
	this.weightTxt = weight;
	this.weight = parseFloat(weight);
	this.weight = this.weight / sumWeight;
	this.bottom = bottom;
	this.right = right;

	this.position = {x: space, y: -space}; //m
	this.calcAndSetRadius(space);

	// fixed values
	this.velocity = {x: 0, y: 0}; // m/s
	this.mass = 1/this.radius * 100000; //kg
	this.area = 0.05; //(Math.PI * radius * radius) / 10000; //m^2
	this.rotation = 0;
	this.endrotationDegree = endrotation;

	return this;
}

Ball.prototype.toHtmlElement = function() {
	this.htmlElement = document.createElement('div');
	this.htmlElement.classList.add('ball');
	this.setHtmlElementStyle();
	var wrapDiv = document.createElement('div');
	wrapDiv.className = 'vertical-center';

	if (this.radius > 200) {
		wrapDiv.classList.add('inline');
	}

	var imageDiv = document.createElement('div');
	var imageWidth = Math.min(this.radius / 2, 100);
	imageDiv.style.backgroundImage = `url(${this.logo})`;
	imageDiv.classList.add('image');
	imageDiv.style.width = imageWidth + 'px';
	imageDiv.style.height = imageWidth + 'px';
	wrapDiv.appendChild(imageDiv);

	var textWrapDiv = document.createElement('div');
	textWrapDiv.className = 'text-wrap';
	textWrapDiv.style.color = getContrastYIQ(this.color, 1);

	var textDiv = document.createElement('div');
	textDiv.innerHTML = 'Sector weight';
	textDiv.className = "weight-text";
	textWrapDiv.appendChild(textDiv);
	var innerTextDiv = document.createElement('div');
	innerTextDiv.innerHTML = this.weightTxt;
	innerTextDiv.className = "weight-value";
	textWrapDiv.appendChild(innerTextDiv);

	var textDiv2 = document.createElement('div');
	textDiv2.innerHTML = 'Growth exposure';
	textDiv2.className = 'growth-text';
	textWrapDiv.appendChild(textDiv2);
	var innerTextDiv2 = document.createElement('div');
	innerTextDiv2.innerHTML = this.growth;
	innerTextDiv2.className = 'growth-value';
	textWrapDiv.appendChild(innerTextDiv2);
	
	wrapDiv.appendChild(textWrapDiv);
	this.htmlElement.appendChild(wrapDiv);

	return this.htmlElement;
};

Ball.prototype.setHtmlElementStyle = function () {
	this.htmlElement.style.width = `${this.radius * 2}px`;
	this.htmlElement.style.height = `${this.radius * 2}px`;
	this.htmlElement.style.backgroundColor = this.color;
	this.htmlElement.style.bottom = `${this.bottom * this.coeff}px`;
	this.htmlElement.style.right = `${this.right * this.coeff}px`;
};

Ball.prototype.calcAndSetRadius = function(space) {
	this.radius = Math.max(this.weight*space, 50);
	this.radius = Math.min(this.radius, 250*window.innerWidth/1920);
	this.coeff = window.innerWidth/1920;
};