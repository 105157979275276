import './lift-up.animation.scss';
import { splitIntoLines, timeout } from '../../scripts/util';

export function liftUpAnimation(txtElement, opts) {
  opts = opts || {};
  opts.delay = opts.delay || 1;
  opts.splitLines = opts.splitLines || false;
  opts.removeActiveClassDelay = opts.removeActiveClassDelay || 500;

  if (opts.splitLines) {
    animateLines(txtElement, opts);
  } else {
    animateBlock(txtElement, opts);
  }
};

function animateLines(txtElement, opts) {
  txtElement.innerHTML = txtElement.innerHTML.replace(/([^\x00-\x80]|.)/g, "<span>$&</span>");
  var lines = doLineSplit(txtElement);
  for (var i = 0; i < lines.length; i++) {
    var line = lines[i];
    addLineAnimation(line, i * 200, opts);
  }

  timeout(() => {
    txtElement.classList.add("lift-up-complete");
  }, lines.length * 200);

  window.addEventListener('resize', () => {
    txtElement.innerHTML = txtElement.innerText.replace(/(?:\r\n|\r|\n)/g, " ").replace(/([^\x00-\x80]|.)/g, "<span>$&</span>");
    var animated = txtElement.classList.contains('lift-up-complete');
    var lines = doLineSplit(txtElement);
    for (var i = 0; i < lines.length; i++) {
      var line = lines[i];
      if (animated) {
        doAnimation(line, { delay: 0 }, true);
      } else {
        addLineAnimation(line, i*200, opts);
      }
    }
  });
}

function addLineAnimation(line, lineDelay, opts) {
  timeout(() => {
    doAnimation(line, opts);
  }, lineDelay);
}

function animateBlock(txtElement, opts) {
  txtElement.classList && txtElement.classList.add('lift-up-text');
  timeout(() => {
    txtElement.style.visibility = 'visible';
    doAnimation(txtElement, opts);
  }, opts.delay);
  removeAnimationActiveClass(txtElement, opts);
  txtElement.classList.add("lift-up-complete");
}

function doAnimation(node, opts, immediate = false) {
  timeout(() => {
    var tl = new TimelineMax();
    tl.to(node, immediate ? 0 : 0.4, {top: 0, opacity: 1});
  }, opts.delay);
}

function removeAnimationActiveClass(txtElement, opts) {
  timeout(() => {
    txtElement.classList && txtElement.classList.remove('animation-active');
  }, opts.delay + opts.removeActiveClassDelay);
}

function doLineSplit(txtElement) {
  var lines = splitIntoLines(txtElement);
  var divLines = [];
  lines.forEach(line => {
    var div = document.createElement('div');
    div.classList.add('lift-up-text');
    line.forEach(letter => {
      div.appendChild(letter);
    });
    divLines.push(div);
  });
  
  txtElement.innerHTML = "";
  divLines.forEach(div => {
    txtElement.appendChild(div);
  });

  return divLines;
}