const features = [window.fetch, Array.prototype.forEach, window.NodeList, NodeList.prototype.forEach, Float32Array, (webglSupport() || undefined)];

export const BrowserChecker = {
  check() {
    return new Promise((resolve, reject) => {
      
      if (features.indexOf(undefined) > -1) {
        reject(new Error('unsupported browser'));
        return;
      }

      resolve();
    });
  }
};

function webglSupport() { 
  try {
    if (!window.WebGLRenderingContext) {
      return false;
    }

    var canvas = document.createElement('canvas'); 
    return canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  } catch(e) {
    return false;
  }
};

export const msieversion = () => {

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return ua.substring(msie + 5, ua.indexOf(".", msie));
  }
  
  return false;
};
