import { Director } from "../../scripts/director";
import './landing.scene.scss';
import { curvedTextAnimation } from "../../animations/curved-text/curved-text.animation";
import { liftUpAnimation } from "../../animations/lift-up/lift-up.animation";
import { buttonFillAnimation } from "../../animations/button-fill/button-fill.animation";
import { DataSource } from "../../scripts/datasource";
import { bubbleMenuAnimation } from "../../animations/bubble-menu/bubble-menu.animation";
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';
import { InteractionHandler, InteractionDirection } from "../../scripts/interaction-handler";
import { GTAGHandler } from "../../scripts/gtaghandler";
import { TimelineMax } from "gsap";
import { Navigation } from "../../scripts/navigation";
import { timeout, clearAllTimeouts } from "../../scripts/util";

export default function LandingScene() {
  return {
    /*inline-html*/
    template: `
    <div class="page-wrapper" id="landing_wrapper">
      <div id="landing_top">
        <a target="_blank" href="http://www.itware.hu"><img id="landing-visualization-poc-logo" class="header-visualization-poc-logo" src="assets/images/itware.png"></a>
        <div id='landing_text_wrap' class='vertical-center text-center'>
          <div id="landing_text1" class="curved-text mx-auto">{{ text.title }}</div>
          <div id="landing_subtitle" class="curved-text mx-auto">{{ text.subtitle }}</div>
          <div id="landing_text2" class="mx-auto">{{ text.description }}</div>
          <button id="explore">{{ text.explore.text }}</button>
        </div>
      </div>
      <div id="landing_bottom">
        <div id="landing_ball_padding">
          <div id="landing-ball-menu-container">
          </div>
        </div>
        <div id="landing_bottom_header_wrapper">
          <a target="_blank" href="http://www.itware.hu"><img id="bubble-logo" class="header-visualization-poc-logo" src="assets/images/itware.png"></a>
          <a id="landing_factors_subscribe" href="{{ text.subscribe.url }}" target="_blank" class="subscribe white"><span id="bubble-subscribe">{{ text.subscribe.text }}</span><i id="bubble-subscribe-icon" class="material-icons">exit_to_app</i></a>
        </div>
        <div id="landing_bottom_visualization-poc_facs_text">
          <div id='landing_facs_title'>{{ ballMenuText.title }}</div>
          <div id='landing_facs_description'>{{ ballMenuText.description }}</div>
        </div>
      </div>
      <div id="selected_menu_text_wrapper">
      </div>
      <div id="landing-arrow-wrapper">
        <button id='landing_arrow_back'>←</button>
        <button id='landing_arrow_next'>→</button>
      </div>
    </div>`,

    transition: {
      enter: 'fade',
      leave: 'fade'
    },
    background: 'white',
    isExplored: false,
    exploreButton: null,
    timeoutForLastSelected: null,
    keyHandler: null,

    preload() {
      var promise1 = DataSource.fetch('bubbleMenu');
      var promise2 = DataSource.fetch('text');
      return Promise.all([promise1, promise2]).then((values) => {
        this.ballMenuData = values[0].menuItems;
        this.text = values[1].pages.landing;
        this.ballMenuText = values[1].pages.bubbleMenu;
      });
    },

    init() {
      var curvedText = document.getElementById("landing_text1");
      var subtitle = document.getElementById("landing_subtitle");
      var liftUpText = document.getElementById("landing_text2");
      var exploreBtn = document.getElementById("explore");
      var ballMenuContainer = document.getElementById("landing-ball-menu-container");
      this.visualizationPocLogoFadeIn();
      this.isExplored = false;
      exploreBtn.addEventListener('click', () => this.explore());

      curvedTextAnimation(curvedText, { delay: 200 });
      curvedTextAnimation(subtitle, { delay: 600 });
      liftUpAnimation(liftUpText, { delay: 2000, splitLines: true });
      liftUpAnimation(exploreBtn, { delay: 1250 });
      this.exploreButton = buttonFillAnimation(exploreBtn);
      this.bubbleMenu = bubbleMenuAnimation(ballMenuContainer, { 
        data: this.ballMenuData,
        ballClickCallback: () => {
          this.circleArrow1 && this.circleArrow1.disableIfNeeded();
        },
        onSelectionChange: (selectedMenu) => {
          this.showMenuTexts(selectedMenu);
        }
      }).prepare();
      liftUpAnimation(ballMenuContainer, {delay: 1000});
      timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { this.explore(); }); }, 1000);
      if (!this.keyHandler) {
        this.keyHandler = e => {
          if (this.isExplored) {
            if (e.which === 37) {
              this.previous();
            } else if (e.which === 39) {
              this.next();
            }
          }
        };
        document.addEventListener('keyup', this.keyHandler);
        this.keyHandlerAdded = true;
      }
    },

    onDestroy() {
      document.removeEventListener('keyup', this.keyHandler);
    },

    visualizationPocLogoFadeIn() {
      this.logo = document.getElementById("landing-visualization-poc-logo");
      var tl = new TimelineMax();
      tl.to(this.logo, 2, { opacity: 1 });
    },

    previous() {
      this.bubbleMenu.previous();
    },

    next() {
      this.bubbleMenu.next();
    },

    explore() {
      if (!this.isExplored) {
        timeout(() => {
          Director.setBackground('black');
          Director.setDataAttributeOnWrapper(true);
          this.scroll();
          this.setArrowVisible(true);
          this.showBottomTexts({ delay: 2000 });
          this.setTopContentVisible(false);
        }, 500);
        const sceneTitle = Navigation.getSceneTitle(this);
        GTAGHandler.addScreenView(sceneTitle + this.text.bubbleMenuSuffix, sceneTitle, Director.getTimeOnScene());
        Director.lastSceneChange = new Date();
      }
      this.isExplored = true;
      timeout(() => {
        InteractionHandler.on(InteractionDirection.UP, () => {
          this.isExplored = false; 
          this.backToFirst();
        });
      }, 1500);
      timeout(() => {
        InteractionHandler.on(InteractionDirection.DOWN, () => { 
          Director.nextScene();
        });
      }, 1500);
    },

    backToFirst() {
      clearAllTimeouts();
      this.bubbleMenu.cancelAnimation();
      this.exploreButton.reset();
      Director.setBackground('white');
      Director.setDataAttributeOnWrapper(false);
      this.setArrowVisible(false);
      this.setTopContentVisible(true, 0);
      const wrapper = document.getElementById("landing_wrapper");
      this.arrowIdSuffix = Date.now();
      wrapper.parentElement.innerHTML = Director.mustache(this.template, this);
      setTimeout(() => this.init());
    },

    setTopContentVisible(visible, delay) {
      timeout(() => {
        document.getElementById('landing_top').style.opacity = visible ? 1 : 0;
      }, delay !== undefined ? delay : 2500);
    },
    
    showBottomTexts(opts) {
      opts.delay = opts.delay || 0;
      timeout(() => {
        document.getElementById('bubble-logo').style.opacity = 1;
        var title = document.getElementById('landing_facs_title');
        var description = document.getElementById('landing_facs_description');
        liftUpAnimation(title, { delay: 500 });
        liftUpAnimation(description, { delay: 600 });
        timeout(() => {
          const bubbleSubscribe = document.getElementById('bubble-subscribe');
          if (bubbleSubscribe) {
            bubbleSubscribe.style.opacity = 1;
          }
          const bubbleSubscribeIcon = document.getElementById('bubble-subscribe-icon');
          if (bubbleSubscribeIcon) {
            bubbleSubscribeIcon.style.opacity = 1;
          }
        }, 800);
      }, opts.delay);
    },

    showMenuTexts(selectedMenu) {
      var titleElements = document.getElementsByClassName('selected_menu_title');
      var descriptionElements = document.getElementsByClassName("selected_menu_description");

      for (var i = 0; i < titleElements.length; i++) {
        var element = titleElements[i];
        element.classList.add("fade-out");
        (function(el) {
          timeout(() => {
            el.remove();
          }, 1000);
        })(element);
      }

      for (var i = 0; i < descriptionElements.length; i++) {
        var element = descriptionElements[i];
        element.classList.add("fade-out");
        (function(el) {
          timeout(() => {
            el.remove();
          }, 1000);
        })(element);
      }
      clearTimeout(this.timeoutForLastSelected);
      this.timeoutForLastSelected = timeout(() => {
        var newTitleElement = document.createElement('span');
        newTitleElement.classList.add('menu-item');
        newTitleElement.classList.add('selected_menu_title');
        var newDescriptionElement = document.createElement('span');
        newDescriptionElement.classList.add('selected_menu_description');
        newDescriptionElement.classList.add('menu-item');

        newTitleElement.innerText = selectedMenu.title;
        newTitleElement.style.color = selectedMenu.color;
        newDescriptionElement.innerText = selectedMenu.description;

        var wrapper = document.getElementById("selected_menu_text_wrapper");
        wrapper.appendChild(newTitleElement);
        wrapper.appendChild(newDescriptionElement);

        timeout(() => {
          newTitleElement.classList.add("fade-in");
          newDescriptionElement.classList.add("fade-in");
        }, 100);
      }, 1000);
    },

    setArrowVisible(visible) {
      var arrow1 = document.getElementById(`landing_arrow_back`);
      var arrow2 = document.getElementById(`landing_arrow_next`);
      arrow1.addEventListener('click', () => this.previous());
      arrow2.addEventListener('click', () => this.next());
      this.circleArrow1 = circleButtonAnimation(arrow1);
      circleButtonAnimation(arrow2);
      var arrowWrapper = document.getElementById("landing-arrow-wrapper");
      arrowWrapper.style.opacity = visible ? 0 : 1;
      timeout(() => {
        arrowWrapper.style.opacity = visible ? 1 : 0;
      }, 2500);
    },

    scroll() {
      var wrapper = document.getElementById("landing_wrapper");
      wrapper.style.top = "-100%";
      this.bubbleMenu.animate();
    }
  };
};
