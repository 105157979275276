class EventBus {

  constructor() {
    this.map = {};
  }

  $emit(event, params) {
    this.map[event] = this.map[event] || [];
    this.map[event].forEach(fn => {
      fn(params);
    });
  }

  $on(event, fn) {
    this.map[event] = this.map[event] || [];
    this.map[event].push(fn);
  }

}

export const eventBus = new EventBus();
export const VisEvent = {
  SCENE_CHANGE: 'sceneChange'
};