/**
 * Instantiates a new instance of the NiceScale class.
 *
 * @param min the minimum data point on the axis
 * @param max the maximum data point on the axis
 */
export function NiceScale(min, max) {

    var minPoint;
    var maxPoint;
    var maxTicks = 12;
    var tickSpacing;
    var range;
    var niceMin;
    var niceMax;

        /**
     * Calculate and update values for tick spacing and nice
     * minimum and maximum data points on the axis.
     */
    function calculate() {
        range = niceNum(maxPoint - minPoint, false);
        tickSpacing = niceNum(range / (maxTicks - 1), true);
        niceMin =
            Math.floor(minPoint / tickSpacing) * tickSpacing;
        niceMax =
            Math.ceil(maxPoint / tickSpacing) * tickSpacing;
    };

    /**
     * Returns a "nice" number approximately equal to range Rounds
     * the number if round = true Takes the ceiling if round = false.
     *
     * @param range the data range
     * @param round whether to round the result
     * @return a "nice" number to be used for the data range
     */
    function niceNum(range, round) {
        var exponent; /** exponent of range */
        var fraction; /** fractional part of range */
        var niceFraction; /** nice, rounded fraction */

        exponent = Math.floor(Math.log10(range));
        fraction = range / Math.pow(10, exponent);

        if (round) {
            if (fraction < 1.5)
                niceFraction = 1;
            else if (fraction < 3)
                niceFraction = 2;
            else if (fraction < 7)
                niceFraction = 5;
            else
                niceFraction = 10;
        } else {
            if (fraction <= 1)
                niceFraction = 1;
            else if (fraction <= 2)
                niceFraction = 2;
            else if (fraction <= 5)
                niceFraction = 5;
            else
                niceFraction = 10;
        }

        return niceFraction * Math.pow(10, exponent);
    };

    this.getMinPoint = function() { return minPoint; };
    this.getMaxPoint = function() { return maxPoint; };
    this.getMaxTicks = function() { return maxTicks; };
    this.getTickSpacing = function() { return tickSpacing; };
    this.getRange = function() { return range; };
    this.getNiceMin = function() { return niceMin; };
    this.getNiceMax = function() { return niceMax; };


    minPoint = min;
    maxPoint = max;
    maxTicks = 10;
    calculate();
}


/**
 * Sets the minimum and maximum data points for the axis.
 *
 * @param minPoint the minimum data point on the axis
 * @param maxPoint the maximum data point on the axis
 */

NiceScale.prototype.setMinMaxPoints = function (minPoint, maxPoint) {
    this.minPoint = minPoint;
    this.maxPoint = maxPoint;
    calculate();
};

/**
 * Sets maximum number of tick marks we're comfortable with
 *
 * @param maxTicks the maximum number of tick marks for the axis
 */
NiceScale.prototype.setMaxTicks = function (maxTicks) {
    this.maxTicks = maxTicks;
    calculate();
};
