import { TimelineMax } from "gsap";
import { timeout } from "./util";

export function intro(config) {
  const onStepChangedCallbacks = [];
  const onClosedCallbacks = [];
  let selectedStepIndex = 0;
  let stepInProgress = false;

  const overlay = document.createElement('div');
  overlay.id = 'intro-overlay';
  document.body.appendChild(overlay);
  const tooltip = document.createElement('div');
  tooltip.className = 'intro-overlay-tooltip';
  const closeButton = document.createElement('button');
  closeButton.innerText = 'x';
  closeButton.className = 'intro-overlay-close-button';
  closeButton.addEventListener('click', () => api.close());
  tooltip.appendChild(closeButton);
  overlay.appendChild(tooltip);
  const content = document.createElement('div');
  content.className = 'intro-overlay-tooltip-content';
  tooltip.appendChild(content);
  const controls = document.createElement('div');
  controls.className = 'intro-overlay-tooltip-controls';
  controls.innerHTML = `
    <button id="intro-control-prev" disabled="true" style="display: none">Previous</button>
    <div style="flex: 1 auto"></div>
    <button id="intro-control-next">Next</button>
  `;
  tooltip.appendChild(controls);
  const prev = document.getElementById('intro-control-prev');
  const next = document.getElementById('intro-control-next');
  prev.addEventListener('click', () => api.prevStep());
  next.addEventListener('click', () => api.nextStep());
  const refreshOverlay = (noanim) => {
    let elem = document.getElementById(config.steps[selectedStepIndex].id);
    console.log(config.steps[selectedStepIndex].id);
    tooltip.className = `intro-overlay-tooltip ${config.steps[selectedStepIndex].side || 'bottom'}`;
    if (elem.offsetWidth < 300) {
      tooltip.style.width = '300px';
    } else {
      tooltip.style.width = '';
    }
    const rect = elem.getBoundingClientRect();
    content.innerHTML = `
      <h5>${config.steps[selectedStepIndex].title}</h5>
      <div>${config.steps[selectedStepIndex].content}</div>
    `;
    const tooltipPosition = {};
    switch(config.steps[selectedStepIndex].side || 'bottom') {
      case 'left': {
        tooltipPosition.left =  - ((elem.offsetWidth < 300 ? 300 : rect.width) + 20);
        tooltipPosition.top = (rect.height - tooltip.offsetHeight) / 2;
        break;
      };
      case 'bottom': {
        tooltipPosition.left = (rect.width - tooltip.offsetWidth) / 2;
        tooltipPosition.top = rect.height + 20;
      };
    }
    if (noanim) {
      overlay.style.borderLeftWidth = rect.left + 'px';
      overlay.style.borderTopWidth = rect.top + 'px';
      overlay.style.borderRightWidth = window.innerWidth - rect.right + 'px';
      overlay.style.borderBottomWidth = window.innerHeight - rect.bottom + 'px';
      overlay.style.width = rect.width + 'px';
      overlay.style.height = rect.height + 'px';
      tooltip.style.top = tooltipPosition.top + 'px';
      tooltip.style.left = tooltipPosition.left + 'px';
    } else {
      const tl = new TimelineMax();
      tl.to(overlay, 0.2, {
        width: rect.width,
        height: rect.height,
        borderLeftWidth: rect.left,
        borderTopWidth: rect.top,
        borderRightWidth: window.innerWidth - rect.right,
        borderBottomWidth: window.innerHeight - rect.bottom
      });
      tl.to(tooltip, 0.2, {
        top: tooltipPosition.top,
        left: tooltipPosition.left
      }, '-=0.2');
    }
  };
  
  const api = {
    close: () => {
      const tl = new TimelineMax();
      onClosedCallbacks.forEach(callbackFn => callbackFn());
      tl.to(overlay, 0.2, {
        opacity: 0,
        onComplete: () => {
          overlay.style.display = 'none';
        }
      });
    },

    show: (step) => {
      selectedStepIndex = step || selectedStepIndex;
      if (overlay.style.display !== 'block') {
        overlay.style.display = 'block';
        overlay.style.opacity = 0;
        const tl = new TimelineMax();
        tl.to(overlay, 0.4, { opacity: 1 });
      }
      refreshOverlay(true);
    },

    destroy: () => {
      return new Promise((resolve, reject) => {
        const tl = new TimelineMax();
        tl.to(overlay, 0.2, {
          opacity: 0,
          onComplete: () => {
            overlay.parentNode.removeChild(overlay);
            resolve();
          }
        });
      });
    },

    nextStep: () => {
      if (stepInProgress) {
        return;
      }
      stepInProgress = true;
      prev.disabled = false;
      if (selectedStepIndex < config.steps.length - 1) {
        selectedStepIndex++;
        if (config.steps[selectedStepIndex].activate) {
          config.steps[selectedStepIndex].activate({ previousIndex: selectedStepIndex - 1, currentIndex: selectedStepIndex }).then(() => {
            if (selectedStepIndex === config.steps.length - 1) {
              next.disabled = true;
            }
            stepInProgress = false;
            refreshOverlay();
          });
        } else {
          if (selectedStepIndex === config.steps.length - 1) {
            next.disabled = true;
          }
          stepInProgress = false;
          refreshOverlay();
        }
        onStepChangedCallbacks.forEach(callbackFn => callbackFn(selectedStepIndex));
      }
    },

    prevStep: () => {
      if (stepInProgress) {
        return;
      }
      stepInProgress = true;
      next.disabled = false;
      if (selectedStepIndex > 0) {
        selectedStepIndex--;
        if (config.steps[selectedStepIndex].activate) {
          // config.steps[selectedStepIndex].activate({ previousIndex: selectedStepIndex + 1, currentIndex: selectedStepIndex });
        }
        refreshOverlay();
        stepInProgress = false;
        onStepChangedCallbacks.forEach(callbackFn => callbackFn(selectedStepIndex));
      }
      if (selectedStepIndex === 0) {
        prev.disabled = true;
      }
    },

    onStepChanged: (callbackFn) => {
      onStepChangedCallbacks.push(callbackFn);
    },

    onClosed: (callbackFn) => {
      onClosedCallbacks.push(callbackFn);
    },

    refresh: () => {
      refreshOverlay();
    },

    isVisible: () => {
      return overlay.style.display === 'block';
    }
  };

  return api;
}