import './button-fill.animation.scss';

export function buttonFillAnimation(element) {
  var fallbackNeeded = window.getComputedStyle(document.body).mixBlendMode === undefined;
  var btnClass = fallbackNeeded ? 'button-fill-fallback' : 'button-fill';
  element.classList && element.classList.add(btnClass);
  var span = document.createElement('span');
  span.innerHTML = element.innerHTML;
  element.innerHTML = span.innerHTML;

  if (fallbackNeeded) {
    var innerDiv = document.createElement('div');
    innerDiv.className = 'inner';
    innerDiv.appendChild(span);
    element.appendChild(innerDiv);
  } else {
    element.innerHTML = '';
    element.appendChild(span);
  }

  element.onclick = () => { element.classList.add('clicked'); };

  return {
    reset: () => {
      element.classList.remove('clicked');
    },

    setText: (text) => {
      span.innerHTML = text;
    }
  };
};