import './floating-balls.animation.scss';
import { FloatingBall, FloatingBallAnimationVariant } from './floating-ball.model';

const defaultBalls = [
  new FloatingBall(1400, 0, 280, '#E44261', FloatingBallAnimationVariant.SMALL_CIRCLE),
  new FloatingBall(1300, 300, 40, '#00C4B3'),
  new FloatingBall(1400, 350, 60, '#0626A9', FloatingBallAnimationVariant.SLOW_BACKWARDS),
  new FloatingBall(1000, 400, 160, '#F08900'),
  new FloatingBall(1200, 80, 80, '#5987DA'),
  new FloatingBall(-20, 800, 200, '#FFC62D', FloatingBallAnimationVariant.SMALL_CIRCLE),
  new FloatingBall(250, 800, 80, '#73787C')
];

export function floatingBallsAnimation(bgElement, opts) {
  opts = opts || {};
  opts.balls = opts.balls || defaultBalls;
  opts.balls.forEach(ball => {
    bgElement.appendChild(ball.toHtmlNode());
  });
};