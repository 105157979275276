import { buttonFillAnimation } from "../../animations/button-fill/button-fill.animation";
import './button-fill.scene.scss';

export default function ButtonFillScene() {
  return {
    template: `
      <div class='vertical-center text-center'>
        <button id="buttonfill1">Explore</button>
      </div>`,
    background: 'white',
    transition: {
      enter: 'fade',
      leave: 'fade'
    },

    init() {
      var button = document.getElementById("buttonfill1");
      buttonFillAnimation(button);
    }
  };
};