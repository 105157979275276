import { isMobile, timeout } from "./util";
import { DataSource } from "./datasource";

export const Help = {

  txtDesktop: 'Press space or the arrow keys to navigate.',
  txtMobile: 'Swipe down to navigate.',
  useChrome: 'This site is optimized for Google Chrome.',
  isChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),

  init () {
    return new Promise(resolve => {
      DataSource.fetch('text').then(txt => {
        this.txtDesktop = txt.help.desktop;
        this.txtMobile = txt.help.mobile;
        resolve();
      });
    });
  },

  show (rootElementId, opts = {}) {
    opts.delay = opts.delay || 3000;
    opts.displayTime = opts.displayTime || 4000;
    const rootElement = document.getElementById(rootElementId);
    if (!this.isChrome) {
      let chromeDiv = document.getElementById('browserwarning');
      if (!chromeDiv) {
        chromeDiv = document.createElement('div');
        chromeDiv.id = 'browserwarning';
        chromeDiv.innerHTML = this.useChrome;
        rootElement.parentElement.appendChild(chromeDiv);
        setTimeout(() => {
          chromeDiv.classList.add('show');
          setTimeout(() => {
            chromeDiv.classList.remove('show');
          }, 5000);
        }, 1000);
      }
    }
    let div = document.getElementById('help');
    if (!div) {
      div = document.createElement('div');
      div.id = "help";
      div.innerHTML = isMobile() ? this.txtMobile : this.txtDesktop;
      rootElement.parentElement.appendChild(div);
    }

    setTimeout(() => {
      div.classList.add('show');

      setTimeout(() => {
        div.classList.remove('show');
      }, opts.displayTime);
    }, opts.delay);
  }
};