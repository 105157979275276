import './curved-text.scene.scss';
import { curvedTextAnimation } from "../../animations/curved-text/curved-text.animation";

export default function CurvedTextScene() {
  return {
    template: `
    <div class='vertical-center text-center'>
        <div id="animtext1" class="curved-text full-width">See how Factors by MSCI can help</div>
        <div id="animtext2" class="curved-text full-width">you turn confusion into clarity.</div>
      </div>`,
    background: 'white',

    init() {
      var animtext1 = document.getElementById("animtext1");
      var animtext2 = document.getElementById("animtext2");
      curvedTextAnimation(animtext1);
      curvedTextAnimation(animtext2, { delay: 500 });
    }
  };
};