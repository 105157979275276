export function Canvas(nativeCanvas) {
  this.nativeCanvas = nativeCanvas;
  this.ctx = nativeCanvas.getContext('2d');
  this.objects = {};
  this.idSeq = 0;
  this.ctx.translate(.5, .5);
}

Canvas.prototype.add = function(object) {
  if (!object.id) {
    object.id = `object${this.idSeq++}`;
  }
  this.objects[object.id] = object;
};

Canvas.prototype.remove = function(objList) {
  if (!objList) {
    return;
  }
  if (Array.isArray(objList)) {
    objList.forEach( obj => delete this.objects[obj.id]);
  } else {
    delete this.objects[obj.id];
  }
};

Canvas.prototype.resize = function(width, height) {
  this.ctx.canvas.width = width;
  this.ctx.canvas.height = height;
};

Canvas.prototype.render = function() {
  this.ctx.clearRect(-1, -1, this.nativeCanvas.width + 1, this.nativeCanvas.height + 1);
  Object.keys(this.objects).forEach(key => {
    this.objects[key].draw(this);
  });
};

export function CanvasLine(coords, props) {
  this.coords = coords;
  this.props = props || {};
};

CanvasLine.prototype.draw = function(canvas) {
  if (this.props.visible !== false) {
    const ctx = canvas.ctx;
    ctx.beginPath();
    ctx.lineCap = this.props.lineCap || 'butt';
    if (this.props.dashed) {
      ctx.setLineDash([5, 15]);
    } else {
      ctx.setLineDash([]);
    }
    ctx.moveTo(this.coords[0], this.coords[1]);
    ctx.lineWidth = this.props.width || 1;
    ctx.strokeStyle = this.props.color || '#fff';
    ctx.lineTo(this.coords[2], this.coords[3]);
    ctx.stroke();
    ctx.closePath();
    if (this.props.cropLineCap) {
      if (this.coords[0] > this.coords[2]) {
        ctx.clearRect(this.coords[0] + 1, this.coords[1] - ctx.lineWidth / 2, ctx.lineWidth, ctx.lineWidth);
      } else {
        ctx.clearRect(this.coords[0] - ctx.lineWidth, this.coords[1] - ctx.lineWidth / 2, ctx.lineWidth - 1, ctx.lineWidth);
      }
    }
  }
};

export function CanvasText(text, props) {
  this.text = text;
  this.props = props || {};
  this.width = null;
  this.height = null;
}

CanvasText.prototype.draw = function(canvas) {
  if (this.props.visible !== false) {
    const ctx = canvas.ctx;
    ctx.font = `${this.props.size || 12}px Roboto`;
    const td = ctx.measureText(this.text);

    this.width = td.width + (this.props.padding || 0) * 2;
    this.height = this.props.size + (this.props.padding || 0) * 2;
    let x = this.props.left;
    if (this.props.origin === 'center') {
      x = x - this.width / 2;
    }
    if (this.props.origin === 'right') {
      x = x - this.width;
    }
    if (this.props.background) {
      ctx.beginPath();
      let y = this.props.top + 3;
      const radius = {tl: 0, tr: 0, br: 0, bl: 0};
      if (typeof this.props.background.radius === 'number') {
        this.props.background.radius = {tl: this.props.background.radius, tr: this.props.background.radius, br: this.props.background.radius, bl: this.props.background.radius};
      }
      for (var side in radius) {
        radius[side] = this.props.background.radius[side] || radius[side];
      }
      ctx.fillStyle = this.props.background.color || '#fff';
      ctx.moveTo(x + radius.tl, y);
      ctx.lineTo(x + this.width - radius.tr, y);
      ctx.quadraticCurveTo(x + this.width, y, x + this.width, y + radius.tr);
      ctx.lineTo(x + this.width, y + this.height - radius.br);
      ctx.quadraticCurveTo(x + this.width, y + this.height, x + this.width - radius.br, y + this.height);
      ctx.lineTo(x + radius.bl, y + this.height);
      ctx.quadraticCurveTo(x, y + this.height, x, y + this.height - radius.bl);
      ctx.lineTo(x, y + radius.tl);
      ctx.quadraticCurveTo(x, y, x + radius.tl, y);
      ctx.closePath();
      ctx.fill();
    }
    ctx.fillStyle = this.props.color || '#fff';
    const textLeft = (x || 0) + (this.props.padding || 0);
    const textTop = (this.props.top || 0) + (this.props.padding || 0);
    ctx.fillText(this.text, textLeft, textTop + (this.props.size || 12));
  }
};

export function CanvasRect(coords, props) {
  this.coords = coords;
  this.props = props || {};
};

CanvasRect.prototype.draw = function(canvas) {
  if (this.props.visible !== false) {
    const ctx = canvas.ctx;
    ctx.fillStyle = this.props.color || '#fff';
    if (this.props.opacity !== undefined) {
      ctx.globalAlpha = this.props.opacity;
    }
    ctx.fillRect(this.coords[0], this.coords[1], this.coords[2], this.coords[3]);
    if (this.props.opacity !== undefined) {
      ctx.globalAlpha = 1;
    }
  }
};

export function CanvasImage(image, props) {
  if (typeof image === 'string') {
    const img = new Image();
    img.src = image;
    this.image = img;
  } else {
    this.image = image;
  }
  this.image.width = props.width;
  this.image.height = props.height;
  this.props = props;
};

CanvasImage.prototype.draw = function(canvas) {
  if (this.props.visible !== false) {
    const ctx = canvas.ctx;
    ctx.drawImage(this.image, this.props.left, this.props.top, this.props.width, this.props.height);
  }
};
