import "./falling-balls.scene.scss";
import { Ball } from "./ball-model";
import { conf } from "./config/conf";
import {DataSource} from "../../scripts/datasource";
import { curvedTextAnimation } from "../../animations/curved-text/curved-text.animation";
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';
import { InteractionHandler, InteractionDirection } from "../../scripts/interaction-handler";
import { Director } from "../../scripts/director";
import { TimelineMax } from "gsap";
import { timeout } from "../../scripts/util";

export default function FallingBallsScene() {
	return {
		height: window.innerHeight,
		template: `
			<div id="ball-container">
				<div id="falling-balls"></div>
				<div class="page-wrapper">
					<div class="falling-balls-header">
						<div class="logo-wrapper">
							<a target="_blank" href="http://www.itware.hu"><img class="header-visualization-poc-logo" src="assets/images/itware.png"></a>
						</div>
						<a href="{{ text.subscribe.url }}" target="_blank" class="subscribe" id="fallingBalls_subscribe"><span>{{ text.subscribe.text }}</span><i class="material-icons" id="fallingBalls_subscribe_icon">exit_to_app</i></a>
					</div>
					<div class="falling-balls-content-wrapper row col-xl-7 col-lg-10 col-md-12">
						<h2>{{ text.title }}</h2>
						<div class="falling-balls-content" id="falling-balls-content-id">{{ text.description }}</div>
					</div>
				</div>
				<button id="fallingballs-arrow-top" class="scene-arrow top">↑</button>
				<button id="fallingballs-arrow-bottom" class="scene-arrow bottom">↓</button>
			</div>
		`,
		background: 'black',

		transition: {
			enter: 'slide',
			leave: 'slide'
		},

		text: {},
		ballData: [],
		intervalId: null,
		rotateIteration: 0,
		lastAnimationFrame: null,

		preload() {
			return Promise.all([DataSource.fetch('text'), DataSource.fetch('fallingBalls')]).then(values => {
				this.text = values[0].pages.fallingBalls;
				this.ballData = values[1];
			});
		},

		init() {
			conf.coeff = conf.acceleration * conf.gravity;
			conf.fps100 = conf.fps * 100;
			this.container = document.getElementById('falling-balls');
			//this.container.style.visibility = 'hidden';
			this.contentEl = document.getElementById('falling-balls-content-id');
			curvedTextAnimation(this.contentEl);
			this.balls = [];
			var xCoords = [];
			const containerSpace = this.getContainerSpace();
			const containerRect = this.container.getBoundingClientRect();
			var sumWeight = this.ballData.reduce((a, b) => parseFloat(a.weight ? a.weight : a) + parseFloat(b.weight));

			let delta = 0;
			this.ballData.forEach(entry => {
				const ball = new Ball(entry, containerSpace, sumWeight);
				this.container.appendChild(ball.toHtmlElement());
				ball.htmlElement.style.opacity = 0;
				this.balls.push(ball);
				ball.position.x = delta;
				xCoords.push(delta);
				ball.position.y = -containerRect.height*2;
				delta += ball.radius/2;
				ball.coeff = -0.5 * conf.drag * conf.density * ball.area;
			});

			// we run 1000 loops without applying the calculations to the balls to check the rotation of the balls when the physics simulation stops
			/*for (let i = 0; i < 1000; i++) {
				this.loop(false);
			}*/

			//this.container.style.visibility = 'visible';

			// if we set the start rotation of the balls to 360 - end rotation we calculated above then the balls will not be rotated when the animation ends so the text on them will be easy to read
			/*this.balls.forEach(function (ball, i) {
				ball.rotation = 360 - ball.rotation + ball.endrotationDegree;
				ball.position.x = xCoords[i];
				ball.position.y = -containerRect.height*2;
				ball.velocity.x = 0;
				ball.velocity.y = 0;
			});*/
			this.balls.forEach(ball => {
				ball.htmlElement.style.opacity = 1;
			});
			//this.intervalId = setInterval(() => {this.loop(true);}, conf.fps * 1000);
			timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { clearInterval(this.intervalId); Director.nextScene(); }); }, 1000);
			timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { clearInterval(this.intervalId);  Director.previousScene(); }); }, 1000);
			const topArrow = document.getElementById('fallingballs-arrow-top');
			topArrow.addEventListener('click', () => Director.previousScene());
			circleButtonAnimation(topArrow);
			const tlTop = new TimelineMax();
			tlTop.to(topArrow, 3, {top: 60, ease: Elastic.easeInOut});
			tlTop.to(topArrow, 3, {top: 65, ease: Elastic.easeInOut});
			tlTop.repeat(1000);
			const bottomArrow = document.getElementById('fallingballs-arrow-bottom');
			bottomArrow.addEventListener('click', () => Director.nextScene());
			circleButtonAnimation(bottomArrow);
			const tlBottom = new TimelineMax();
			tlBottom.to(bottomArrow, 3, {bottom: 60, ease: Elastic.easeInOut});
			tlBottom.to(bottomArrow, 3, {bottom: 65, ease: Elastic.easeInOut});
			tlBottom.repeat(1000);
		},

		loop(withBalls) {
			var balls = this.balls;
			for (let i = 0; i < balls.length; i++) {
				if (i !== balls.length) {
					//physics - calculating the aerodynamic forces to drag
					let fx = balls[i].coeff * balls[i].velocity.x * balls[i].velocity.x * (balls[i].velocity.x / Math.abs(balls[i].velocity.x)) * conf.ballmovementx;
					let fy = balls[i].coeff * balls[i].velocity.y * balls[i].velocity.y * (balls[i].velocity.y / Math.abs(balls[i].velocity.y));
					fx = (isNaN(fx) ? 0 : fx);
					fy = (isNaN(fy) ? 0 : fy);
					//Calculating the accleration of the ball
					//F = ma or a = F/m
					let ax = fx / balls[i].mass;
					//acceleration * gravity
					let ay = conf.coeff + (fy / balls[i].mass);
					//Calculating the ball velocity
					balls[i].velocity.x += ax * conf.fps;
					balls[i].velocity.y += ay * conf.fps;
					if (balls[i].position.y >= this.container.clientHeight - balls[i].radius) {
						balls[i].velocity.x *= conf.friction;
					}
					//If the velocity is below threshold then we set it to 0 in order to avoid flickering when the balls are practically stopped
					if (Math.abs(balls[i].velocity.x) < 0.08) {
						balls[i].velocity.x = 0;
					}

					if (Math.abs(balls[i].velocity.y) < 0.08) {
						balls[i].velocity.y = 0;
					}

					//Calculating the position of the ball
					balls[i].position.x += balls[i].velocity.x * conf.fps100;
					balls[i].position.y += balls[i].velocity.y * conf.fps100;

					if (Math.abs(balls[i].velocity.x) > 0.3) {
						balls[i].rotation += balls[i].velocity.x * conf.fps100 / (balls[i].radius * 2 * Math.PI) * 360;
					}
				}

				//Handling the ball collisions
				this.collisionBall(balls[i]);
				this.collisionWall(balls[i]);

				if (withBalls) {
					this.container.style.visibility = 'visible';
					balls[i].htmlElement.style.left = (balls[i].position.x - balls[i].radius).toFixed(0) + 'px';
					balls[i].htmlElement.style.top = (balls[i].position.y - balls[i].radius).toFixed(0) + 'px';
					balls[i].htmlElement.style.transform = `rotate(${balls[i].rotation}deg)`;
					this.lastAnimationFrame = null;
					this.rotateIteration++;
				}
			}
		},

		collisionWall(ball) {
			if (ball.position.x > this.container.clientWidth - ball.radius) {
				ball.velocity.x *= conf.collisionOnWall;
				ball.position.x = this.container.clientWidth - ball.radius;
			}
			if (ball.position.y > this.container.clientHeight - ball.radius) {
				ball.velocity.y *= conf.collisionOnWall;
				ball.position.y = this.container.clientHeight - ball.radius;
			}
			if (ball.position.x < ball.radius) {
				ball.velocity.x *= conf.collisionOnWall;
				ball.position.x = ball.radius;
			}
		},

		collisionBall(b1) {
			var balls = this.balls;
			for (let i = 0; i < balls.length; i++) {
				let b2 = balls[i];
				if (b1.position.x !== b2.position.x && b1.position.y !== b2.position.y) {
					//quick check for potential collisions using AABBs
					if (b1.position.x + b1.radius + b2.radius > b2.position.x
						&& b1.position.x < b2.position.x + b1.radius + b2.radius
						&& b1.position.y + b1.radius + b2.radius > b2.position.y
						&& b1.position.y < b2.position.y + b1.radius + b2.radius) {
						//pythagoras
						let distX = b1.position.x - b2.position.x;
						let distY = b1.position.y - b2.position.y;
						let d = Math.sqrt((distX) * (distX) + (distY) * (distY));
						//checking circle vs circle collision
						if (d < b1.radius + b2.radius) {
							let nx = (b2.position.x - b1.position.x) / d;
							let ny = (b2.position.y - b1.position.y) / d;
							let p = conf.massCollision * (b1.velocity.x * nx + b1.velocity.y * ny - b2.velocity.x * nx - b2.velocity.y * ny) / (b1.mass + b2.mass);
							// calculating the point of collision
							let colPointX = ((b1.position.x * b2.radius) + (b2.position.x * b1.radius)) / (b1.radius + b2.radius);
							let colPointY = ((b1.position.y * b2.radius) + (b2.position.y * b1.radius)) / (b1.radius + b2.radius);
							//stopping overlap
							b1.position.x = colPointX + b1.radius * (b1.position.x - b2.position.x) / d;
							b1.position.y = colPointY + b1.radius * (b1.position.y - b2.position.y) / d;
							b2.position.x = colPointX + b2.radius * (b2.position.x - b1.position.x) / d;
							b2.position.y = colPointY + b2.radius * (b2.position.y - b1.position.y) / d;
							//updating velocity to reflect collision
							b1.velocity.x -= p * b1.mass * nx * conf.ballCollisionFriction;
							b1.velocity.y -= p * b1.mass * ny * conf.ballCollisionFriction;
							b2.velocity.x += p * b2.mass * nx * conf.ballCollisionFriction;
							b2.velocity.y += p * b2.mass * ny * conf.ballCollisionFriction;
						}
					}
				}
			}
		},
		
		onResize () {
			const containerSpace = this.getContainerSpace();
			this.balls.forEach(ball => {
				ball.calcAndSetRadius(containerSpace);
				ball.setHtmlElementStyle();
			});
		},

		getContainerSpace() {
			let containerRect = this.container.getBoundingClientRect();
			return Math.min(containerRect.width, containerRect.height);
		}
	};
};
