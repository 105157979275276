import "./ekg-diagram.scss";
import {Canvas, CanvasLine, CanvasText, CanvasRect} from '../../scripts/canvas';
import {getColor, getContrastYIQ, timeout, blackOrWhiteBackground, blackOrWhiteColor, blackOrWhiteBackgroundWithOpacity, blackOrWhiteColorWithOpacity, clearAllTimeouts} from '../../scripts/util';
import { TimelineMax } from "gsap";
import { Director } from '../../scripts/director';
import { InteractionHandler, InteractionDirection } from '../../scripts/interaction-handler';
import { lineByLineReveal } from '../../animations/line-by-line-reveal/line-by-line-reveal.animation';
import { NiceScale } from '../../scripts/nicescale';
import { GTAGHandler } from "../../scripts/gtaghandler";
import { DataSource } from "../../scripts/datasource";
import { tab } from "../../scripts/tab";
import { FactorDataSource } from "../../scripts/factordatasource";
import { FundFactorDataSource } from "../../scripts/fundfactordatasource";
import { dialog } from "../../scripts/dialog";
import { autocomplete } from "../../scripts/autocomplete";
import { accordion } from "../../scripts/accordion";
import { intro } from "../../scripts/intro";
import { circleButtonAnimation } from "../../animations/circle-button/circle-button.animation";
import { dropdown } from "../../scripts/dropdown";

export default function EkgDiagramScene(mode) {
  mode = mode || '';
	return {
    height: 1080, // window.innerHeight,
    /*inline-html*/
    template: `
    <div id="${mode}-ekg-diagram" style="transform-origin: top left; transform: scale(${window.innerWidth / 1920}); width: 1920px; height: ${1920 * window.innerHeight / window.innerWidth}px; position: absolute; top: 0; left: 0">
      <div class="progress-bar-wrapper col-lg-4 col-md-8">
        <div class="progress-bar-content">
          <div class="progress-bar-text" id="${mode}-ekg-legend-bar-negative" style="color: ${blackOrWhiteColor()}">{{ text.barText.negative }}</div>
          <div class="facs-sectors-exposure-bar scale col-lg-8 col-md-8" id="${mode}-progress-bar">
            <div class="exposure-bar">
              <div class="red1 first base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-r2">
              <div class="red2 base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-r3">
              <div class="red3 base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-y1">
              <div class="orange base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-y2">
              <div class="yellow base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-g1">
              <div class="green1 base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-g2">
              <div class="green2 base-bar"></div>
            </div>
            <div class="exposure-bar" id="${mode}-ekg-legend-bar-g3">
              <div class="green3 last base-bar"></div>
            </div>
          </div>
          <div class="progress-bar-text" id="${mode}-ekg-legend-bar-positive" style="color: ${blackOrWhiteColor()}">{{ text.barText.positive }}</div>
        </div>
      </div>
      <div class="scene-container">
        <a target="_blank" href="http://www.itware.hu" class="logo"><img id="${mode}-visualization-poc" src="assets/images/itware.png" class="logo header-logo"/></a>
        <p id="${mode}-ekg-title" align="center" class="title" style="color: ${blackOrWhiteColor()}">{{ text.title }}</p>
        <div class="description-container">
          <p id="${mode}-description" class="description">{{ text.description }}</p>
        </div>
        <div id="${mode}-ekg_subscribe" class="subscribe">
          <!--a target="_blank" href={{ text.subscribe.url }}>{{ text.subscribe.text }}<i class="material-icons subscribe">exit_to_app</i></a-->
        </div>
      </div>
      <div style="display: flex">
        <canvas id="${mode}-canvas" width="1470" height="${1920 * window.innerHeight / window.innerWidth - 20}"></canvas>
        <div id="${mode}-graph-list-container" class="graph-list-container">
          <div tab="Compare ${mode === 'stock' ? 'Stocks' : 'Funds'}" class="tab-content ${blackOrWhiteBackground()}">
            <div panel="Select a Factor" static>
              <div style="display: flex; padding-top: 4px;">
                <select id="${mode}-ekg-factor-select" class="${blackOrWhiteBackground()}" full>
                  <option>Value</option>
                  <option>Size</option>
                  <option>Momentum</option>
                  <option>Quality</option>
                  <option>Yield</option>
                  <option>Volatility</option>
                  <option selected>Growth</option>
                  <option>Liquidity</option>
                </select>
              </div>
              <div id="${mode}-ekg-switches"></div>
            </div>
          </div>
          <div tab="Compare Factors" class="tab-content ${blackOrWhiteBackground()}">
            <div panel="Select a ${mode === 'stock' ? 'Stock' : 'Fund'} " static>
              <div style="display: flex; padding-top: 4px;">
                <select id="${mode}-ekg-stock-select" class="${blackOrWhiteBackground()}" full></select>
              </div>
              <div id="${mode}-ekg-factor-switches"></div>
            </div>
          </div>
          <div id="${mode}-add-company-accordion" class="gray-wrapper no-border-radius no-padding ${blackOrWhiteBackground()}">
            <div style="width: 100%" panel="Add ${mode === 'stock' ? 'Stocks' : 'Funds'}" static>
              <input id="${mode}-ekg-company-input" type="text" placeholder="Choose a ${mode === 'stock' ? 'Stock' : 'Fund'}" autocomplete="off">
              <span id="${mode}-ekg-close-btn" class="close" style="display: none">&times;</span>
            </div>
          </div>
          <div id="${mode}-bottom-cta" class="bottom-facs-cta gray-wrapper ${blackOrWhiteBackground()}" style="display: block;">
            <div>Access all Companies</div>
            <div style="display: flex">
              <a href="http://www.itware.hu">
                <div class="cta-button">
                  Sign-In
                </div>
              </a>
              <div style="flex: 1 auto"></div>
              <a href="http://www.itware.hu">
                <div class="cta-button">
                  Subscribe
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="${mode}-ekg-overlay" class="${blackOrWhiteBackground()}" style="display: none"></div>
      <div id="${mode}-ekg-modal-content" class="modal-content">
        {{ text.tooManyAssetswarning }}
      </div>
      <div id="${mode}-ekg-modal-cta" class="modal-content">
        <div class="bottom-facs-cta gray-wrapper ${blackOrWhiteBackground()}" style="display: block;">
          <div class="title">Access all Companies</div>
          <div class="header">The selected company is available for FaCS Clients.</div>
          <div style="display: flex">
            <a href="http://www.itware.hu">
              <div class="cta-button">
                FaCS Client<br/>
                Sign-In
              </div>
            </a>
            <div style="flex: 1 auto"></div>
            <a href="http://www.itware.hu">
              <div class="cta-button">
                Not a FaCS Client<br/>
                Subscribe
              </div>
            </a>
          </div>
        </div>
      </div>
      <button id="${mode}-click-animation" style="position: absolute; top: 0px; left: -100px; opacity: 0;"> </button>
    </div>`,
    background: blackOrWhiteBackground(),
    transition: {
      enter: 'slide',
      leave: 'slide'
    },

    methods: {},

    coordinates: [],
    xLabels: [],
    yLabels: [],
    chartLines: [],
    chartBars: [],
    charts: [
      {
        color: '#' + ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6),
        coordinates: [],
        dataLines: [],
        data: [],
      }
    ],
    dataLines: [],
    tickLines: [],
    labels: [],
    data: [],
    htmlcanvas: null,
    canvas: null,
    columnWidth: null,
    mouse: null,
    selected: null,
    drawing: true,
    resizeTimer: null,
    ratio: 1,
    yAxisWidth: 0,
    xAxisHeight: 50,
    min: 0,
    max: 0,
    scale: {},
    reset: false,
    hideYAxisExtremeLabels: true,
    recalculateXLabelPosition: false,
    initialized: false,
    stopRender: false,
    text: {},
    barList: null,
    exposureLabelEl: null,
    progBarNegative: null,
    progBarPositive: null,
    factorSelect: null,
    selectedFactor: 'Growth',
    factors: [],
    selectedStock: null,
    selectedStocks: [],
    graphTab: null,
    introApi: null,
    clickAnimation: null,
    companyInput: null,
    offset: 0,
    startYear: null,
    companyDropdown: null,
    factorDropdown: null,
    dataSource: mode === 'stock' ? FactorDataSource : FundFactorDataSource,
    root: null,
    cta: null,
    ctaDialogApi: null,

    prev () {
      Director.toScene(example1Scene);
    },

    preload() {
      return new Promise(resolve => {
        console.log(`mode: ${mode}`);
        Promise.all([DataSource.fetch(`ekgDiagram`), DataSource.fetch('text')]).then(values => {
          this.charts[0].data = values[0].chart;
          this.charts[0].label = 'FACEBOOK INC';
          this.text = values[1].pages[`${mode}EkgDiagram`];
          resolve();
        });
      });
    },

    onDestroy() {
      return new Promise((resolve, reject) => {
        this.clickAnimation.parentNode.removeChild(this.clickAnimation);
        this.introApi.destroy().then(() => resolve());
      });
    },

    showClick(elem, delay) {
      return new Promise((resolve, reject) => {
        const rect = elem.getBoundingClientRect();
        this.clickAnimation.style.top = rect.top + rect.height / 2 - this.clickAnimation.offsetHeight / 2 + 'px';
        this.clickAnimation.style.left = rect.left + rect.width / 2 - this.clickAnimation.offsetWidth / 2 + 'px';
        const tl = new TimelineMax();
        tl.to(this.clickAnimation, 0.2, {
          opacity: 1,
          onComplete: () => {
            this.clickAnimation.click();
            timeout(() => {
              tl.to(this.clickAnimation, 0.2, {
                opacity: 0,
                onComplete: () => {
                  if (elem.tagName === 'SELECT') {
                    let event = document.createEvent('MouseEvents');
                    event.initMouseEvent('mousedown', true, true, window);
                    elem.dispatchEvent(event);
                  } else {
                    elem.click();
                  }
                  timeout(() => resolve(), delay);
                }
              }, '+=0.25');
            });
          }
        });
      });
    },

    createFactor(label, color) {
      const switchesContainer = document.getElementById(mode + '-ekg-factor-switches');
      const factor = {
        color: color || '#' + ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6),
        div: document.createElement('div'),
        label: label
      };
      factor.div.className = 'factor-switch-wrapper';
      /*inline-html*/
      factor.div.innerHTML = `
        <div id="${mode}-${factor.label}-factor" style="white-space: nowrap; display: flex;">
          <label class="switch" for="${mode}-${factor.label}-switch">
            <input type="checkbox" id="${mode}-${factor.label}-switch" checked/>
            <div class="slider round" style="background-color: ${factor.color};"></div>
          </label>
          <span class="factor-switch-label" style="color: white; display: inline-block; vertical-align: top; font-size: 12pt; margin-left: 12px; padding-top: 6px;">${factor.label}</span>
        </div>`;

      switchesContainer.appendChild(factor.div);
      const switchButton = document.getElementById(`${mode}-${factor.label}-switch`);
      switchButton.addEventListener('click', () => {
        if (switchButton.checked) {
          this.addNewChart(this.selectedStock, factor);
        } else {
          this.removeChart(factor);
        }
      });
      this.factors.push(factor);
      this.companyInput.recalculateHeight();
    },

    createStock(stock, color) {
      this.switchesContainer = document.getElementById(mode + '-ekg-switches');
      stock.color = color || '#' + ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6);
      stock.div = document.createElement('div');
      stock.div.className = 'asset-switch-wrapper';
      /*inline-html*/
      stock.div.innerHTML = `
        <div id="${mode}-${stock.assetName}-stock" style="white-space: nowrap; display: flex;">
          <label class="switch" for="${mode}-${stock.assetName}-switch">
            <input type="checkbox" id="${mode}-${stock.assetName}-switch" checked/>
            <div class="slider round" style="background-color: ${stock.color};"></div>
          </label>
          <span class="asset-switch-label" title="${stock.assetName}">${stock.assetName}</span>
          <div style="flex: 1 auto;"></div>
          <div class="asset-switch-remove-btn" style="display: inline-block; vertical-align: top">
            <i id="${mode}-${stock.assetName}-remove" style="padding-top: 6px; margin-left: 4px; color: white; cursor: pointer" class="material-icons">remove_circle_outline</i>
          </div>
        </div>`;

      this.switchesContainer.appendChild(stock.div);
      const closeButton = document.getElementById(`${mode}-${stock.assetName}-remove`);
      closeButton.addEventListener('click', (event) => {
        GTAGHandler.addClickEvent(event);
        this.removeStock(stock);
      });
      const switchButton = document.getElementById(`${mode}-${stock.assetName}-switch`);
      switchButton.addEventListener('click', () => {
        if (switchButton.checked) {
          stock.hidden = false;
          this.addNewChart(stock);
        } else {
          stock.hidden = true;
          this.removeChart(stock);
        }
      });
      this.selectedStocks.push(stock);
      const stockSelect = document.getElementById(mode + '-ekg-stock-select');
      const option = document.createElement('option');
      option.innerText = stock.assetName;
      stockSelect.appendChild(option);
      this.selectedStock = stock;
      this.stockSelect.value = stock.assetName;
      if (this.graphTab.getSelectedIndex() === 1) {
        this.charts.forEach((chart) => this.removeChart(chart, true));
        timeout(() => {
          clearAllTimeouts();
          this.recreateFactorCharts();
        }, 500);
      }
      this.companyDropdown.createDropdown(false, true);
      this.factorDropdown.createDropdown(false, true);
      this.companyInput.recalculateHeight();
    },

    createModal() {
      this.graphTab.onSelectedIndexChanged((index) => {
        if (index === 0) {
          this.titleElement.innerHTML = this.text.title.replace('{{selectedFactor}}', this.selectedFactor);
          this.factorDropdown.createDropdown(false, true);
          this.charts.forEach(chart => this.removeChart(chart, true));
          timeout(() => {
            this.selectedStocks.forEach(stock => {
              const switchButton = document.getElementById(`${mode}-${stock.assetName}-switch`);
              if (switchButton.checked) {
                this.addNewChart(stock, null, true);
              }
            });
            const minMax = this.calculateMinMax();
            this.min = minMax.min;
            this.max = minMax.max;
            this.rescale(this.min, this.max);
            this.calculateChartCoordinates(true);
            this.calculateDataCoordinates(true);
          }, 500);
        } else {
          this.titleElement.innerHTML = this.text.title.replace('{{selectedFactor}}', 'Factor');
          this.companyDropdown.createDropdown(false, true);
          this.selectedStocks.forEach(stock => {
            this.removeChart(stock.currentChart, true);
          });
          this.selectedStock = this.selectedStock || this.selectedStocks[0];
          timeout(() => this.recreateFactorCharts(), 500);
        }
        this.companyInput.recalculateHeight();
      });
      const input = document.getElementById(mode + "-ekg-company-input");

      input.style.background = blackOrWhiteBackground();
      input.style.color = blackOrWhiteColor();

      this.root.style.height = `${1920 * window.innerHeight / window.innerWidth}px`;

      this.companyInput = autocomplete(document.getElementById(mode + "-ekg-company-input"), this.dataSource, document.getElementById(mode + '-ekg-diagram'), document.getElementById(mode + '-bottom-cta'), (asset) => {
        this.dataSource.fetchByAsset(asset.assetId).then((stock) => {
          if (this.selectedStocks.length > 4) {
            this.dialogApi.open();
          } else if (!this.selectedStocks.filter(stock => stock.assetName === asset.assetName).length) {
            this.createStock(stock);
            if (this.graphTab.getSelectedIndex() === 0) {
              this.addNewChart(stock);
            }
          }
        });
      });
      this.companyInput.setItemFilter((item) => {
        return !item.paid;
      });
      this.companyInput.onShowCta((show) => {
        if (this.ctaDialogApi) {
          this.ctaDialogApi.open();
        }
      });
      input.click();
    },

    recreateFactorCharts() {
      this.factors.forEach(factor => {
        const switchButton = document.getElementById(`${mode}-${factor.label}-switch`);
        if (switchButton.checked) {
          this.addNewChart(this.selectedStock, factor, true);
        }
      });
      const minMax = this.calculateMinMax();
      this.min = minMax.min;
      this.max = minMax.max;
      this.rescale(this.min, this.max);
      this.calculateChartCoordinates(true);
      this.calculateDataCoordinates(true);
    },

    generateChartData(value) {
      const data = [];
      value = parseFloat(value) || Math.random();
      for (let i = 2006; i <= 2020; i++) {
        const obj = {
          label: i.toString(),
          values: [],
          overlay: value
        };
        const skipYear = Math.random() > 0.8;
        for (let j = 0; j < 10; j++) {
          value += (Math.random() - 0.5) * 0.1;
          if (!skipYear) {
            obj.values.push(value);
          }
        }
        data.push(obj);
      }
      return data;
    },

    createOverlay(index, top, left) {
      if (this.introApi && this.introApi.isVisible()) {
        return;
      }
      const overlay = document.getElementById(mode + '-ekg-overlay');
      if (this.graphTab.getSelectedIndex() === 0) {
        overlay.innerHTML = `<h4>${this.selectedFactor} Exposure - ${this.charts[0].data[index].label}</h4>`;
      } else {
        overlay.innerHTML = `<h4>${this.selectedStock.assetName} - ${this.charts[0].data[index].label}</h4>`;
      }
      overlay.style.display = 'block';
      overlay.style.opacity = 1;
      this.charts.sort((a, b) => b.data[index].values[0] - a.data[index].values[0]).forEach(chart => {
        const div = document.createElement('div');
        div.style.display = 'flex';
        /*inline-html*/
        div.innerHTML = `
          <div style="background: ${chart.color}; display: block; width: 20px; height: 3px; position: relative; top: 10px;"></div>
          <span style="margin-left: 5px; margin-right: 10px">${chart.label}</span>
          <div style="flex: 1 auto"></div>
          <span style="color: white;">${chart.data[index].values[0] ? parseFloat(chart.data[index].values[0]).toFixed(2) : 'N/A'}
        `;
        overlay.appendChild(div);
      });
      overlay.style.top = Math.min(top, 1920 * window.innerHeight / window.innerWidth - overlay.offsetHeight) + 'px';
      overlay.style.left = Math.min(left, 1920 - overlay.offsetWidth) + 'px';
    },
    
    calculateXLabelVerticalPositions() {

      let maxLabelWidth = this.xLabels[0].width || 0;
      this.xLabels.forEach( label => 
        maxLabelWidth = Math.max(maxLabelWidth, label.width));
      let alternating = maxLabelWidth + 4 > this.columnWidth ? 1 : 0;

      this.alternatingXLabelPosition = true;
      for(let i = 0; i<this.xLabels.length; i++) {
        this.xLabels[i].props.top = this.htmlcanvas.height - this.xAxisHeight + alternating * 16 * (i % 2);
      }
      this.recalculateXLabelPosition = false;
    },

    calculateChartCoordinates(opaque) {
      if (!this.charts[0] || !this.charts[0].data) {
        return;
      }
      let maxYLabelWidth = this.yLabels[0].width || 0;
      this.yLabels.forEach(label => {
        maxYLabelWidth = Math.max(maxYLabelWidth, label.width);
      });
      this.xOffset = maxYLabelWidth || 50;
      if (!opaque) {
        this.columnWidth = Math.round((this.htmlcanvas.width - this.yAxisWidth - this.xOffset * 2) / this.charts[0].data.length);
      }
      for (let i = 0; i < this.xLabels.length; i++) {
        var label = this.xLabels[i];
        label.props.left = this.xOffset + this.yAxisWidth + i * this.columnWidth + this.columnWidth / 2 + this.offset;
      }

      if (!opaque) {
        for (let i = 1; i < this.chartLines.length; i++) {
          const line = this.chartLines[i];
          line.coords = [this.xOffset + this.yAxisWidth + i * this.columnWidth + this.offset, this.htmlcanvas.height, this.xOffset + this.yAxisWidth + i * this.columnWidth + this.offset, this.htmlcanvas.height];
        }
      }

      for (let i = 0; i < this.chartBars.length; i++) {
        const bar = this.chartBars[i];
        bar.coords = [this.xOffset + this.yAxisWidth + i * this.columnWidth + this.offset, 40, this.columnWidth, this.htmlcanvas.height];
      }
      // calculate the magnifying ratio to span the data across the y axis of the screen
      this.ratio = (this.htmlcanvas.height - this.xAxisHeight - this.titleHeight) / (this.max - this.min);

      for (let i = 0; i < this.yLabels.length; i++) {
        const yLabel = this.yLabels[i];
        const y = (this.htmlcanvas.height - this.xAxisHeight) - ((parseFloat(yLabel.text) - this.min) * this.ratio + yLabel.height / 2);
        yLabel.props.top = y;// + 20;
        yLabel.props.color = blackOrWhiteColorWithOpacity(opaque ? 1 : 0);
      }

      this.recalculateXLabelPosition = true;
    },

    calculateDataCoordinates(updateOnly) {
      this.charts.forEach(chart => {
        chart.coordinates = [];
        let dashed = [];
        for (let i = 0; i < chart.data.length; i++) {
          if (chart.data[i].values.length === 0) {
            dashed.push(chart.coordinates.length - 1);
          }
          for (let j = 0 ; j < chart.data[i].values.length; j++) {
            const x = this.xOffset + this.yAxisWidth + i * this.columnWidth + j * this.columnWidth / chart.data[i].values.length + this.offset;
            chart.coordinates.push({x: x, y : chart.data[i].values[j]});
          }
        }

        chart.coordinates.forEach(coordinate => {
          coordinate.y -= this.min;
          coordinate.y *= this.ratio;
        });

        if (!updateOnly) {
          chart.dataLines = [];
        }

        for (let i = 0; i < chart.coordinates.length - 1; i++) {
          if (updateOnly && chart.dataLines[i]) {
            chart.dataLines[i].coords = [chart.coordinates[i].x, (this.htmlcanvas.height - this.xAxisHeight) - chart.coordinates[i].y, chart.coordinates[i + 1].x, (this.htmlcanvas.height - this.xAxisHeight) - chart.coordinates[i + 1].y];
          } else {
            const line = new CanvasLine([chart.coordinates[i].x, (this.htmlcanvas.height - this.xAxisHeight) - chart.coordinates[i].y, chart.coordinates[i + 1].x, (this.htmlcanvas.height - this.xAxisHeight) - chart.coordinates[i + 1].y], {
              width: 3,
              lineCap: 'round',
              color: 'rgba(255, 255, 255, 1)',
              dashed: dashed.indexOf(i) > -1
            });
            chart.dataLines.push(line);
            this.canvas.add(line);
          }
        }
      });
    },

    removeStock(stock) {
      const switches = document.getElementById(mode + '-ekg-switches');
      switches.removeChild(stock.div);
      this.removeChart(stock.currentChart);
      const stockSelect = document.getElementById(mode + '-ekg-stock-select');
      stockSelect.remove(this.selectedStocks.indexOf(stock));
      this.selectedStocks.splice(this.selectedStocks.indexOf(stock), 1);
      this.companyInput.recalculateHeight();
    },

    removeChart(chart, norescale) {
      if (chart.currentChart) {
        chart = chart.currentChart;
      }
      chart.isFading = true;
      chart.dataLines.forEach(line => {
        const tl = new TimelineMax();
        tl.to(line.props, 0.4, { color: blackOrWhiteBackgroundWithOpacity(0) });
      });
      timeout(() => {
        this.canvas.remove(chart.dataLines);
        this.charts.splice(this.charts.indexOf(chart), 1);
        if (!norescale) {
          const minMax = this.calculateMinMax();
          this.startYear = 2021;
          this.charts.forEach(chart => {
            chart.data.forEach(data => {
              if (data.values.length > 0) {
                this.startYear = Math.min(this.startYear, parseInt(data.label));
              }
            });
          });
          const diff = this.startYear - 2006;
          const newColumnWidth = Math.round((this.htmlcanvas.width - this.yAxisWidth - this.xOffset * 2) / (this.charts[0].data.length - diff));
          const tl = new TimelineMax();
          tl.to(this, 1, {
            min: minMax.min,
            max: minMax.max,
            offset: -newColumnWidth * diff,
            columnWidth: newColumnWidth,
            onUpdate: () => {
              this.calculateTimeScale();
              this.rescale(this.min, this.max);
              this.calculateChartCoordinates(true);
              this.calculateDataCoordinates(true);
            }
          });
        }
      }, 400);
    },

    loadBar() {
      for (let i = 0; i < 8; i++) {
        timeout(() => {
          var tl = new TimelineMax();
          tl.to(this.barList[i], 0.2, {opacity: 1, width: 100 + '%'});
        }, 200 * i);
      }
    },

    rescale(min, max) {
      console.log(`min: ${min} max: ${max}`);
      this.canvas.remove(this.yLabels);
      this.yLabels = [];

      // calculate y axis labels, that are human friendly
      this.scale = new NiceScale(min, max);

      // Add labels and tick lines to Y axis
      for (let i = 0; i < this.scale.getMaxTicks(); i++) {
        if (this.hideYAxisExtremeLabels && this.scale.getMaxTicks() > 3 && i === 0 ) {
          continue;
        }

        const valueLabel = new CanvasText( `${(this.scale.getNiceMin() + i * this.scale.getTickSpacing()).toFixed(2)}`, {
          top: 0, // will be calculated based on canvas size
          left: 25,
          origin: 'center',
          color: blackOrWhiteColorWithOpacity(1),
          size: 14,
          visible: true
        });
        this.canvas.add(valueLabel);
        this.yLabels.push(valueLabel);
      }
    },

    init() {
      if (!this.initialized) {
        this.root = document.getElementById(mode + '-ekg-diagram');
        const modal = document.getElementById(mode + '-ekg-modal-content');
        this.dialogApi = dialog(document.body, modal, true);
        this.cta = document.getElementById(`${mode}-ekg-modal-cta`);
        this.ctaDialogApi = dialog(document.body, this.cta, true);
        
        this.introApi = intro({
          steps: [
            {
              id: mode + '-graph-list-container',
              side: 'left',
              title: 'Chart Controls',
              content: 'You can compare multiple stocks and funds on this chart. Use this panel to add and remove stocks and funds.'
            },
            {
              id: mode + '-ekg-company-input',
              side: 'left',
              title: 'Add Stocks',
              content: 'Stocks and funds can be filtered by either name or ticker.'
            },
            {
              id: mode + '-add-stocks-panel-content',
              activate: (params) => {
                return new Promise((resolve, reject) => {
                  const input = document.getElementById(mode + "-ekg-company-input");
                  timeout(() => {
                    input.value = 'a';
                    let event = document.createEvent('Event');
                    event.initEvent('input', false, true);
                    input.dispatchEvent(event);
                    setTimeout(() => this.introApi.refresh());
                  }, 400);
                  timeout(() => {
                    input.value = 'am';
                    let event = document.createEvent('Event');
                    event.initEvent('input', false, true);
                    input.dispatchEvent(event);
                    setTimeout(() => this.introApi.refresh());
                  }, 700);
                  timeout(() => {
                    input.value = 'ame';
                    let event = document.createEvent('Event');
                    event.initEvent('input', false, true);
                    input.dispatchEvent(event);
                    setTimeout(() => this.introApi.refresh());
                  }, 1000);
                  timeout(() => {
                    input.value = 'amer';
                    let event = document.createEvent('Event');
                    event.initEvent('input', false, true);
                    input.dispatchEvent(event);
                    setTimeout(() => {
                      this.introApi.refresh();
                      resolve();
                    });
                  }, 1300);
                });
              },
              side: 'left',
              title: 'Add Stocks',
              content: 'Filtered stocks and funds are displayed in this list.'
            },
            {
              id: mode + '-add-stocks-panel-content',
              side: 'left',
              title: 'Add Stocks',
              content: 'You can add multiple stocks and funds from the filtered list to the chart.'
            },
            {
              id: `${mode}-compare-${mode}s-select-a-factor-panel-content`,
              activate: (params) => {
                return this.showClick(document.getElementById(mode + '-ekg-company-inputautocomplete-list').childNodes[0], 200);
              },
              side: 'left',
              title: 'Play with the Charts',
              content: 'Stocks and funds you added are displayed here. You can turn the charts on and off by pressing these switches.'
            },
            {
              id: mode + '-ekg-factor-select-wrapper',
              activate: (params) => {
                return new Promise((resolve, reject) => {
                  const facebookSwitch = document.querySelectorAll(`[for="${mode}-FACEBOOK INC-switch"]`)[0];
                  this.showClick(facebookSwitch);
                  timeout(() => {
                    this.showClick(facebookSwitch, 600).then(() => resolve());
                  }, 2000);
                });
              },
              side: 'left',
              title: 'Switch Factors',
              content: 'You can use this box to select a different factor to compare.'
            },
            {
              id: mode + '-compare-factors-tab',
              /*activate: (params) => {
                return this.showClick(document.getElementById(mode + '-ekg-factor-select-wrapper'), 500);
                //return this.showClick(document.getElementById(mode + '-compare-factors-tab'), 500);
              },*/
              side: 'bottom',
              title: 'Compare Factors',
              content: 'You can compare all factors for a selected company by activating this tab.'
            },
            {
              id: mode + '-compare-factors-select-a-stock--panel-content',
              activate: (params) => {
                return this.showClick(document.getElementById(mode + '-compare-factors-tab'), 500);
              },
              side: 'left',
              title: 'Compare Factors',
              content: 'Switches in this tab controls charts for each factor.'
            },
          ]
        });
        this.introApi.onClosed(() => {
          const input = document.getElementById(mode + "-ekg-company-input");
          input.value = '';
          input.click();
        });
        timeout(() => this.introApi.show(), 2000);
        this.clickAnimation = document.getElementById(mode + '-click-animation');
        document.body.appendChild(this.clickAnimation);
        circleButtonAnimation(this.clickAnimation);
        this.titleElement = document.getElementById(mode + '-ekg-title');
        if (this.titleElement) {
          this.titleHeight = this.titleElement.offsetHeight + this.titleElement.offsetParent.offsetTop;
        } else {
          this.titleHeight = 40;
        }
        this.root.querySelectorAll('[tab]').forEach(tab => accordion(tab, `${mode}-${tab.getAttribute('tab')}`));
        const grapListContainer = document.getElementById(mode + '-graph-list-container');
        this.graphTab = tab(grapListContainer, mode);
        grapListContainer.addEventListener('mouseover', () => {
          const overlay = document.getElementById(mode + '-ekg-overlay');
          const tl = new TimelineMax();
          tl.to(overlay, 0.2, { opacity: 0, onComplete: () => {
            overlay.style.display = 'none';
          }});
        });
        // IE workaround
        setTimeout(() => {
          grapListContainer.style.position = 'absolute';
          grapListContainer.style.minWidth = '400px';
          grapListContainer.style.width = '400px';
          grapListContainer.style.top = '15px';
          grapListContainer.style.right = '75px';
        });
        this.barList = document.getElementsByClassName('base-bar');
        this.progBarNegative = document.getElementById(mode + '-ekg-legend-bar-negative');
        this.progBarPositive = document.getElementById(mode + '-ekg-legend-bar-positive');
        this.factorSelect = document.getElementById(mode + '-ekg-factor-select');
        this.factorSelect.addEventListener('change', (e) => {
          this.selectedFactor = this.factorSelect.value;
          this.titleElement.innerHTML = this.text.title.replace('{{selectedFactor}}', this.selectedFactor);
          this.charts.forEach((chart) => this.removeChart(chart, true));
          timeout(() => {
            this.selectedStocks.forEach((stock) => {
              if (!stock.hidden) {
                this.addNewChart(stock, null, true);
              }
            });
            const minMax = this.calculateMinMax();
            this.min = minMax.min;
            this.max = minMax.max;
            this.rescale(this.min, this.max);
            this.calculateChartCoordinates(true);
            this.calculateDataCoordinates(true);
          }, 500);
        });
        this.factorDropdown = dropdown(this.factorSelect, true);
        this.factorDropdown.onSelectToggled(() => {
          const dummy = {v: 0};
          const tl = new TimelineMax();
          tl.to(dummy, 0.2, {v: 1, onUpdate: () => {
            this.companyInput.recalculateHeight();
          }});
        });
        this.stockSelect = document.getElementById(mode + '-ekg-stock-select');
        this.companyDropdown = dropdown(this.stockSelect);
        this.companyDropdown.onItemRemoved(idx => {
          this.removeStock(this.selectedStocks[idx]);
        });
        this.companyDropdown.onSelectToggled(() => {
          const dummy = {v: 0};
          const tl = new TimelineMax();
          tl.to(dummy, 0.2, {v: 1, onUpdate: () => {
            this.companyInput.recalculateHeight();
          }});
        });
        this.stockSelect.addEventListener('change', (e) => {
          if (this.stockSelect.value) {
            this.selectedStock = this.selectedStocks.filter((stock) => stock.assetName === this.stockSelect.value)[0];
            this.charts.forEach((chart) => this.removeChart(chart, true));
            timeout(() => {
              clearAllTimeouts();
              this.recreateFactorCharts();
            }, 500);
          }
        });
        this.createModal();
        const addCompany = document.getElementById(mode + '-add-company-accordion');
        const addCompanyAccordion = accordion(addCompany, mode);
        addCompanyAccordion.onPanelExpanded(() => {
          this.companyInput.recalculateHeight();
        });
        this.companyInput.recalculateHeight(window.innerHeight);
        timeout(() => this.loadBar(), 3500);
        lineByLineReveal(this.progBarNegative, {delay: 3500});
        lineByLineReveal(this.progBarPositive, {delay: 4500});
        this.initialized = true;
        this.htmlcanvas = document.getElementById(mode + '-canvas');
        this.htmlcanvas.height = 1920 * window.innerHeight / window.innerWidth - 20;
        this.columnWidth = Math.round((this.htmlcanvas.width - this.yAxisWidth) / this.data.length);
        this.canvas = new Canvas(this.htmlcanvas);
        this.coordinates = [];
        // Add labels and tick lines to X axis
        for (let i = 0; i < this.charts[0].data.length; i++) {
          const year = new CanvasText(this.charts[0].data[i].label || '', {
            top: 0, // will be calculated based on canvas size
            left: 0, // will be calculated based on canvas size
            color: blackOrWhiteColorWithOpacity(0),
            origin: 'center',
            size: 14,
            visible: false
          });
          this.canvas.add(year);
          this.xLabels.push(year);
          const chartLine = new CanvasLine([0, 0, 0, 0], {
            color: blackOrWhiteColorWithOpacity(0),
            width: 1
          });
          this.canvas.add(chartLine);
          this.chartLines.push(chartLine);
        }
        
        const minMax = this.calculateMinMax();
        this.min = minMax.min;
        this.max = minMax.max;
        this.rescale(this.min, this.max);

        // Adding highlight bars and value labels
        for (let i = 0; i < this.charts[0].data.length; i++) {
          const chartBar = new CanvasRect([0, 0, 0, 0], { // coords will be calculated based on canvas size
            color: blackOrWhiteColorWithOpacity(0)
          });
          this.canvas.add(chartBar);
          this.chartBars.push(chartBar);

          const dataGroup = this.charts[0].data[i];

          let min = dataGroup.values[0] || 0;
          let max = dataGroup.values[0] || 0;

          for (let j = 0; j < dataGroup.values.length; j++) {
            max = Math.max(max, dataGroup.values[j]);
            min = Math.min(min, dataGroup.values[j]);
          }

          dataGroup.min = min;
          dataGroup.max = max;
          
          const value = this.charts[0].data[i].overlay;
          const label = new CanvasText(`${value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}`, {
            value: value,
            top: 0, // coords will be calculated based on canvas size
            left: 0, // coords will be calculated based on canvas size
            color: getContrastYIQ(getColor(value, -1, 1, 1), 0),
            size: 14,
            origin: 'center',
            background: {
              color: getColor(value, -1, 1, 0),
              radius: 14
            },
            padding: 6,
            visible: true
          });
          this.canvas.add(label);
          this.labels.push(label);
        }
        this.dataSource.fetchByAsset(mode === 'stock' ? 'USAMZ01' : 'LP40000027').then((stock) => {
          this.createStock(stock, this.charts[0].color);
          this.selectedStocks[0].currentChart = this.charts[0];
        });
        this.calculateChartCoordinates();
        this.calculateDataCoordinates();

        this.startYear = 2021;
        this.charts.forEach(chart => {
          chart.data.forEach(data => {
            if (data.values.length > 0) {
              this.startYear = Math.min(this.startYear, parseInt(data.label));
            }
          });
        });
        const diff = this.startYear - 2006;
        this.columnWidth = Math.round((this.htmlcanvas.width - this.yAxisWidth - this.xOffset * 2) / (this.charts[0].data.length - diff));
        this.offset = -this.columnWidth * diff;
        this.calculateTimeScale();
        this.calculateChartCoordinates(true);
        this.calculateDataCoordinates(true);
        [{
          label: 'Growth',
          color: 'rgb(107, 121, 185)'
        },
        {
          label: 'Liquidity',
          color: 'rgb(171, 175, 176)'
        },
        {
          label: 'Momentum',
          color: 'rgb(34, 58, 143)'
        },
        {
          label: 'Quality',
          color: 'rgb(229, 67, 97)'
        },
        {
          label: 'Size',
          color: 'rgb(1, 172, 165)'
        },
        {
          label: 'Value',
          color: 'rgb(253, 196, 45)'
        },
        {
          label: 'Volatility',
          color: 'rgb(117, 119, 123)'
        },
        {
          label: 'Yield',
          color: 'rgb(240, 138, 0)'
        }].forEach(factor => this.createFactor(factor.label, factor.color));
        this.offset = 0;
      }

      const handleMouse = (e) => {
        
        const min = Math.floor(e.clientX / this.columnWidth) * this.columnWidth;
        const max = Math.floor(e.clientX / this.columnWidth) * this.columnWidth + this.columnWidth;
        this.mouse = {
          x: e.clientX / window.innerWidth * 1920,
          y: e.clientY * 1920 / window.innerWidth
        };
        this.chartBars.forEach(bar => {
          const tl = new TimelineMax();
          const label = this.labels[this.chartBars.indexOf(bar)];
          if (this.mouse.x > bar.coords[0] && this.mouse.x < bar.coords[0] + this.columnWidth) {
            if (this.selected !== this.chartBars.indexOf(bar)) {
              tl.to(bar.props, 1, {color: blackOrWhiteColorWithOpacity(0.1)});
            }
            this.selected = this.chartBars.indexOf(bar);
            this.createOverlay(this.chartBars.indexOf(bar), this.mouse.y + 20, this.mouse.x + 20);
          } else {
            tl.to(bar.props, 1, {color: blackOrWhiteColorWithOpacity(0)});
          }
        });
      };
      this.htmlcanvas.addEventListener('mousemove', handleMouse);
      timeout(() => this.draw(), 400);
      InteractionHandler.clear();
      timeout(() => {
        InteractionHandler.always(InteractionDirection.DOWN, () => {
          if (!this.companyDropdown.isOpen() && !this.factorDropdown.isOpen()) {
            this.stopRender = true;
            Director.nextScene();
          }
        });
      }, 1000);
      timeout(() => {
        InteractionHandler.always(InteractionDirection.UP, () => {
          if (!this.companyDropdown.isOpen() && !this.factorDropdown.isOpen()) {
            this.stopRender = true;
            Director.previousScene();
          }
        });
      }, 1000);
    },

    calculateTimeScale() {
      for (let i = 0; i < this.chartLines.length; i++) {
        const line = this.chartLines[i];
        const label = this.xLabels[i];
        line.coords[0] = this.xOffset + this.yAxisWidth + i * this.columnWidth + this.offset;
        line.coords[2] = this.xOffset + this.yAxisWidth + i * this.columnWidth + this.offset;
        label.props.left = this.xOffset + this.yAxisWidth + i * this.columnWidth + this.columnWidth / 2 + this.offset;
      }
    },

    calculateMinMax() {
      if (this.charts.length === 0) {
        return { min: -0.5, max: -0.5 };
      }
      // calculate min and max data values so as to be able to span values to canvas size.
      let min = 10;
      let max = -10;
      this.charts.forEach(chart => {
        chart.data.forEach(data => {
          data.values.forEach(value => {
            min = Math.min(min, value);
            max = Math.max(max, value);
          });
        });
      });
      return { min, max };
    },

    addNewChart(stock, factor, noanim) {
      const chart = {
        label: factor ? factor.label : stock.assetName,
        coordinates: [],
        color: factor ? factor.color : stock.color,
        dataLines: [],
        data: this.generateChartData(stock[factor ? factor.label.toLowerCase() : this.selectedFactor.toLowerCase()])
      };
      this.charts.push(chart);
      
      if (!noanim) {
        const minMax = this.calculateMinMax();
        const tl = new TimelineMax();
        this.startYear = 2021;
        this.charts.forEach(chart => {
          chart.data.forEach(data => {
            if (data.values.length > 0) {
              this.startYear = Math.min(this.startYear, parseInt(data.label));
            }
          });
        });
        const diff = this.startYear - 2006;
        const newColumnWidth = Math.round((this.htmlcanvas.width - this.yAxisWidth - this.xOffset * 2) / (this.charts[0].data.length - diff));

        tl.to(this, 1, {
          min: minMax.min,
          max: minMax.max,
          offset: -newColumnWidth * diff,
          columnWidth: newColumnWidth,
          onUpdate: () => {
            this.calculateTimeScale();
            this.rescale(this.min, this.max);
            this.calculateChartCoordinates(true);
            this.calculateDataCoordinates(true);
          }
        });
      }
      if (factor) {
        factor.currentChart = chart;
      }
      stock.currentChart = chart;
    },

    draw() {
      let startTime;
      const duration = 3000;
      timeout(() => {
        this.chartLines.forEach(chartLine => {
          const tl = new TimelineMax();
          tl.to(chartLine.coords, 1, {3: 40});
          tl.to(chartLine.props, 3, {color: blackOrWhiteColorWithOpacity(0.1)}, '=-1');
        });
        const tly = new TimelineMax();
        var offset = this.hideYAxisExtremeLabels && this.scale.getMaxTicks() > 3 ? -1 * this.scale.getTickSpacing() * this.ratio: 0;
        this.yLabels.forEach( (yLabel, i) => {
          //const y = offset + (this.htmlcanvas.height - this.xAxisHeight) - i * this.scale.getTickSpacing() * this.ratio;
          const y = (this.htmlcanvas.height - this.xAxisHeight) - ((parseFloat(yLabel.text) - this.min) * this.ratio + yLabel.height / 2);
          if ( y > this.titleHeight + 14) {
            tly.to(yLabel.props, 0.5, {color: blackOrWhiteColorWithOpacity(1), top: y}, '=-0.25');
          };
        });
      }, duration);
      this.drawing = true;
      const drawFrame = (time) => {
        this.charts.forEach((chart, i) => {
          if (!startTime || this.reset) {
            this.reset = false;
            startTime = time;
          }
          if (!chart.startTime || this.reset) {
            this.reset = false;
            chart.startTime = time;
          }
          const progress = Math.min(1, (time - chart.startTime) / duration);
  
          const index = Math.round(progress * (chart.coordinates.length));
          chart.dataLines.forEach((line, idx) => {
            if (this.drawing && !chart.isFading) {
              if (index === idx) {
                line.props.color = 'rgba(255, 255, 255, 1)';//blackOrWhiteColorWithOpacity(1);
                line.props.visible = true;
              } else if (index > idx && !line.changedColor) {
                line.changedColor = true;
                const tl = new TimelineMax();
                tl.to(line.props, 0.4, {color: chart.color});
                line.props.visible = true;
              } if (index < idx) {
                line.props.visible = false;
              }
            }
          });
          const year = this.xLabels[Math.round(progress * (this.xLabels.length - 1))];
          if (year && !year.props.visible) {
            year.props.visible = true;
            const tl = new TimelineMax();
            tl.to(year.props, 1, {color: blackOrWhiteColorWithOpacity(0.9)});
          }
          /*const line = this.charts[i].dataLines[index];
          if (line) {
            const yearIndex = Math.floor(line.coords[0] / this.columnWidth);
            const year = this.xLabels[yearIndex];
            if (year && !year.props.visible) {
              year.props.visible = true;
              const tl = new TimelineMax();
              tl.to(year.props, 1, {color: blackOrWhiteColorWithOpacity(0.9)});
            }
          }*/
          
        });

        this.canvas.render();

        if (this.recalculateXLabelPosition) {
          this.calculateXLabelVerticalPositions();
        }

        if (!this.stopRender) {
          requestAnimationFrame(drawFrame);
        }
      };
      requestAnimationFrame(drawFrame);
    }
  };
};

export function ekgStockDiagramScene(mode) {
  return new EkgDiagramScene(mode);
}

export function ekgFundDiagramScene(mode) {
  return new EkgDiagramScene(mode);
}