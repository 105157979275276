import { DataSource } from "./datasource";

export function portraitBlock(rootElementId) {
  DataSource.fetch('text').then(text => {
    var msg = text.portrait;
    var template = 
    `<div class="txt">
        ${msg}
    </div>`;

    var blockDiv = document.createElement('div');
    blockDiv.id = "portrait_blocker";
    blockDiv.className = "text-center";
    blockDiv.innerHTML = template;
    document.getElementById(rootElementId).parentElement.appendChild(blockDiv);
  });
}