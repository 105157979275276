export function FloatingBall(x, y, radius, color, variant) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.color = color;
    this.variant = variant || FloatingBallAnimationVariant.NORMAL;
    return this;
}

export const FloatingBallAnimationVariant = {
  NORMAL: 'NORMAL',
  BACKWARDS: 'BACKWARDS',
  SLOW: 'SLOW',
  SLOW_BACKWARDS: 'SLOW_BACKWARDS',
  SMALL_CIRCLE: 'SMALL_CIRCLE'
};

function toCssClass(variant) {
  switch(variant) {
    case FloatingBallAnimationVariant.NORMAL:
      return 'float-normal';
    case FloatingBallAnimationVariant.BACKWARDS:
      return 'float-backwards';
    case FloatingBallAnimationVariant.SLOW:
      return 'float-slow';
    case FloatingBallAnimationVariant.SLOW_BACKWARDS:
      return 'float-backwards-slow';
    case FloatingBallAnimationVariant.SMALL_CIRCLE:
      return 'float-small-circle';
    default:
      return 'float-normal';
  }
}

FloatingBall.prototype.toHtmlNode = function() {
  var node = document.createElement('div');
  node.style.left = this.x / 1600 * 100 + "%";
  node.style.top = this.y / 900 * 100 + "%";
  node.style.width = this.radius + "px";
  node.style.height = this.radius + "px";
  node.style.backgroundColor = this.color;
  node.classList.add('floating-ball');
  node.classList.add(toCssClass(this.variant));
  return node;
};
