import { Director } from "./director";
import { msieversion } from "./browser-checker";

export const InteractionHandler = {
  xTouchStart: null,
  yTouchStart: null,
  callbacks: {},

  init() {
    var self = this;
    document.addEventListener('wheel', (evt) => { self.handleScroll(evt); }, false);
    document.addEventListener('touchstart', (evt) => { self.handleTouchStart(evt); }, false);        
    document.addEventListener('touchmove', (evt) => { self.handleTouchMove(evt); }, false);
    document.addEventListener('keyup', (evt) => { self.handleKeyup(evt); }, false);
  },

  handleScroll(evt) {
    var delta = (evt.wheelDelta || evt.deltaY) * (!!msieversion() ? -1 : 1);

    if (delta > 0) {
      this.broadcast(InteractionType.SCROLL_UP);
    } else {
      this.broadcast(InteractionType.SCROLL_DOWN);
    }
  },

  handleKeyup(evt) {
    switch (evt.keyCode) {
      case 32: 
        this.broadcast(InteractionType.SPACE_KEY);
        break;
      case 38:
        this.broadcast(InteractionType.ARROW_UP);
        break;
      case 40:
        this.broadcast(InteractionType.ARROW_DOWN);
        break;
      default:
        break;
    }
  },

  handleTouchStart(evt) {
    const firstTouch = evt.touches[0];
    this.xTouchStart = firstTouch.clientX;
    this.yTouchStart = firstTouch.clientY;
    this.startingPosition = this.getStartingScrollPositions();
  },

  getStartingScrollPositions () {
    var docHeight = document.documentElement.scrollHeight;
    return {
      onBottom: (window.innerHeight + window.pageYOffset) >= docHeight,
      onTop: window.pageYOffset === 0
    };
  },

  isOverflowable() {
    return Director.currentScene && Director.currentScene.overflow === 'visible';
  },

  handleTouchMove(evt) {
    if ( !this.xTouchStart || ! this.yTouchStart || !this.startingPosition ) {
        return;
    }

    var xMove = evt.touches[0].clientX;                                    
    var yMove = evt.touches[0].clientY;
    var xDiff = this.xTouchStart - xMove;
    var yDiff = this.yTouchStart - yMove;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      if ( xDiff > 0 ) {
        this.broadcast(InteractionType.SWIPE_LEFT);
      } else {
        this.broadcast(InteractionType.SWIPE_RIGHT);
      }                       
    } else {
      if ( yDiff > 0 ) {
        if (this.isOverflowable()) {
          this.startingPosition.onBottom && this.broadcast(InteractionType.SWIPE_UP);
        } else {
          this.broadcast(InteractionType.SWIPE_UP);
        }
      } else { 
        this.startingPosition.onTop && this.broadcast(InteractionType.SWIPE_DOWN);
      }                                                                 
    }

    this.xTouchStart = null;
    this.yTouchStart = null;
    this.startingPosition = null;
  },

  broadcast(interaction) {
    var callbackObjects = this.callbacks[interaction];
    if (!callbackObjects) {
      return;
    }

    var i = callbackObjects.length;
    while(i--) {
      var cbObj = callbackObjects[i];
      cbObj.fn();

      if (cbObj.clear) {
        this.callbacks = {};
        return;
      }

      if (cbObj.once) {
        callbackObjects.splice(i, 1);
      }
    }
  },

  addCallback(interaction, fn, once, clear) {
    this.callbacks[interaction] = this.callbacks[interaction] || [];
    this.callbacks[interaction].push({ fn, once, clear });
  },

  on(interaction, fn, clear = true) {
    if (Array.isArray(interaction)) {
      interaction.forEach((i) => {
        this.addCallback(i, fn, true, clear);
      });
    } else {
      this.addCallback(interaction, fn, true, clear);
    }
  },

  always(interaction, fn) {
    if (Array.isArray(interaction)) {
      interaction.forEach((i) => {
        this.addCallback(i, fn, false);
      });
    } else {
      this.addCallback(interaction, fn, false);
    }
  },

  clear() {
    this.callbacks = {};
  }
};

export const InteractionType = {
  SCROLL_UP: 'SCROLL_UP',
  SCROLL_DOWN: 'SCROLL_DOWN',
  SWIPE_LEFT: 'SWIPE_LEFT',
  SWIPE_RIGHT: 'SWIPE_RIGHT',
  SWIPE_UP: 'SWIPE_UP',
  SWIPE_DOWN: 'SWIPE_DOWN',
  SPACE_KEY: 'SPACE_KEY',
  ARROW_UP: 'ARROW_UP',
  ARROW_DOWN: 'ARROW_DOWN'
};

export const InteractionDirection = {
  DOWN: [InteractionType.SCROLL_DOWN, InteractionType.SPACE_KEY, InteractionType.SWIPE_UP, InteractionType.ARROW_DOWN],
  UP: [InteractionType.SCROLL_UP, InteractionType.SWIPE_DOWN, InteractionType.ARROW_UP]
};
