import "./bubble-menu.animation.scss";
import { pSBC } from "../../vendor/js/psbc";

export function bubbleMenuAnimation(element, opts) {
  opts = opts || {};

  return {
    position: opts.position || {
      left: 30,
      top: 80
    },
    ballMenu: opts.data || [],
    numberOfBalls: opts.numberOfBalls || 8,
    ballHeight: opts.ballHeight || 155,
    ballWidth: opts.ballWidth || 155,
    targetScale: opts.targetScale || 1.15,

    fullHdWindowWidth: 1920,
    fullHdWindowHeight: 1080,
    windowWidthPercentage: 100,
    windowHeightPercentage: 100,
    windowPercentage: 100,
    windowResolution: window.innerWidth,
    windowResolutionHeight: window.innerHeight,
    colors: [],
    selected: 0,
    distance: element.clientWidth,
    animated: false,
    balls: [],
    ballWrappers: [],
    scale: 0,
    ballsTimelines: [],
    wrapperTimeline: null,
    animationCompleted: false,

    prepare() {
      this.calcScale();
      this.createElements();
      this.setSizes();
      this.generateMenuItems();
      return this;
    },

    calcScale() {
      var x = this.windowResolution/this.fullHdWindowWidth;
      var y = this.windowResolutionHeight / this.fullHdWindowHeight;
      this.scale = Math.min(1.25, x < y ? y*this.targetScale : x*this.targetScale);
      this.scale = this.scale < 1 ? 1 : this.scale;
    },

    cancelTimelines() {
      this.wrapperTimeline && this.wrapperTimeline.kill();
      this.ballsTimelines.forEach(tl => { tl && tl.kill(); });
    },

    createElements() {
      this.ballMenuContainer = document.createElement('div');
      this.ballMenuContainer.className = "ball-menu-container";
      this.ballMenuElement = document.createElement('div');
      this.ballMenuElement.className = 'ball-menu';
      this.ballMenuContainer.appendChild(this.ballMenuElement);
      element.appendChild(this.ballMenuContainer);
    },

    generateMenuItems() {
      this.ballWidth = this.distance * 0.9 / (this.numberOfBalls + 1);
      this.ballHeight = this.ballWidth;
      this.blockWidth = this.ballWidth + 30;
      this.shownBalls = [];
      this.hiddenBalls = [];

      this.ballMenu.forEach((item, i) => {
        let div = document.createElement('div');
        div.id = item.id;
        div.className = "menu-balls-wrapper";
        div.style.width = this.ballWidth + 'px';
        div.style.height = this.ballWidth + 'px';
        let ballWrapper = document.createElement('div');
        ballWrapper.className = "menu-ball";
        ballWrapper.style.width = this.blockWidth+ 'px';
        ballWrapper.style.height = this.ballHeight + 'px';
        ballWrapper.appendChild(div);
        this.ballWrappers.push(ballWrapper);
        this.ballMenuElement.appendChild(ballWrapper);
        var item2 = JSON.parse(JSON.stringify(item));
        var item3 = JSON.parse(JSON.stringify(item));
        var item4 = JSON.parse(JSON.stringify(item));
        item.id = item.id + "0";
        item.parent = div;

        item2.color = pSBC(-0.225, item2.color);
        item2.id = item2.id + "1";
        item2.parent = div;
        
        item3.color = pSBC(-0.435, item3.color);
        item3.id = item3.id + "2";
        item3.parent = div;
        
        item4.color = pSBC(-0.605, item4.color);
        item4.id = item4.id + "3";
        item4.parent = div;

        this.shownBalls.push(item4);
        this.shownBalls.push(item3);
        this.shownBalls.push(item2);
        this.shownBalls.push(item);
      });

      this.shownBalls.forEach((item, i) => {
        let color = item.id.substring(0, item.id.length - 1);
        if(!this.colors.includes(color)) {
          this.colors.push(color);
        }
        this.createBalls(item);
      });
    },

    setSizes() {
      this.windowPercentage = (this.windowResolution / this.fullHdWindowWidth) + (this.windowResolutionHeight / this.fullHdWindowHeight) * 100;
      this.windowWidthPercentage = this.windowResolution / this.fullHdWindowWidth * 100;
      this.windowHeightPercentage = this.windowResolutionHeight / this.fullHdWindowHeight * 100;
      this.ballHeight = this.windowPercentage/100 * (opts.ballHeight || 155);
      this.ballWidth = this.windowPercentage/100 * (opts.ballWidth || 155);
    },

    previous() {
      this.selected = this.selected > 0 ? this.selected - 1 : this.colors.length - 1;
      this.selectItem();
    },

    next() {
      this.selected = this.selected < this.colors.length - 1 ? this.selected + 1 : 0;
      this.selectItem();
    },

    selectItem() {
      this.colors.forEach(color => {
        let tl = new TimelineMax();
        const obj = document.getElementById(`${color}0`);
        tl.to(obj, 0.5, {opacity: (this.colors.indexOf(color) !== this.selected ? 0.25 : 1)});
        tl.to(obj, 0.5, {scale: (this.colors.indexOf(color) !== this.selected ? 1 : 1.25)}, '=-0.5');
      });

      if (typeof opts.onSelectionChange === "function") {
        opts.onSelectionChange(this.ballMenu[this.selected]);
      }
    },


    createBalls(item, hide){
      let ball = document.createElement('div');
      ball.className = "menu-balls";
      ball.style.width = this.ballWidth.toString() + 'px';
      ball.style.height = this.ballHeight.toString() + 'px';
      ball.style.backgroundColor = item.color;
      ball.style.borderRadius = this.ballWidth/2 + 'px';
      ball.style.top = -this.ballHeight / 2 + 'px';
      ball.id = item.id;
      ball.setAttribute('data-factor-title', item.title);
      this.balls.push(ball);
      item.parent.appendChild(ball);
      
      if (hide) {
        ball.style.opacity = 0;
      }

      let self = this;
      ball.onclick = function() {
        if (!self.animated) {
          return;
        }
        self.selected = self.colors.indexOf(item.id.slice(0, item.id.length-1));
        self.selectItem();

        if (typeof opts.ballClickCallback !== 'function') {
          return;
        }

        opts.ballClickCallback();
      };
    },

    setBubbles(color, addMenuDetails) {
      this.ballMenu.forEach(item => {
        if(item.id == (color + '0')) {
          const ball = document.getElementById(`${color}0`);
          ball.style.backgroundImage = `url(${item.logo})`;
          ball.style.backgroundSize = this.ballWidth/1.45 + 'px';
          ball.style.backgroundRepeat = 'no-repeat';
          ball.style.backgroundPosition = 'center';

          if (addMenuDetails) {
            ball.style.top = this.position.top / 10 * this.windowPercentage + 'px';
            ball.style.opacity = this.colors.indexOf(color) !== this.selected ? 0.25 : 1;
          }
        }
      });
    },

    showHiddenBalls() {
      this.hiddenBalls.forEach(item => {
        this.createBalls(item, true);
        let color = item.id.substring(0, item.id.length - 1);
        if(!this.colors.includes(color)) {
          this.colors.push(color);
          this.setBubbles(color, true, true);
        }
      });
    },

    animate() {
      this.animated = true;

      this.colors.forEach(color => {
        for (let i = 0; i < 4; i++) {
          const obj = document.getElementById(`${color}${i}`);
          const tl = this.ballsTimelines[i] = new TimelineMax({onComplete: () => {
              if (i > 0) {
                obj.parentElement.removeChild(obj);
              }
            }});
          let diff = i * 0.2 / 100 * this.windowPercentage;
          let diff2 = -i * 0.2 - 0.75;
          tl.to(obj, 0.75, {top: this.position.top/2 / 10 * this.windowPercentage, ease: Linear.ease}, `=+${diff}`);
          tl.to(obj, 0.75, {height: 400 / 100 * this.windowPercentage, ease: Linear.ease}, `=${diff2}`);
          tl.to(obj, 1, {height: this.ballHeight, width: this.ballWidth, ease: Linear.ease}, `=-0.5`);
          tl.to(obj, 1, {top: this.position.top / 10 * this.windowPercentage, margin: 'auto', ease: Linear.ease}, `=-1`);
          tl.to(obj, 1, {opacity: (this.colors.indexOf(color) !== this.selected ? 0.25 : 1)});
        }
        this.setBubbles(color);
      });
      
      this.wrapperTimeline = new TimelineMax({onComplete: () => { this.selectItem(); this.showHiddenBalls(); this.animationCompleted = true; }});
      this.wrapperTimeline.to(this.ballMenuElement, 1, {width: Math.ceil(this.numberOfBalls * this.blockWidth), ease: Sine.easeInOut}, '=-2');
      this.wrapperTimeline.to(this.ballMenuElement, 1, {scale: this.scale, ease: Sine.easeInOut}, '=+2');
      this.wrapperTimeline.to(this.ballMenuElement, 1, {top: (-this.position.top*this.scale+50) + '%', left: -this.ballWidth/2, ease: Sine.easeInOut}, '=-1');

      return this;
    },

    cancelAnimation() {
      this.ballsTimelines.forEach(tl => {
        tl.clear();
        tl.kill();
      });
      this.wrapperTimeline.clear();
      this.wrapperTimeline.kill();
    }
  };
};
