import '../facs-sectors/facs-sectors-model.scss';
import './3d-scene-1.scss';
import { typewriterAnimation } from '../../animations/typewriter/typewriter.animation';
import { lineByLineReveal } from '../../animations/line-by-line-reveal/line-by-line-reveal.animation';
import { InteractionDirection, InteractionHandler } from '../../scripts/interaction-handler';
import { TimelineMax } from "gsap";
import example1Scene from "../example-1-scene/example-1.scene";
import { DataSource } from "../../scripts/datasource";
import { Director } from "../../scripts/director";
import { get2DCoords, getColor, timeout, clearAllTimeouts } from "../../scripts/util";
import { Canvas, CanvasLine, CanvasText, CanvasRect, CanvasImage } from "../../scripts/canvas";
import { curvedTextAnimation } from "../../animations/curved-text/curved-text.animation";
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';
import { spinnerEffectAnimation } from '../../animations/spinner-effect/spinner-effect.animation';
import { GTAGHandler } from '../../scripts/gtaghandler';

export default function Scene3D(mode) {
  mode = mode || '';
  return {
    $$param: mode,
    template: `
    <div class="page-wrapper facs-sectors-page-wrapper" id="${mode}facs-sectors-page-wrapper-id">
      <div class="facs-sectors-page-header" id="${mode}facs-sectors-page-header-id">
        <div class="logo-wrapper"><a target="_blank" href="http://www.itware.hu"><img class="header-visualization-poc-logo" id="${mode}facs-sectors-page-logo" src="{{ text.logo }}"></a></div>
      </div>
      <div class="facs-sectors-content-wrapper">
        <div class="col-lg-6 col-md-12">
          <div id="${mode}facs-sectors-content-id" class="facs-sectors-content">{{ text.description }}</div>
        </div>
        <div class="progress-bar-wrapper col-lg-6 col-md-12">
          <div class="progress-bar-content">
            <div class="progress-bar-text" id="${mode}prog-bar-negative">{{ text.barText.negative }}</div>
            <div class="facs-sectors-exposure-bar scale col-lg-8 col-md-8" id="${mode}progress-bar">
              <div class="exposure-bar">
                <div class="red1 first base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-r2">
                <div class="red2 base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-r3">
                <div class="red3 base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-y1">
                <div class="orange base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-y2">
                <div class="yellow base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-g1">
                <div class="green1 base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-g2">
                <div class="green2 base-bar"></div>
              </div>
              <div class="exposure-bar" id="${mode}exposure-bar-g3">
                <div class="green3 last base-bar"></div>
              </div>
            </div>
            <div class="progress-bar-text" id="${mode}prog-bar-positive">{{ text.barText.positive }}</div>
          </div>
          <div class="exposure-bar-label" id="${mode}exposure-bar-id">{{ text.barText.label }}</div>
        </div>
      </div>
    </div>
    <div id="${mode}wrapper">
      <div class="change-to-3d${mode === 'tour' ? ' hidden' : ''}"><button id="${mode}change-to-3d-button"> </button><span>3D View</span></div>
      <div class="change-to-2d${mode === 'tour' ? ' hidden' : ''}"><button id="${mode}change-to-2d-button"> </button><span>2D View</span></div>
      <div id="${mode}back-to-3d-wrapper" class="hidden"><button id="${mode}back-to-3d-button"> </button><span>Go Back</span></div>
      <canvas id="${mode}overlaycanvas"></canvas>
      <div id="${mode}overlaydiv"></div>
      <div id="${mode}row-tooltip" class="tooltip-3d">Click on a label to select a row<img src="/assets/images/right_arrow_white.png"/></div>
      <div id="${mode}col-tooltip" class="tooltip-3d"><img src="/assets/images/left_arrow_white.png"/>Click on a label to select a column</div>
      <div id="${mode}camera-tooltip" class="tooltip-3d"><img src="/assets/images/move.png"/><br/>Left click to rotate the camera<br/>Right click to pan the camera</div>
    </div>
    <div id="${mode}contentoverlay">
      <div id="${mode}step-content">
        <img id="${mode}step-icon" src="url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=)">
        <div id="${mode}step-text"></div>
      </div>
      <div class="stepper-controls">
        <div class="steps"><span id="${mode}current-step">1</span> of <span id="${mode}total-steps">4</span></div>
        <div style="flex: 1 1 auto"></div>
        <button id="${mode}stepper-button-left">←</button>
        <div id="${mode}progress" data-progress="0">
          <i class='material-icons' id="${mode}pause-button">pause</i>
        </div>
        <button id="${mode}stepper-button-right">→</button>
      </div>
    </div>`,
    background: mode === 'tour' ? '#222222' : '#ffffff',
    injectCanvas: false,
    transition: {
      enter: 'slide',
      leave: 'slide'
    },

    mouseDownTime: null,
    mouse: new THREE.Vector2(),
    raycaster: new THREE.Raycaster(),
    camera: new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 10000),
    scene: new THREE.Scene(),
    sideMode: false,
    meshes: [],
    showShadows: true,
    rows: [],
    columns: [],
    icons: [],
    tour: null,
    colLabelMeshes: [],
    rowLabelMeshes: [],
    targetRow: null,
    targetCol: null,
    width: 0.25,
    height: 0.1,
    light: new THREE.PointLight(0xFFFFFF, 1, 1000),
    data: [],
    gui: null,
    overlayCanvas: null,
    overlayCanvasEl: null,
    overlayLines: {},
    focusedCells: [],
    overlayDiv: null,
    targetVector: null,
    currentStep: 1,
    pauseButton: null,
    text: {},
    exposureLabelEl: null,
    pageWrapper: null,
    facsTextEl: null,
    progBarNegative: null,
    progBarPositive: null,
    barList: [],
    stopRender: false,
    viewMode: null,
    changeTo2dButton: null,
    changeTo3dButton: null,
    backTo3dButton: null,
    backTo3dWrapper: null,
    adminMode: false,
    renderer: null,
    lastCameraState: {},
    needsRender: true,
    prevStepButton: null,
    nextStepButton: null,
    rowTooltip: null,
    colTooltip: null,
    cameraTooltip: null,
    recoloredImageCache: {},

    resizeHandler: function() {
      /*if (this.renderer) {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.overlayCanvasEl.width = window.innerWidth;
        this.overlayCanvasEl.height = window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.arrangeValueLabels(true);
        this.needsRender = true;
      }*/
    },

    prev () {
      Director.toScene(example1Scene);
    },

    onDestroy() {
      return new Promise(resolve => {
        TweenMax.killAll();
        window.removeEventListener('resize', this.resizeHandler);
        clearAllTimeouts();
        timeout(() => {
          this.renderer.forceContextLoss();
          this.renderer.context = null;
          this.renderer.domElement = null;
          this.renderer = null;
        }, 1000);
        this.hideAllLabels();
        timeout(() => resolve());
      });
    },

    preload() {
      return new Promise(resolve => {
        const promises = [
          DataSource.fetch('barChartMatrixLabels').then(data => {
            this.rows = data.rows;
            this.columns = data.columns.map(column => column.label);
            this.icons = data.columns.map(column => {
              return {
                url: column.icon,
                color: column.color
              };
            });
          }),
          DataSource.fetch('barChartMatrix').then(data => this.data = data),
          DataSource.fetch('tour').then(tour => this.tour = tour),
          DataSource.fetch('text').then(txt => this.text = txt.pages[this.$$param === 'tour' ? 'facsTourFirstPage' : 'facsTextPage'])
        ];
        Promise.all(promises).then(() => resolve());
      });
    },

    init (renderer, mode) {
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setClearColor("#222222", 0);
      this.renderer.setSize(1920, 1920 * window.innerHeight / window.innerWidth);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.initElements();
      const wrapperEl = document.getElementById(mode + 'wrapper');
      if (mode === 'tour') {
        this.pageWrapper.classList.add('dark');
      }
      wrapperEl.style.top = '70%';
      this.animation();

      this.changeTo2dButton = document.getElementById(mode + 'change-to-2d-button');
      this.changeTo3dButton = document.getElementById(mode + 'change-to-3d-button');
      this.backTo3dButton = document.getElementById(mode + 'back-to-3d-button');
      this.backTo3dWrapper = document.getElementById(mode + 'back-to-3d-wrapper');
      circleButtonAnimation(this.changeTo2dButton);
      this.changeTo2dButton.addEventListener('click', event => {
        if (this.viewMode !== 2) {
          this.changeTo2D();
          this.backTo3dWrapper.className = 'hidden';
        }
      });
      circleButtonAnimation(this.changeTo3dButton);
      this.changeTo3dButton.addEventListener('click', event => {
        if (this.viewMode !== 3) {
          this.changeTo3D();
        }
      });
      circleButtonAnimation(this.backTo3dButton);
      this.backTo3dButton.addEventListener('click', event => {
        this.changeTo3D();
        this.backTo3dWrapper.className = 'hidden';
      });
      const stepperButtonLeft = document.getElementById(mode + 'stepper-button-left');
      this.prevStepButton = circleButtonAnimation(stepperButtonLeft, { color: 'white' });
      stepperButtonLeft.addEventListener('click', event => {
        event.preventDefault();
        event.stopImmediatePropagation();
        GTAGHandler.addClickEvent(event);
        this.prevStep();
      });
      this.prevStepButton.disable();
      const stepperButtonRight = document.getElementById(mode + 'stepper-button-right');
      this.nextStepButton = circleButtonAnimation(stepperButtonRight, { color: 'white' });
      stepperButtonRight.addEventListener('click', event => {
        event.preventDefault();
        event.stopImmediatePropagation();
        GTAGHandler.addClickEvent(event);
        this.nextStep();
      });
      this.pauseButton = document.getElementById(mode + 'pause-button');
      spinnerEffectAnimation(this.pauseButton, () => this.nextStep());
      this.pauseButton.pause();
      this.rowTooltip = document.getElementById(mode + 'row-tooltip');
      this.colTooltip = document.getElementById(mode + 'col-tooltip');
      this.cameraTooltip = document.getElementById(mode + 'camera-tooltip');
      wrapperEl.appendChild(this.renderer.domElement);
      this.renderer.domElement.style.transformOrigin = 'top left';
      this.renderer.domElement.style.transform = `scale(${window.innerWidth / 1920})`;
      this.overlayDiv = document.getElementById(mode + 'overlaydiv');
      this.overlayDiv.style.zIndex = 100;
      this.overlayCanvasEl = document.getElementById(mode + 'overlaycanvas');
      this.overlayCanvasEl.width = window.innerWidth;
      this.overlayCanvasEl.height = window.innerHeight;
      this.overlayCanvasEl.style.zIndex = 100;
      this.overlayCanvas = new Canvas(this.overlayCanvasEl);
      this.camera.position.z = 0.5;

      window.addEventListener('resize', this.resizeHandler.bind(this));

      this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableKeys = !!this.adminMode;
      this.controls.enableZoom = !!this.adminMode;
      if (this.adminMode) {
        this.controls.addEventListener( 'change', () => this.showCameraPosition() );
      } else {
        this.controls.addEventListener( 'change', () => this.hideTooltips());
      }

      var fovRad = this.camera.fov * Math.PI / 180; 
      var dist = Math.max(
        (this.rows.length * this.height + 0.3) / 2 / Math.tan(fovRad / 2),
        (this.columns.length * this.width + 0.7) / 2 / Math.tan(fovRad / 2) * window.innerHeight / window.innerWidth
      );
      this.camera.position.set((this.columns.length - 1) * this.width / 2, dist, this.rows.length * this.height / 2);
      this.camera.lookAt((this.columns.length - 1) * this.width / 2, 0, this.rows.length * this.height / 2);
      if (this.controls) {
        this.controls.target.set((this.columns.length - 1) * this.width / 2, 0, this.rows.length * this.height / 2);
        this.controls.enabled = this.adminMode ? true : false;
        this.controls.update();
      }
      this.scene.add(this.light);

      this.light.shadow.mapSize.width = 2048;
      this.light.shadow.mapSize.height = 2048;
      this.light.shadow.camera.near = 0.1;
      this.light.shadow.camera.far = 500;

      this.scene.add(new THREE.AmbientLight(0x222222));

      this.camera.rotation.z = 0;


      this.renderer.domElement.addEventListener('click', e => { this.onClick.call(this, e); });
      this.renderer.domElement.addEventListener('touchend', e => { this.onClick.call(this, e); });
      this.renderer.domElement.addEventListener('touchmove', e => { this.onMouseMove(e); });
      this.renderer.domElement.addEventListener('mousemove', e => { this.onMouseMove(e); });
      this.renderer.domElement.addEventListener('touchstart', e => {
        this.mouseDownTime = Date.now();
      });
      this.renderer.domElement.addEventListener('mousedown', e => {
        this.mouseDownTime = Date.now();
      });

      for (var row = 0; row < this.rows.length; row++) {
        const rowLabelMesh = this.createPlaneWithText(this.rows[row]);
        rowLabelMesh.position.set(
          -rowLabelMesh.metadata.width / 2 - 0.17,
          0,
          row * this.height + 0.01
        );
        rowLabelMesh.metadata.row = row;
        rowLabelMesh.metadata.rowLabel = true;
        this.scene.add(rowLabelMesh);
        var rowMeshes = [];
        this.rowLabelMeshes.push(rowLabelMesh);
        for (var column = 0; column < this.columns.length; column++) {
          if (row === 0) {
            let colLabelMesh = this.createPlaneWithText(this.columns[column], this.icons[column]);
            colLabelMesh.position.set(
              column * this.width - (this.width - colLabelMesh.metadata.width) / 2 + 0.04,
              0,
              -0.13
            );
            colLabelMesh.metadata.col = column;
            colLabelMesh.metadata.colLabel = true;
            this.colLabelMeshes.push(colLabelMesh);
            this.scene.add(colLabelMesh);

            colLabelMesh = this.createPlaneWithText(this.columns[column], this.icons[column]);
            colLabelMesh.position.set(
              column * this.width - (this.width - colLabelMesh.metadata.width) / 2 + 0.04,
              0,
              this.rows.length * this.height + colLabelMesh.metadata.width / 2 - 0.03
            );
            colLabelMesh.metadata.col = column;
            colLabelMesh.metadata.colLabel = true;
            colLabelMesh.metadata.bottomLabel = true;
            colLabelMesh.rotation.z = -Math.PI / 2;
            this.colLabelMeshes.push(colLabelMesh);
            this.scene.add(colLabelMesh);
          }
          var geometry = new THREE.BoxGeometry(this.width - 0.001, 0.01 /*Math.abs(data[row][column])*/, this.height - 0.001);
          const value = this.data[row][column];
          var material = new THREE.MeshLambertMaterial({ color: getColor(value, -1, 1, 1), transparent: true, opacity: 1 });
          const mesh = new THREE.Mesh(geometry, material);
          mesh.position.x = column * (this.width /*- 0.003*/);
          mesh.position.y = 0; //data[row][column] < 0 ? data[row][column] /2 : 0;
          mesh.position.z = row * (this.height /*- 0.003*/);
          mesh.castShadow = true;
          mesh.receiveShadow = true;
          
          this.scene.add(mesh);
          const coords = get2DCoords(new THREE.Vector3(mesh.position.x, mesh.position.y, mesh.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
          const rowLabelSpan = document.createElement('span');
          rowLabelSpan.className = 'label';
          rowLabelSpan.innerText = this.rows[row];
          rowLabelSpan.style.width = '100px';
          const colLabelSpan = document.createElement('span');
          colLabelSpan.className = 'label';
          if (mode === 'tour') {
            colLabelSpan.innerHTML = `${this.columns[column]}`;
          } else {
            const coloredImage = new Image();
            const icon = this.icons[column];
            /*if (!this.recoloredImageCache[icon.url]) {
              const image = new Image();
              image.onload = () => {
                // recolor SVG image
                const recolorCanvas = document.createElement('canvas');
                recolorCanvas.width = 50;
                recolorCanvas.height = 50;
                const ctx = recolorCanvas.getContext('2d');
                ctx.drawImage(image, 0, 0, 50, 50);
                const imageData = ctx.getImageData(0, 0, 50, 50);
                const color = icon.color.substr(icon.color.indexOf('(') + 1).split(',').map(i => parseInt(i));
                for (let i = 0; i < imageData.data.length; i += 4) {
                  for (let j = 0; j < color.length; j++) {
                    // this practically inverts the image, colored pixels will be black, black pixels will have the new color
                    // this is needed because the Q in Quality.svg is colored and it would disappear after simply tinting the image
                    if (imageData.data[i + j] < 255) {
                      imageData.data[i + 3] = 0;
                    } 
                    imageData.data[i + j] = imageData.data[i + j] === 255 ? color[j] : 0;
                  }
                }
                ctx.putImageData(imageData, 0, 0);
                this.recoloredImageCache[icon.url] = recolorCanvas.toDataURL('image/png'); // png preserves alpha channel
                coloredImage.src = this.recoloredImageCache[icon.url];
              };
              image.src = icon.url;
            } else {
              coloredImage.src = this.recoloredImageCache[icon.url];
            }
            colLabelSpan.appendChild(coloredImage);*/
            colLabelSpan.innerHTML = `<img style="width: 50px; height: 50px;" src="${icon.url}" />`;
            const labelText = document.createElement('span');
            labelText.innerText = `${this.columns[column]}`;
            colLabelSpan.appendChild(labelText);
          }
          const labelSpan = document.createElement('span');
          labelSpan.className = 'label';
          labelSpan.style.color = 'rgba(0, 0, 0, 1)'; //getColor(this.data[row][column], -1, 1, 0);
          labelSpan.innerText = this.data[row][column].toFixed(2);
          mesh.metadata = {
            row: row,
            col: column,
            value: this.data[row][column],
            overlay: {
              vertical: new CanvasLine([coords.x, coords.y, coords.x, coords.y], {color: 'rgba(255, 255, 255, 1)', width: 1}),
              horizontal: new CanvasLine([coords.x, coords.y, coords.x, coords.y], {color: 'rgba(255, 255, 255, 1)', width: 1}),
              rowLabel: rowLabelSpan,
              colLabel: colLabelSpan,
              label: labelSpan
            }
          };
          mesh.metadata.tl = new TimelineMax({
            onComplete: () => {
              mesh.metadata.animating = false;
            }
          });
          this.overlayCanvas.add(mesh.metadata.overlay.vertical);
          this.overlayCanvas.add(mesh.metadata.overlay.horizontal);
          this.overlayDiv.appendChild(rowLabelSpan);
          this.overlayDiv.appendChild(colLabelSpan);
          this.overlayDiv.appendChild(labelSpan);
          rowMeshes.push(mesh);
        }
        this.meshes.push(rowMeshes);
      }

      const to3D = () => {
        this.slideUp(true);
        if (this.controls) {
          this.controls.enabled = true;
        }
        this.setViewMode(3);
        timeout(() => {
          let mesh = this.rowLabelMeshes[5];
          const rowCoords = get2DCoords(new THREE.Vector3(mesh.position.x - this.width / 2, mesh.position.y, mesh.position.z - this.height / 2), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
          this.rowTooltip.style.left = `${rowCoords.x - this.rowTooltip.offsetWidth / 2}px`;
          this.rowTooltip.style.top = `${rowCoords.y - 70}px`;
          this.rowTooltip.style.opacity = 1;
          mesh = this.colLabelMeshes[5];
          const colCoords = get2DCoords(new THREE.Vector3(mesh.position.x, mesh.position.y, mesh.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
          this.colTooltip.style.left = `${colCoords.x}px`;
          this.colTooltip.style.top = `${colCoords.y - 70}px`;
          this.colTooltip.style.opacity = 1;
          this.cameraTooltip.style.left = '50%';
          this.cameraTooltip.style.top = '50%';
          this.cameraTooltip.style.transform = 'translate(-50%, -50%)';
          this.cameraTooltip.style.textAlign = 'center';
          this.cameraTooltip.style.opacity = 1;
        }, 1000);
        timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { this.stopRender = true; Director.nextScene(); }); }, 1000);
        timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { this.stopRender = true; Director.previousScene(); }); }, 1000);
      };

      timeout(() => {
        InteractionHandler.on(
          InteractionDirection.DOWN, () => {
            clearAllTimeouts();
            if (mode === 'tour') {
              this.startTour();
              timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { this.stopRender = true; Director.nextScene(); }); }, 1000);
              timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { this.stopRender = true; Director.previousScene(); }); }, 1000);
            } else {
              to3D();
            }
          }
        );
      }, 1000);
      
      if (mode === 'tour') {
        this.changeTo2D();
        timeout(() => {
          this.startTour();
          timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { this.stopRender = true; Director.nextScene(); }); }, 1000);
          timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { this.stopRender = true; Director.previousScene(); }); }, 1000);
        }, this.adminMode ? 1000 : 5500);
      } else {
        this.changeTo3D(true);
        if (this.controls) {
          this.controls.enabled = this.adminMode;
        }
        timeout(to3D, 6000);
      }

      const render = () => {
        if (this.meshes.length) {
          let flip = 1;
          const lineLength = window.devicePixelRatio === 2 ? 140 : 200;
          this.focusedCells.map(cell => {
            if (cell.metadata.animating) {
              return cell;
            }
            cell.metadata.coords = get2DCoords(new THREE.Vector3(cell.position.x - this.width / 2.5, cell.position.y + cell.scale.y * 0.004, cell.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
            return cell;
          }).sort((a, b) => {
            if (a.metadata.animating || b.metadata.animating) {
              return 0;
            }
            if (a.metadata.coords.x === b.metadata.coords.x) {
              return 0;
            }
            return a.metadata.coords.x > b.metadata.coords.x ? 1 : -1;
          }).forEach((cell, index, array) => {
            if (!cell.metadata.animating && this.adminMode) {
              if (index === 0) {
                cell.metadata.yoffset = 100;
              }
              const coords = cell.metadata.coords;
              if (array[index - 1]) {
                const prev = array[index - 1].metadata;

                cell.metadata.yoffset = prev.yoffset + (coords.y - prev.coords.y) + 80 * flip;
                if (coords.y - cell.metadata.yoffset < 150) {
                  flip *= -1;
                  cell.metadata.yoffset = prev.yoffset + (coords.y - prev.coords.y) + 80 * flip;
                } else if (coords.y > window.innerHeight - 50 && prev.coords.y <= window.innerHeight - 50) {
                  flip = -1;
                  cell.metadata.yoffset = prev.yoffset + (coords.y - prev.coords.y) - 240;
                }
              }
              cell.metadata.overlay.vertical.coords = [coords.x, coords.y - cell.metadata.yoffset, coords.x, coords.y];
              cell.metadata.overlay.vertical.props.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)';
              cell.metadata.overlay.horizontal.coords = [coords.x - lineLength * flip, coords.y - cell.metadata.yoffset, coords.x, coords.y - cell.metadata.yoffset];
              cell.metadata.overlay.horizontal.props.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)';
              cell.metadata.overlay.label.style.top = `${coords.y - 22 - cell.metadata.yoffset}px`;
              cell.metadata.overlay.label.style.left = `${coords.x - cell.metadata.overlay.label.offsetWidth + (flip === -1 ? lineLength : 0)}px`;
              cell.metadata.overlay.label.style.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : getColor(cell.metadata.value, -1, 1, 1);
              cell.metadata.overlay.rowLabel.style.top = `${coords.y - 40 - cell.metadata.yoffset}px`;
              cell.metadata.overlay.rowLabel.style.left = `${coords.x - (flip === -1 ? 0 : lineLength)}px`;
              cell.metadata.overlay.rowLabel.style.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)';
              cell.metadata.overlay.colLabel.style.top = `${coords.y - 40 - cell.metadata.yoffset}px`;
              cell.metadata.overlay.colLabel.style.left = `${coords.x - cell.metadata.overlay.colLabel.offsetWidth + (flip === -1 ? lineLength : 0)}px`;
              cell.metadata.overlay.colLabel.style.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)';
            }
          });
        }
        this.light.position.x = this.camera.position.x + 2 * 5 / this.camera.fov;
        this.light.position.y = this.camera.position.y;
        this.light.position.z = this.camera.position.z + 5 * 5 / this.camera.fov;
        this.light.castShadow = this.showShadows;
        // rotate labels if camera is below them to avoid mirrored texts
        if (this.camera.position.y < 0) {
          this.rowLabelMeshes.concat(this.colLabelMeshes).forEach(mesh => {
            mesh.rotation.x = Math.PI /2;
            if (mesh.metadata.bottomLabel) {
              mesh.rotation.z = Math.PI /2;
            }
          });
        } else {
          this.rowLabelMeshes.concat(this.colLabelMeshes).forEach(mesh => {
            mesh.rotation.x = -Math.PI /2;
            if (mesh.metadata.bottomLabel) {
              mesh.rotation.z = -Math.PI /2;
            }
          });
        }
        this.overlayCanvas.render();

        if (
          this.lastCameraState.positionX === this.camera.position.x &&
          this.lastCameraState.positionY === this.camera.position.y &&
          this.lastCameraState.positionZ === this.camera.position.z &&
          this.lastCameraState.rotationX === this.camera.rotation.x &&
          this.lastCameraState.rotationY === this.camera.rotation.y &&
          this.lastCameraState.rotationZ === this.camera.rotation.z &&
          !this.needsRender
        ) {
          if (!this.stopRender) {
            requestAnimationFrame(render);
          }
          return;
        }
        this.lastCameraState.positionX = this.camera.position.x;
        this.lastCameraState.positionY = this.camera.position.y;
        this.lastCameraState.positionZ = this.camera.position.z;
        this.lastCameraState.rotationX = this.camera.rotation.x;
        this.lastCameraState.rotationY = this.camera.rotation.y;
        this.lastCameraState.rotationZ = this.camera.rotation.z;
        if (this.renderer) {
          this.renderer.render(this.scene, this.camera);
        }
        this.needsRender = false;
        if (!this.stopRender) {
          requestAnimationFrame(render);
        }
      };

      render();
    },

    hideTooltips() {
      this.colTooltip.style.opacity = 0;
      this.rowTooltip.style.opacity = 0;
      this.cameraTooltip.style.opacity = 0;
    },

    showCameraPosition() {
      const info = {
        cameraPosition: {
          x: this.camera.position.x,
          y: this.camera.position.y,
          z: this.camera.position.z
        },
        cameraRotation: {
          x: this.camera.rotation.x,
          y: this.camera.rotation.y,
          z: this.camera.rotation.z
        }
      };
      const stepText = document.getElementById(mode + 'step-text');
      const html = `<textarea id="${mode}camerainfo">${JSON.stringify(info, null, 2)}</textarea><button id="${mode}setcamera">set position</button>`;
      if (stepText.innerHTML != html) {
        stepText.innerHTML = html;
        const button = document.getElementById(mode + 'setcamera');
        button.addEventListener('click', () => {
          const camerainfo = JSON.parse(document.getElementById(mode + 'camerainfo').value);
          this.animateCamera(this.camera, {
            position: camerainfo.cameraPosition,
            rotation: camerainfo.cameraRotation
          });
        });
      }
      this.needsRender = true;
    },

    createPlaneWithText(text, icon) {
      const htmlcanvas = document.createElement('canvas');
      htmlcanvas.width = 750;
      htmlcanvas.height = 200;
      const canvas = new Canvas(htmlcanvas);
      const canvasText = new CanvasText(text, {
        top: icon ? 90 : -10,
        left: 0,
        size: 45,
        padding: 0,
        color: 'rgba(255, 255, 255, 1)',
      });
      canvas.add(canvasText);
      canvas.render();
      htmlcanvas.width = Math.max(100, canvasText.width);
      htmlcanvas.height = icon ? 160 : 60;
      const ratio1 = Math.max(100, canvasText.width) / 750;
      const ratio2 = htmlcanvas.height / htmlcanvas.width;
      canvas.render();
      const texture = new THREE.Texture(htmlcanvas);
      texture.magFilter = THREE.NearestFilter;
      texture.minFilter = THREE.LinearFilter;
      texture.generateMipmaps = false;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      const geometry = new THREE.PlaneGeometry(0.5 * ratio1, 0.5 * ratio1  * ratio2);
      const material = new THREE.MeshBasicMaterial({ map: texture, opacity: 1, side: THREE.DoubleSide});
      material.transparent = true;
      const mesh = new THREE.Mesh(geometry, material);
      mesh.rotation.x = -Math.PI / 2;
      mesh.receiveShadow = true;
      mesh.material.map.needsUpdate = true;
      mesh.metadata = {
        canvas: canvas,
        text: canvasText,
        width: 0.5 * ratio1
      };
      if (icon) {
        const image = new Image();
        image.onload = () => {
          // recolor SVG image
          /*const recolorCanvas = document.createElement('canvas');
          recolorCanvas.width = 100;
          recolorCanvas.height = 100;
          const ctx = recolorCanvas.getContext('2d');
          ctx.drawImage(image, 0, 0, 100, 100);
          const imageData = ctx.getImageData(0, 0, 100, 100);
          const color = icon.color.substr(icon.color.indexOf('(') + 1).split(',').map(i => parseInt(i));
          for (let i = 0; i < imageData.data.length; i += 4) {
            for (let j = 0; j < color.length; j++) {
              // this practically inverts the image, colored pixels will be black, black pixels will have the new color
              // this is needed because the Q in Quality.svg is colored and it would disappear after simply tinting the image
              if (imageData.data[i + j] < 255) {
                imageData.data[i + 3] = 0;
              } 
              imageData.data[i + j] = imageData.data[i + j] === 255 ? color[j] : 0;
            }
          }
          ctx.putImageData(imageData, 0, 0);
          const coloredImage = new Image();
          coloredImage.onload = () => {
            // once the colored image is loaded add the image to the texture canvas, render it and notify threejs that the material's texure map needs to be updated
            const canvasImage = new CanvasImage(coloredImage, {
              top: 0,
              left: -18,
              width: 100,
              height: 100
            });
            canvas.add(canvasImage);
            canvas.render();
            mesh.material.map.needsUpdate = true;
            this.needsRender = true;
          };
          coloredImage.src = recolorCanvas.toDataURL('image/png'); // png preserves alpha channel*/
          const canvasImage = new CanvasImage(image, {
            top: 0,
            left: -6,
            width: 100,
            height: 100
          });
          canvas.add(canvasImage);
          canvas.render();
          mesh.material.map.needsUpdate = true;
          this.needsRender = true;
        };
        image.src = icon.url;
      }
      return mesh;
    },

    slideUp(darken) {
      const tl = new TimelineMax();
      const wrapperEl = document.getElementById(mode + 'wrapper');
      tl.to(wrapperEl, 1, {top: 0});
      this.pageWrapper.style.top = 0;
      tl.to(this.pageWrapper, 1, {top: -this.pageWrapper.offsetHeight}, '=-1');
      if (darken) {
        const parent = document.getElementById(mode + 'visualization-poc');
        tl.to(parent, 1, {background: '#222'}, '=-1');
      }
    },

    startTour() {
      if (this.adminMode) {
        this.controls.enabled = true;
      }
      this.slideUp();
      timeout(() => {
        this.hideAllLabels();
        for (let row = 0; row < this.rows.length; row++) {
          for (let col = 0; col < this.columns.length; col++) {
            const cell = this.meshes[row][col];
            cell.metadata.tl.to(cell.metadata.overlay.label, 0.2, {color: getColor(this.data[row][col], -1, 1, 0)});
          }
        }
        const wrapperEl = document.getElementById(mode + 'wrapper');
        const tl = new TimelineMax();
        tl.to(wrapperEl, 1, {left: '-12.5%'});
        const contentOverlayEl = document.getElementById(mode + 'contentoverlay');
        tl.to(contentOverlayEl, 1, {right: 0}, '=-1');
        this.updateTourInfo();
        this.pauseButton.play();
      }, 2000);
    },

    closeOverlay() {
      this.focusedCells = [];
      for (let row = 0; row < this.rows.length; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          const cell = this.meshes[row][col];
          const color = {rgba: getColor(cell.metadata.value, -1, 1, 1, true)};
          const dark = new THREE.Color(color.rgba);
          const needToLighten = cell.material.color.r == dark.r && cell.material.color.g == dark.g == cell.material.color.b == dark.b;
          cell.metadata.tl.to(color, 0.4, {
            rgba: getColor(cell.metadata.value, -1, 1, 1),
            onUpdate: () => {
              if (needToLighten) {
                cell.material.color = new THREE.Color(color.rgba);
                this.needsRender = true;
              }
            },
            onComplete: () => {
              this.changeTo3D().then(() => {
                this.stopRender = true;
                Director.nextScene();
              });
            }
          });
          cell.metadata.tl.to(cell.material, 0.4, {opacity: 1}, '=-0.4');
          cell.metadata.tl.to(cell.metadata.overlay.vertical.props, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.2');
          cell.metadata.tl.to(cell.metadata.overlay.horizontal.props, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.1');
          cell.metadata.tl.to(cell.metadata.overlay.label, 0.1, {color: getColor(cell.metadata.value, -1, 1, 0)}, '=-0.1');
          cell.metadata.tl.to(cell.metadata.overlay.rowLabel, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.1');
          cell.metadata.tl.to(cell.metadata.overlay.colLabel, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.1');
        }
      }
      this.pauseButton.pause();
      const wrapperEl = document.getElementById(mode + 'wrapper');
      wrapperEl.className = '';
      const contentOverlayEl = document.getElementById(mode + 'contentoverlay');
      contentOverlayEl.className = '';
    },

    onClick(event) {
      if (Date.now() - this.mouseDownTime < 200 && this.viewMode === 3 && !this.sideMode) {
        const targetMesh = this.getTargetMesh(event);
        if (targetMesh && targetMesh.metadata.rowLabel) {
          this.targetRow = targetMesh.metadata.row;
          this.focusRow();
          this.backTo3dWrapper.className = '';
        } else if (targetMesh && targetMesh.metadata.colLabel) {
          this.targetCol = targetMesh.metadata.col;
          this.focusColumn();
          this.backTo3dWrapper.className = '';
        }
      }
    },

    onMouseMove(event) {
      if (!event.buttons && this.viewMode === 3) {
        const targetMesh = this.getTargetMesh(event);
        if (targetMesh && targetMesh.metadata && (targetMesh.metadata.rowLabel || targetMesh.metadata.colLabel)) {
          document.body.style.cursor = 'pointer';
        } else {
          document.body.style.cursor = 'auto';
        }
      } else {
        document.body.style.cursor = 'auto';
      }
    },

    getTargetMesh(event) {
      event.preventDefault();

      const position = event.changedTouches ? event.changedTouches[0] : event;

      this.mouse.x = (position.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = - (position.clientY / window.innerHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.mouse, this.camera);

      var intersects = this.raycaster.intersectObjects(this.scene.children, true);
      var targetMesh;
      var minDistance;
      for (var i = 0; i < intersects.length; i++) {
        const o = intersects[i];
        if (minDistance === undefined || o.distance < minDistance) {
          minDistance = o.distance;
          targetMesh = o.object;
        }
      }
      return targetMesh;
    },

    changeTo2D() {
      this.hideAllLabels();
      this.hideTooltips();
      this.setViewMode(2);
      if (this.controls) {
        this.controls.enabled = this.adminMode;
      }
      //this.showShadows =  false;
      for (var row = 0; row < this.rows.length; row++) {
        const tl = new TimelineMax();
        tl.to(this.rowLabelMeshes[row].material, 1, { opacity: 1 });
        for (var col = 0; col < this.columns.length; col++) {
          const cell = this.meshes[row][col];
          const tl = cell.metadata.tl;
          tl.clear();
          if (row === 0) {
            tl.to(this.colLabelMeshes[col * 2].material, 1, { opacity: 1 });
            tl.to(this.colLabelMeshes[col * 2 + 1].material, 1, { opacity: 0 }, '=-1');
          }
          tl.to(cell.scale, 2, { y: 0.2, ease: Sine.easeOut }, row === 0 ? '=-1' : '');
          tl.to(cell.position, 2, { y: 0, ease: Sine.easeOut }, '=-2');
          tl.to(cell.material, 1, { opacity: 1, ease: Sine.easeOut }, '=-2');
        }
      }
      var fovRad = this.camera.fov * Math.PI / 180; 
      var dist = Math.max(
        (this.rows.length * this.height + 0.3) / 2 / Math.tan(fovRad / 2),
        (this.columns.length * this.width + 0.8) / 2 / Math.tan(fovRad / 2) * window.innerHeight / window.innerWidth
      );
      this.animateCamera(this.camera, {
        position: {
          x: 0.865,
          y: dist,
          z: 0.45
        },
        rotation: {
          x: -1.5707,
          y: 0,
          z: 0
        },
        duration: 2,
        onComplete: () => this.arrangeValueLabels()
      });
    },

    hideAllLabels() {
      for (let row = 0; row < this.rows.length; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          const tl = new TimelineMax();
          const cm = this.meshes[row][col].metadata;
          tl.to(cm.overlay.rowLabel, 1, {color: 'rgba(255, 255, 255, 0)', opacity: 0});
          tl.to(cm.overlay.colLabel, 1, {color: 'rgba(255, 255, 255, 0)', opacity: 0}, '=-1');
          tl.to(cm.overlay.label, 1, {color: getColor(cm.value, -1, 1, 0), opacity: 0}, '=-1');
          tl.to(cm.overlay.horizontal.props, 1, {color: 'rgba(255, 255, 255, 0)', opacity: 0}, '=-1');
          tl.to(cm.overlay.vertical.props, 1, {color: 'rgba(255, 255, 255, 0)', opacity: 0}, '=-1');
        }
      }
    },

    arrangeValueLabels(noanim) {
      let cell = this.meshes[0][0];
      const coords1 = get2DCoords(new THREE.Vector3(cell.position.x - this.width / 2, cell.position.y, cell.position.z - this.height / 2), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
      cell = this.meshes[1][1];
      const coords2 = get2DCoords(new THREE.Vector3(cell.position.x - this.width / 2, cell.position.y, cell.position.z - this.height / 2), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
      const height = coords2.y - coords1.y;
      for (let row = 0; row < this.rows.length; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          const cell = this.meshes[row][col];
          const coords = get2DCoords(new THREE.Vector3(cell.position.x - this.width / 2, cell.position.y, cell.position.z - this.height / 2), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
          cell.metadata.overlay.label.style.top = `${coords.y + height / 2 - 7}px`;
          cell.metadata.overlay.label.style.left = `${coords.x + 30 * 1920 / this.overlayCanvasEl.width}px`;
          if (!noanim) {
            const tl = new TimelineMax();
            tl.to(cell.metadata.overlay.label, 0.4, {color: 'rgba(0, 0, 0, 1)', opacity: 1});
          }
        }
      }
    },

    focusRow() {
      GTAGHandler.addFocusEvent('row', this.rows[this.targetRow]);
      if (this.controls) {
        this.controls.enabled = this.adminMode;
      }
      this.sideMode = true;
      //this.showShadows =  false;
      for (var col = 0; col < this.columns.length; col++) {
        const tl = new TimelineMax();
        tl.to(this.colLabelMeshes[col * 2].material, 1, { opacity: 0 });
        tl.to(this.colLabelMeshes[col * 2 + 1].material, 1, { opacity: 0 }, '=-1');
        for (var row = 0; row < this.rows.length; row++) {
          if (row !== this.targetRow) {
            const tl = new TimelineMax();
            const cell = this.meshes[row][col];
            tl.to(this.rowLabelMeshes[row].material, 1, { opacity: 0, ease: Sine.easeOut });
            tl.to(cell.scale, 1, { y: 0.1, ease: Sine.easeOut }, '=-1');
            tl.to(cell.position, 1, { y: 0, ease: Sine.easeOut }, '=-1');
            tl.to(cell.material, 1, { opacity: 1, ease: Sine.easeOut }, '=-1');
          } else {
            const tl = new TimelineMax();
            tl.to(this.rowLabelMeshes[row].material, 1, { opacity: 0, ease: Sine.easeOut });
            tl.to(this.meshes[row][col].material, 1, { opacity: 1, ease: Sine.easeOut }, '=-1');
          }
        }
      }
      this.animateCamera(this.camera, {
        position: {
          x: (this.columns.length - 1) * this.width / 2,
          y: 0,
          z: this.targetRow * this.height + (window.innerWidth === 1024 ? 2.1 : 1.8)
        },
        lookAt: {
          x: (this.columns.length - 1) * this.width / 2,
          y: 0,
          z: this.targetRow * this.height
        },
        duration: 2,
        onComplete: () => {
          for (let col = 0; col < this.columns.length; col++) {
            const cell = this.meshes[this.targetRow][col];
            const coords = get2DCoords(new THREE.Vector3(cell.position.x, cell.position.y + cell.scale.y * 0.005 * cell.metadata.value / Math.abs(cell.metadata.value), cell.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
            const cm = cell.metadata;
            const colLabel =  cm.overlay.colLabel;
            const label =  cm.overlay.label;
            //colLabel.className = 'label noimage';
            colLabel.style.top = `${coords.y - (cm.value < 0 ? -20 : 55)}px`;
            colLabel.style.left = `${coords.x - colLabel.offsetWidth / 2}px`;
            colLabel.className = `label ${cm.value < 0 ? 'negative' : 'positive'}`;
            label.style.top = `${coords.y - (cm.value < 0 ? -60 : 70)}px`;
            label.style.left = `${coords.x - colLabel.offsetWidth / 2 + 50}px`;
            cm.tl.to(colLabel, 1, {color: 'rgba(255, 255, 255, 1)', opacity: 1});
            cm.tl.to(label, 1, {color: getColor(cm.value, -1, 1, 1), opacity: 1}, '=-1');
            if (col === 0) {
              const coords2 = get2DCoords(new THREE.Vector3(cell.position.x - this.width, 0, cell.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
              const rowLabel = cm.overlay.rowLabel;
              rowLabel.style.top = `${coords2.y - cm.overlay.rowLabel.offsetHeight - 5}px`;
              rowLabel.style.left = `${coords2.x - rowLabel.offsetWidth}px`;
              cm.tl.to(rowLabel, 1, {color: 'rgba(255, 255, 255, 1)', opacity: 1}, '=-1');
            }
          }
        }
      });
    },

    focusColumn() {
      GTAGHandler.addFocusEvent('column', this.columns[this.targetCol]);
      if (this.controls) {
        this.controls.enabled = this.adminMode;
      }
      this.sideMode = true;
      //this.showShadows =  false;
      for (var col = 0; col < this.columns.length; col++) {
        const tl = new TimelineMax();
        tl.to(this.colLabelMeshes[col * 2].material, 1, { opacity: 0 });
        tl.to(this.colLabelMeshes[col * 2 + 1].material, 1, { opacity: 0 }, '=-1');
        for (var row = 0; row < this.rows.length; row++) {
          if (col !== this.targetCol) {
            const tl = new TimelineMax();
            const cell = this.meshes[row][col];
            tl.to(this.rowLabelMeshes[row].material, 1, { opacity: 0, ease: Sine.easeOut });
            tl.to(cell.scale, 1, { y: 0.05, ease: Sine.easeOut }, '=-1');
            tl.to(cell.position, 1, { y: 0, ease: Sine.easeOut }, '=-1');
            tl.to(cell.material, 1, { opacity: 1, ease: Sine.easeOut }, '=-1');
          } else {
            const tl = new TimelineMax();
            tl.to(this.rowLabelMeshes[row].material, 1, { opacity: 0, ease: Sine.easeOut });
            tl.to(this.meshes[row][col].material, 1, { opacity: 1, ease: Sine.easeOut }, '=-1');
          }
        }
      }
      const minValue = Math.min(...this.meshes.map(row => row[this.targetCol].metadata.value));
      let lastCoords;
      this.animateCamera(this.camera, {
        position: {
          x: this.targetCol * this.width - (minValue < -1 ? 1.6 : 1.1),
          y: 0,
          z: this.columns.length * this.height / 2
        },
        lookAt: {
          x: this.targetCol * this.width,
          y: 0,
          z: this.columns.length * this.height / 2
        },
        duration: 2,
        onComplete: () => {
          for (let row = 0; row < this.rows.length; row++) {
            const cell = this.meshes[row][this.targetCol];
            const coords = get2DCoords(new THREE.Vector3(cell.position.x - this.width / 2, cell.position.y + cell.scale.y * 0.005 * cell.metadata.value / Math.abs(cell.metadata.value), cell.position.z - this.height / 2), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
            const cm = cell.metadata;
            const rowLabel =  cm.overlay.rowLabel;
            const label =  cm.overlay.label;
            if (row > 0 && row < 4) {
              const lastValue = this.meshes[row - 1][this.targetCol].metadata.value;
              const currentValue = this.meshes[row][this.targetCol].metadata.value;
              if (
                getColor(lastValue, -1, 1, 1) == getColor(currentValue, -1, 1, 1) &&
                Math.abs(lastValue) >= Math.abs(currentValue) + 0.1
              ) {
                coords.y = lastCoords.y + (currentValue < 0 ? -40 : 40);
              }
            }
            rowLabel.style.top = `${coords.y - (cm.value < 0 ? -20 : rowLabel.offsetHeight + 40)}px`;
            rowLabel.style.left = `${coords.x}px`;
            label.style.top = `${coords.y - (cm.value < 0 ? -rowLabel.offsetHeight-label.offsetHeight : 40)}px`;
            label.style.left = `${coords.x}px`;
            cm.tl.to(rowLabel, 1, {color: 'rgba(255, 255, 255, 1)', opacity: 1});
            cm.tl.to(label, 1, {color: getColor(cm.value, -1, 1, 1), opacity: 1}, '=-1');
            if (row === 0) {
              const coords2 = get2DCoords(new THREE.Vector3(cell.position.x - this.width, 0, cell.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
              const colLabel = cm.overlay.colLabel;
              colLabel.style.top = `${coords2.y - colLabel.offsetHeight - 5}px`;
              colLabel.style.left = `${coords2.x - colLabel.offsetWidth - 40}px`;
              colLabel.className = 'label';
              cm.tl.to(colLabel, 1, {color: 'rgba(255, 255, 255, 1)', opacity: 1}, '=-1');
            }
            lastCoords = coords;
          }
        }
      });
    },

    focusCell(cells, positionVector, rotationVector) {
      const init = !this.focusedCells.length;
      if (positionVector) {
        this.targetVector = new THREE.Vector3().copy(positionVector);
      } else {
        this.targetVector = new THREE.Vector3(cells[0].position.x, cells[0].position.y + cells[0].metadata.value * 0.2, cells[0].position.z);
        for (let i = 0; i < cells.length - 1; i++) {
          this.targetVector.x = (this.targetVector.x + cells[i + 1].position.x) / 2;
          this.targetVector.y = (this.targetVector.y + cells[i + 1].position.y) / 2;
          this.targetVector.z = (this.targetVector.z + cells[i + 1].position.z) / 2;
        }
      }
      this.animateCamera(this.camera, {
        position: positionVector, 
        rotation: rotationVector,
        duration: init ? 1 : 1.2,
        onComplete: () => {
          const lineLength = window.devicePixelRatio === 2 ? 140 : 200;
          cells.map(cell => {
            cell.metadata.animating = true;
            cell.metadata.coords = get2DCoords(new THREE.Vector3(cell.position.x - this.width / 2.5, cell.position.y + cell.scale.y * 0.004, cell.position.z), this.camera, this.overlayCanvasEl.width, this.overlayCanvasEl.height);
            return cell;
          }).sort((a, b) => {
            if (a.metadata.coords.x === b.metadata.coords.x) {
              return 0;
            }
            return a.metadata.coords.x > b.metadata.coords.x ? 1 : -1;
          }).forEach((cell, index, array) => {
            if (cells.includes(cell)) {
              if (index === 0) {
                cell.metadata.yoffset = 100;
              }
              const coords = cell.metadata.coords;
              if (array[index - 1]) {
                const prev = array[index - 1].metadata;

                cell.metadata.yoffset = prev.yoffset + (coords.y - prev.coords.y) + 80 * flip;
                if (coords.y - cell.metadata.yoffset < 150) {
                  flip *= -1;
                  cell.metadata.yoffset = prev.yoffset + (coords.y - prev.coords.y) + 80 * flip;
                } else if (coords.y > window.innerHeight - 50 && prev.coords.y <= window.innerHeight - 50) {
                  flip = -1;
                  cell.metadata.yoffset = prev.yoffset + (coords.y - prev.coords.y) - 240;
                }
              }
              cell.metadata.overlay.vertical.coords = [coords.x, coords.y - cell.metadata.yoffset, coords.x, coords.y - cell.metadata.yoffset];
              cell.metadata.overlay.vertical.props.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)';
              cell.metadata.overlay.horizontal.coords = [coords.x - lineLength * flip, coords.y - cell.metadata.yoffset, coords.x - lineLength * flip, coords.y - cell.metadata.yoffset];
              cell.metadata.overlay.horizontal.props.color = coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)';
              cell.metadata.overlay.label.style.top = `${coords.y - 22 - cell.metadata.yoffset}px`;
              cell.metadata.overlay.label.style.left = `${coords.x - cell.metadata.overlay.label.offsetWidth + (flip === -1 ? lineLength : 0)}px`;
              cell.metadata.overlay.rowLabel.style.top = `${coords.y - 40 - cell.metadata.yoffset}px`;
              cell.metadata.overlay.rowLabel.style.left = `${coords.x - (flip === -1 ? 0 : lineLength)}px`;
              cell.metadata.overlay.colLabel.style.top = `${coords.y - 40 - cell.metadata.yoffset}px`;
              cell.metadata.overlay.colLabel.style.left = `${coords.x - cell.metadata.overlay.colLabel.offsetWidth + (flip === -1 ? lineLength : 0)}px`;
              cell.metadata.tl.to(cell.metadata.overlay.label, 0.1, {color: coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : getColor(cell.metadata.value, -1, 1, 1), opacity: 1});
              cell.metadata.tl.to(cell.metadata.overlay.rowLabel, 0.1, {color: coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)', opacity: 1}, '=-0.1');
              cell.metadata.tl.to(cell.metadata.overlay.colLabel, 0.1, {color: coords.y > window.innerHeight - 50 ? 'rgba(64, 64, 64, 1)' : 'rgba(255, 255, 255, 1)', opacity: 1}, '=-0.1');
              curvedTextAnimation(cell.metadata.overlay.colLabel, {delay: -900, top: '60px', left: '-20px', rotate: false});
              curvedTextAnimation(cell.metadata.overlay.rowLabel, {delay: -700, top: '60px', left: '-20px', rotate: false});
              curvedTextAnimation(cell.metadata.overlay.label, {delay: -500, top: '60px', left: '-20px', rotate: false});
              cell.metadata.tl.to(cell.metadata.overlay.horizontal.coords, 0.4, {2: coords.x}, '=+0.5');
              cell.metadata.tl.to(cell.metadata.overlay.vertical.coords, 0.4, {3: coords.y});
            }
          });
        }
      });
      let flip = 1;
      cells.forEach((cell, index, array) => {
        cell.castShadow = true;
        cell.metadata.animating = true;
        cell.metadata.tl.clear();
        if (this.focusedCells.length) {
          const tl = new TimelineMax();
          this.focusedCells.forEach((focusedCell, idx) => {
            tl.to(focusedCell.metadata.overlay.vertical.props, 0.1, {color: 'rgba(255, 255, 255, 0)'});
            tl.to(focusedCell.metadata.overlay.horizontal.props, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.1');
            tl.to(focusedCell.metadata.overlay.label, 0.1, {color: getColor(focusedCell.metadata.value, -1, 1, 0)}, '=-0.1');
            tl.to(focusedCell.metadata.overlay.rowLabel, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.1');
            tl.to(focusedCell.metadata.overlay.colLabel, 0.1, {color: 'rgba(255, 255, 255, 0)'}, '=-0.1');
          });
        }
        if (!init) {
          const color = {rgba: getColor(cell.metadata.value, -1, 1, 1, true)};
          cell.metadata.tl.to(color, 0.2, {rgba: getColor(cell.metadata.value, -1, 1, 1), onUpdate: () => {
            cell.material.color = new THREE.Color(color.rgba);
          }});
        }
        cell.metadata.tl.to(cell.material, 0.2, {opacity: 1}, !init ? '=-0.2' : '');
        cell.metadata.tl.to(cell.scale, 1, {
          y: Math.max(0.001, 40 * Math.abs(cell.metadata.value)),
          onUpdate: (tween) => {
            cell.position.y = 0.01 * cell.scale.y / 2 * (cell.metadata.value / Math.abs(cell.metadata.value));
          },
          onUpdateParams: ['{self}']
        }, '=-0.2');
        //tl.to(cell.position, 1, {y: cell.metadata.value * 0.2, ease: Sine.easeOut }, '=-1');
        cell.metadata.needsDarken = true;
      });
      this.focusedCells = cells;
      //fitCameraToObject(this.camera, cell, 0);
      for (let row = 0; row < this.rows.length; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          const mesh = this.meshes[row][col];
          if (!this.focusedCells.includes(mesh)) {
            const tl = new TimelineMax({
              onComplete: () => {
                //mesh.castShadow = false;
              }
            });
            if (init || mesh.metadata.needsDarken) {
              const color = {rgba: getColor(mesh.metadata.value, -1, 1, 1)};
              tl.to(color, 0.5, {rgba: getColor(mesh.metadata.value, -1, 1, 1, true), onUpdate: () => {
                mesh.material.color = new THREE.Color(color.rgba);
              }});
            }
            tl.to(mesh.material, 0.5, {opacity: 0.7}, init || mesh.metadata.needsDarken ? '=-0.5' : '');
            tl.to(mesh.scale, 1, {y: 0.1}, '=-0.5');
            tl.to(mesh.position, 1, {y: 0}, '=-1');
            mesh.metadata.needsDarken = false;
          }
        }
      }
    },

    changeToPointLight() {
      this.scene.remove(this.light);
      this.light = new THREE.PointLight(0xFFFFFF, 1, 1000);
      this.light.shadow.mapSize.width = 2048;
      this.light.shadow.mapSize.height = 2048;
      this.light.shadow.camera.near = 0.1;
      this.light.shadow.camera.far = 500;
      this.scene.add(this.light);
    },

    changeToDirectionalLight() {
      this.scene.remove(this.light);
      this.light = new THREE.DirectionalLight(0xFFFFFF);
      this.light.shadow.mapSize.width = 2048;
      this.light.shadow.mapSize.height = 2048;
      this.light.shadow.camera.near = 0.1;
      this.light.shadow.camera.far = 500;
      this.scene.add(this.light);
    },

    setViewMode(viewMode) {
      this.sideMode = false;
      this.viewMode = viewMode;
      switch(viewMode) {
        case 2:
          this.changeTo3dButton.className = this.changeTo3dButton.className.replace(/selected/g, '');
          this.changeTo2dButton.className += ' selected';
          break;
        case 3:
          this.changeTo2dButton.className = this.changeTo2dButton.className.replace(/selected/g, '');
          this.changeTo3dButton.className += ' selected';
          break;
      }
    },

    changeTo3D(noanim) {
      return new Promise((resolve, reject) => {
        this.hideAllLabels();
        if (!noanim) {
          this.setViewMode(3);
        }
        this.showShadows = true;
        if (this.controls) {
          this.controls.enabled = !noanim;
        }
        this.sideMode = false;
        for (var col = 0; col < this.columns.length; col++) {
          for (var row = 0; row < this.rows.length; row++) {
            const cell = this.meshes[row][col];
            const tl = cell.metadata.tl;
            tl.clear();
            tl.to(cell.metadata.overlay.label, noanim ? 0 : 0.5, {color: getColor(this.data[row][col], -1, 1, 0)});
            if (row === 0) {
              tl.to(this.colLabelMeshes[col * 2 + 1].material, noanim ? 0 : 1, { opacity: 1 }, '=-0.5');
            }
            tl.to(this.meshes[row][col].scale, noanim ? 0 : 3, { y: Math.max(0.1, 40 * Math.abs(this.data[row][col])), ease: Sine.easeOut });
            tl.to(this.meshes[row][col].material, noanim ? 0 : 1, { opacity: 1, ease: Sine.easeOut }, '=-3');
            tl.to(this.meshes[row][col].position, noanim ? 0 : 3, { y: this.data[row][col] * 0.2, ease: Sine.easeOut }, '=-3');
            if (col === 0) {
              tl.to(this.rowLabelMeshes[row].material, noanim ? 0 : 1, { opacity: 1, ease: Sine.easeOut }, '=-3');
              tl.to(this.rowLabelMeshes[row].rotation, noanim ? 0 : 1, { x: - Math.PI / 2., ease: Sine.easeOut }, '=-3');
            }
          }
        }
        this.animateCamera(this.camera, {
          position: {
            x: -0.7003,
            y: 0.8824,
            z: 1.7740
          },
          rotation: {
            x: -0.7097,
            y: -0.7145,
            z: -0.5126
          },
          duration: noanim ? 0 : 3,
          delay: '=+0.2',
          onComplete: () => resolve()
        });
      });
    },

    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.updateTourInfo();
        this.pauseButton.resetTimer();
        this.pauseButton.play();
        if (this.currentStep === 1) {
          this.prevStepButton.disable();
        }
        this.nextStepButton.enable();
      }
    },

    nextStep() {
      if (this.currentStep < this.tour.length) {
        this.currentStep++;
        this.updateTourInfo();
        this.pauseButton.resetTimer();
        this.pauseButton.play();
        if (this.currentStep === 4) {
          this.nextStepButton.disable();
        }
        this.prevStepButton.enable();
      } else {
        this.currentStep = 1;
        this.closeOverlay();
      }
    },

    updateTourInfo() {
      const step = this.tour[this.currentStep - 1];
      const stepContent = document.getElementById(mode + 'step-content');
      const tl = new TimelineMax();
      tl.to(stepContent, 0.4, {
        opacity: 0,
        onComplete: () => {
          try {
            document.getElementById(mode + 'step-icon').src = step.icon;
            document.getElementById(mode + 'step-text').innerHTML = step.description;
            document.getElementById(mode + 'current-step').innerText = this.currentStep;
            document.getElementById(mode + 'total-steps').innerText = this.tour.length;
            tl.to(stepContent, 0.4, {opacity: 1});
          } catch(e) {
            // tween was removed from dom meanwhile
            tl.clear();
            tl.kill();
          }
        }
      });
      const cells = [];
      step.focusedCells.forEach(cell => {
        cells.push(this.meshes[cell.row][cell.col]);
      });
      this.focusCell(cells, step.cameraPosition, step.cameraRotation);
    },

    animateCamera(camera, options) {
      if (!options || !options.position) {
        return;
      }
      // save the camera's current position and rotation 
      const startRotation = new THREE.Euler().copy(camera.rotation);
      const startPosition = new THREE.Vector3().copy(camera.position);
      const controlsEnabled = this.controls ? this.controls.enabled : false;
      if (options.lookAt) {
        // we can convert our lookAt vector to an easy-to-animate rotation vector by setting the camera to the target position and call camera.lookAt()
        // since we will set the camera back to its startPosition and startRotation before the next render call there will be no flickering
        camera.position.copy(options.position);
        camera.lookAt(options.lookAt.x, options.lookAt.y, options.lookAt.z);
      }
      const endRotation = options.rotation || new THREE.Euler().copy(camera.rotation);
      if (options.lookAt) {
        // reset camera if necessary
        camera.rotation.copy(startRotation);
        camera.position.copy(startPosition);
      }
      if (camera.tl) {
        // clear any ongoing animations
        camera.tl.clear();
        camera.tl.kill();
      }
      camera.tl = new TimelineMax({
        // since we use the camera's projection matrix to calculate projected 2d coordinates for labels, we must update it during the animation
        onUpdate: () => camera.updateProjectionMatrix(),
        onComplete: () => {
          // we must update the target of the camera controls when the animation completes
          // The target vector of the controls should be the same as the camera's lookAt vector
          // If options.lookAt is not null then we use it, but we must calculate it otherwise since there's no getLookAt() function on the camera object
          // In the camera's local space it's looking down the Z axis. If we apply the camera's rotation to that vector (0, 0, -1)
          // then we get the direction of the camera in our global space. However this vector is pointing from the global origin (0, 0, 0),
          // not from the camera's position, so we must add the position vector as well to get the true lookAt vector.
          const lookAtVector = new THREE.Vector3(0, 0, -2);
          lookAtVector.applyQuaternion(camera.quaternion).add(camera.position);
          if (this.controls) {
            this.controls.enabled = controlsEnabled;
            this.controls.target.copy(options.lookAt || lookAtVector);
            this.controls.update();
          }
          camera.updateProjectionMatrix();
          if (options.onComplete) {
            options.onComplete();
          }
        }
      });
      
      if (this.controls) {
        this.controls.enabled = this.adminMode;
      }
      camera.tl.to(camera.position, options.duration, {
        x: options.position.x,
        y: options.position.y,
        z: options.position.z,
        ease: Sine.easeInOut
      }, options.delay);
      camera.tl.to(camera.rotation, options.duration, {
        x: endRotation.x,
        y: endRotation.y,
        z: endRotation.z,
        ease: Sine.easeInOut
      }, `=-${options.duration}`);
    },

    initElements() {
      this.pageWrapper = document.getElementById(mode + 'facs-sectors-page-wrapper-id');
      this.barList = document.getElementsByClassName('base-bar');
      this.exposureLabelEl = document.getElementById(mode + 'exposure-bar-id');
      this.progBarNegative = document.getElementById(mode + 'prog-bar-negative');
      this.progBarPositive = document.getElementById(mode + 'prog-bar-positive');
      this.facsTextEl = document.getElementById(mode + 'facs-sectors-content-id');
    },
  
    loadBar() {
      for (let i = 0; i < 8; i++) {
        timeout(() => {
          var tl = new TimelineMax();
          tl.to(this.barList[i], 0.2, {opacity: 1, width: 100 + '%'});
        }, 200 * i);
      }
    },

    animation() {
      typewriterAnimation(this.facsTextEl, {delay: 1200});
      timeout(() => this.loadBar(), 3500);
      lineByLineReveal(this.progBarNegative, {delay: 3500});
      lineByLineReveal(this.exposureLabelEl, {delay: 3900});
      lineByLineReveal(this.progBarPositive, {delay: 4500});
    }
  };
}

export function scene3d(mode) {
  return new Scene3D(mode);
}

export function scene3dTour(mode) {
  return new Scene3D(mode);
}

export function scene3dAdmin(mode) {
  const scene = new Scene3D(mode);
  scene.adminMode = true;
  return scene;
}
