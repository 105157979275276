import './spinner-effect.scene.scss';
import { TimelineMax, Power0 } from 'gsap';
import { spinnerEffectAnimation } from '../../animations/spinner-effect/spinner-effect.animation';

export default function SpinnerEffectScene() {
  return {
    template: `
    <div id="progress" class="vertical-center" data-progress="0">
        <i class='material-icons' id='pause-button'>pause</i>
    </div>  
    `,
    background: '#222222',
    transition: {
      enter: 'fade',
      leave: 'fade'
    },
  
    init() {
      var pause = document.getElementById('pause-button');
      spinnerEffectAnimation(pause);
    }
  };
};