import { DataSource } from "./datasource";
import { buttonFillAnimation } from "../animations/button-fill/button-fill.animation";

export function requestFullscreen(rootElementId) {
  return new Promise((resolve, reject) => {
    DataSource.fetch('text').then(text => {
      const root = document.getElementById(rootElementId);
      root.className = 'full-screen-mode-off';
      var msg = text.fullscreen;
      const id = `fullscreen-ok-${Date.now()}`;
      var template = 
      `<div class="content">
          ${msg}<br/>
          <button id="${id}">OK</button>
      </div>`;
  
      var blockDiv = document.createElement('div');
      blockDiv.id = "request-fullscreen";
      blockDiv.className = "text-center";
      blockDiv.innerHTML = template;
      root.parentElement.appendChild(blockDiv);
      const button = document.getElementById(id);
      buttonFillAnimation(button);
      button.addEventListener('click', () => {
        root.parentElement.removeChild(blockDiv);
        let promise = null;
        if (root.requestFullscreen) {
          promise = root.requestFullscreen();
        } else if (root.mozRequestFullScreen) { /* Firefox */
          promise = root.mozRequestFullScreen();
        } else if (root.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          promise = root.webkitRequestFullscreen();
        } else if (root.msRequestFullscreen) { /* IE/Edge */
          promise = root.msRequestFullscreen();
        }
        if (promise && promise.then) {
          promise.then(() => {
            root.className = 'full-screen-mode-on';
            resolve();
          });
        } else {
          setTimeout(() => resolve());
        }
      });
    });
  });
}