export function accordion(content, prefix) {
  const onPanelCollapsedCallbacks = [];
  const onPanelExpandedCallbacks = [];
  const panels = content.querySelectorAll('[panel]');
  console.dir(panels);
  const wrappers = [];
  const headers = [];
  content.style.display = 'block';
  panels.forEach((panel, idx) => {
    const header = document.createElement('div');
    header.id = (prefix ? prefix.toLowerCase().replace(/ /g, '-') + '-' : '') + panel.getAttribute('panel').toLowerCase().replace(/ /g, '-') + '-panel';
    header.className = `accordion-panel-header  ${panel.hasAttribute('static') ? 'static' : ''}`;
    header.innerHTML = `
      <span class="accordion-panel-header-title">${panel.getAttribute('panel')}</span>
      <div style="flex: 1 auto"></div>
      <span class="accordion-panel-header-toggle">▴</span>
    `;
    try {
      content.removeChild(panel);
      panel.id = (prefix ? prefix.toLowerCase().replace(/ /g, '-') + '-' : '') + panel.getAttribute('panel').toLowerCase().replace(/ /g, '-') + '-panel-content';
      headers.push(header);
    } catch (e) {
      console.error(e);
    }
  });

  const api = {
    expand: (index, noanim) => {
      onPanelExpandedCallbacks.forEach((callbackFn) => callbackFn(index));
      headers[index].children[2].innerHTML = '▴';
      wrappers[index].classList.remove('collapsed');
      wrappers[index].tl = wrappers[index].tl || new TimelineMax();
      wrappers[index].tl.kill();
      if (noanim) {
        wrappers[index].style.height = '';
      } else {
        wrappers[index].tl.to(wrappers[index], 0.4, {
          height: panels[index].offsetHeight,
          onComplete: () => {
            wrappers[index].style.height = '';
          }
        });
      }
    },

    collapse: (index, noanim) => {
      onPanelCollapsedCallbacks.forEach((callbackFn) => callbackFn(index));
      headers[index].children[2].innerHTML = '▾';
      wrappers[index].classList.add('collapsed');
      wrappers[index].style.height = wrappers[index].offsetHeight + 'px';
      wrappers[index].style.overflow = 'hidden';
      wrappers[index].tl = wrappers[index].tl || new TimelineMax();
      wrappers[index].tl.kill();
      if (noanim) {
        wrappers[index].style.height = 0;
      } else {
        wrappers[index].tl.to(wrappers[index], 0.4, { height: 0 });
      }
    },

    toggle: (index) => {
      if (wrappers[index].className.indexOf('collapsed') > -1) {
        api.expand(index);
      } else {
        api.collapse(index);
      }
    },

    onPanelExpanded: (callbackFn) => {
      onPanelExpandedCallbacks.push(callbackFn);
    },

    onPanelCollapsed: (callbackFn) => {
      onPanelCollapsedCallbacks.push(callbackFn);
    }
  };

  panels.forEach((panel, idx) => {
    const wrapper = document.createElement('div');
    wrapper.className = 'accordion-panel-content';
    wrapper.appendChild(panel);
    content.appendChild(headers[idx]);
    content.appendChild(wrapper);
    wrappers.push(wrapper);
    if (!panel.hasAttribute('static')) {
      headers[idx].addEventListener('click', (e) => {
        api.toggle(idx);
      });
    }
    if (panel.hasAttribute('collapsed')) {
      api.collapse(idx, true);
    }
  });

  return api;
}