import { blackOrWhiteBackground } from './util';

export function autocomplete(input, datasource, root, bottomContainer, callback) {
  //items = items.map(data => data.assetName).sort();
  let elemList;
  let selectedIndex;
  let onItemSelectedCallbacks = [];
  let onShowCtaCallbacks = [];
  let itemFilter = (item) => { return true; };
  let showCta = false;

  const keyHandler = (event) => {
    event.stopPropagation();
    if (selectedIndex !== undefined) {
      elemList.children[selectedIndex].className = `select-element ${blackOrWhiteBackground()}`;
    }
    if (event.keyCode === 40) {
      if (selectedIndex === undefined) {
        selectedIndex = 0;
      } else {
        selectedIndex++;
        if (selectedIndex === elemList.children.length) {
          selectedIndex = 0;
          elemList.children[selectedIndex].scrollIntoView();
        }
      }
    } else if (event.keyCode === 38) {
      if (selectedIndex === undefined) {
        selectedIndex = 0;
      } else {
        selectedIndex--;
        if (selectedIndex === -1) {
          selectedIndex = elemList.children.length - 1;
          elemList.children[selectedIndex].scrollIntoView();
        }
      }
    } else if (selectedIndex !== undefined && event.keyCode === 13) {
      elemList.children[selectedIndex].click();
    }
    if (selectedIndex !== undefined) {
      const elem = elemList.children[selectedIndex];
      const elemBounds = elem.getBoundingClientRect();
      const listBounds = elemList.getBoundingClientRect();
      if (elemBounds.top + elemBounds.height > listBounds.top + listBounds.height) {
        elemList.scrollBy({
          top: elemBounds.height,
          left: 0,
          behavior: 'smooth'
        });
      } else if (elemBounds.top < listBounds.top) {
        elemList.scrollBy({
          top: -elemBounds.height,
          left: 0,
          behavior: 'smooth'
        });
      }
      //console.log(bounds);
      elem.className = `select-element ${blackOrWhiteBackground()} selected`;
    }
  };

  input.addEventListener('click', () => {
    input.value = '';
    let event = document.createEvent('Event');
    event.initEvent('input', false, true);
    input.dispatchEvent(event);
  });

  input.addEventListener('keyup', keyHandler);

  input.addEventListener('input', function (e) {
    var listItem, i, val = this.value;
    datasource.getSuggestion(val).then((items) => {
      showCta = false;
      closeAllLists();
      elemList = document.createElement('DIV');
      elemList.id = `${this.id}autocomplete-list`;
      elemList.className = 'autocomplete-items';
      this.parentNode.appendChild(elemList);
      for (let i = 0; i < items.length; i++) {
        listItem = document.createElement('DIV');
        listItem.className = `select-element ${blackOrWhiteBackground()}`;
        listItem.style.background = 'rgba(0, 0, 0, 0)'; //blackOrWhiteBackground();
        listItem.innerHTML = '<strong>' + items[i].assetName.substr(0, val.length) + '</strong>';
        listItem.innerHTML += items[i].assetName.substr(val.length);
        listItem.addEventListener('wheel', function (event) {
          event.stopPropagation();
        });
        listItem.addEventListener('click', function () {
          if (callback && itemFilter(items[i])) {
            callback(items[i]);
          }
          if (!itemFilter(items[i])) {
            onShowCtaCallbacks.forEach(callbackFn => callbackFn(true));
          }
        });
        elemList.appendChild(listItem);
      }
      if (items.length === 0) {
        listItem = document.createElement('DIV');
        listItem.className = `select-element-no-result ${blackOrWhiteBackground()}`;
        listItem.style.background = 'rgba(0, 0, 0, 0)'; //blackOrWhiteBackground();
        listItem.innerHTML = '<strong>No result</strong>';
        listItem.addEventListener('wheel', function (event) {
          event.stopPropagation();
        });
        elemList.appendChild(listItem);
      }
      api.recalculateHeight();
    });
  });

  function closeAllLists(elmnt) {
    var x = document.getElementsByClassName('autocomplete-items');
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != input) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }

  const api = {
    getElement: () => {
      return elemList;
    },

    recalculateHeight: () => {
      if (!elemList) {
        console.log('no elemlist');
        return;
      }

      if (!bottomContainer) {
        return;
      }

      const rect = elemList.getBoundingClientRect();
      console.log(rect);
      let offset = {
        top: 0
      };
      if (root) {
        offset = root.getBoundingClientRect();
      }
      console.log('offset', offset);
      elemList.style.height = 1920 * window.innerHeight / window.innerWidth - (rect.top - offset.top) / window.innerHeight * 1920 * window.innerHeight / window.innerWidth - bottomContainer.clientHeight - 25 + 'px';
    },

    onItemSelected: (callbackFn) => {
      onItemSelectedCallbacks.push(callbackFn);
    },

    onShowCta: (callbackFn) => {
      onShowCtaCallbacks.push(callbackFn);
    },

    setItemFilter: (filter) => {
      itemFilter = filter;
    }
  };

  return api;
}