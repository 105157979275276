import './circle-button.animation.scss';
import { timeout } from '../../scripts/util';

export function circleButtonAnimation(element, opts) {
  opts = opts || {};
  element.classList.add('circle-button');

  if (opts.color) {
    element.classList.add(opts.color);
  }

  disableIfNeeded(element, opts.isDisabled);

  element.innerHTML = '<span class="circle-button-wrapper">' + element.innerHTML + '</span>';
  var previousOnclick = element.onclick;
  element.onclick = () => {
    previousOnclick && previousOnclick();
    element.classList.add('clicked');
    disableIfNeeded(element, opts.isDisabled);
    timeout(() => {
      element.classList.remove('clicked');
    }, 250);
    typeof opts.afterClick === "function" && opts.afterClick();
  };

  return {
    disableIfNeeded: () => { disableIfNeeded(element, opts.isDisabled); },
    disable: () => element.disabled = true,
    enable: () => element.disabled = false,
    hide: () => element.style.display = 'none',
    show: () => element.style.display = ''
  };
}

function disableIfNeeded(element, isDisabledFn) {
  if (typeof isDisabledFn !== "function") {
    return;
  }

  if (isDisabledFn()) {
    element.disabled = true;
  } else {
    element.disabled = false;
  }
}