import './typewriter.scene.scss';
import { typewriterAnimation } from '../../animations/typewriter/typewriter.animation';

export default function TypeWriterScene() {
  return {
    template: `
    <div class='vertical-center'>
        <div id="typewritertext1" class="typewriter-text full-width">Do Tech Stocks</div>
        <div id="typewritertext2" class="typewriter-text full-width">Always Equal Growth?</div>
      </div>`,
    background: 'white',

    init() {
      var animtext1 = document.getElementById("typewritertext1");
      var animtext2 = document.getElementById("typewritertext2");
      typewriterAnimation(animtext1);
      typewriterAnimation(animtext2, { delay: 300 });
    }
  };
};