import example2Scene from "../example-2-scene/example-2.scene";
import { Director } from "../../scripts/director";
import scene3d from "../3d-scene-1/3d-scene-1";
import fallingBallsScene from "../fallingballs/falling-balls.scene";
import curvedTextScene from "../curved-text/curved-text.scene";
import bubbleMenuScene from "../bubble-menu/bubble-menu.scene";
import liftUpScene from "../lift-up/lift-up.scene";
import buttonFillScene from "../button-fill/button-fill.scene";
import typewriterScene from "../typewriter/typewriter.scene";
import lineByLineRevealScene from "../line-by-line/line-by-line-reveal.scene";
import floatingBallsScene from "../floating-balls/floating-balls.scene";
import switchExamplesScene from "../switch-examples/switch-examples.scene";
import arrowControlsScene from "../arrow-controls/arrow-controls.scene";
import { overlayAnimation } from "../../animations/overlay/overlay.animation";
import ekgDiagramScene from "../ekg-diagram/ekg-diagram.scene";
import landingScene from "../landing/landing.scene";
import faangChartScene from "../faang-chart/faang-chart.scene";
import subPageScene from "../sub-page/sub-page.scene";

export default function Example1Scene() {
  return {
    template: `
      <div class="vertical-center text-center">
        <button click="next">page scroll</button>
        <button click="falling">falling</button>
        <button click="curved">curved-text</button>
        <button click="bubble">bubble-menu</button>
        <button click="liftup">lift-up</button>
        <button click="buttonfill">buttonfill</button>
        <button click="typewriter">typewriter</button>
        <button click="lbl">linebyline</button>
        <button click="floatingball">floatingball</button>
        <button click="switches">switches</button>
        <button click="arrows">arrows</button>
        <button click="toggleOverlay">overlay</button>
        <button click="ekgDiagram">ekg-diagram</button>
        <button click="to3d">3d</button>
        <button click="faang">FAANG chart</button>
        <button click="subPage">show sub-page </button>
        <button click="start">start journey</button>
        <div id="overlay">this is overlay</div>
      </div>`,
    background: 'white',
    transition: {
      enter: 'fade',
      leave: 'slide'
    },
    overlayObj: null,

    init() {
      var overlayDiv = document.getElementById("overlay");
      this.overlayObj = overlayAnimation(overlayDiv, { color: 'black' });
    },

    next () {
      Director.toScene(example2Scene);
    },

    to3d () {
      Director.toScene(scene3d);
    },

    falling () {
      Director.toScene(fallingBallsScene);
    },

    curved() {
      Director.toScene(curvedTextScene);
    },

    bubble() {
      Director.toScene(bubbleMenuScene);
    },

    liftup() {
      Director.toScene(liftUpScene);
    },

    buttonfill() {
      Director.toScene(buttonFillScene);
    },

    typewriter() {
      Director.toScene(typewriterScene);
    },

    lbl() {
      Director.toScene(lineByLineRevealScene);
    },

    floatingball() {
      Director.toScene(floatingBallsScene);
    },

    switches() {
      Director.toScene(switchExamplesScene);
    },

    arrows() {
      Director.toScene(arrowControlsScene);
    },

    toggleOverlay() {
      this.overlayObj.toggle();
    },

    ekgDiagram() {
      Director.toScene(ekgDiagramScene);
    },

    start() {
      Director.toScene(landingScene);
    },

    faang() {
      Director.toScene(faangChartScene);
    },

    subPage() {
      Director.toScene(subPageScene);
    }
  };
};