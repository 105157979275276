import { stockOrFund, timeout } from './util';

const delay = 200;

export const FundFactorDataSource = {
  __fetchInProgress: null,
  __data: null,
  __cached: [],

  init(url) {
    this.url = url;
  },

  getSuggestion(val) {
    return new Promise((resolve, reject) => {
      const cached = this.__cached.indexOf(val) > -1;
      if (!cached) {
        this.__cached.push(val);
      }
      if (this.__data === null) {
        var fetchPromise = this.__fetchInProgress || fetch(this.url);
        this.__fetchInProgress = this.__fetchInProgress || fetchPromise;
        fetchPromise.then(response => response.clone().json()).then(json => {
          this.__data = json.funds.data;
        timeout(() => resolve(this.__data.filter(item => item.assetName.substr(0, val.length).toUpperCase() === val.toUpperCase()).sort((a, b) => a.assetName < b.assetName ? -1 : 1)), delay);
        });
      } else {
        timeout(() => resolve(this.__data.filter(item => item.assetName.substr(0, val.length).toUpperCase() === val.toUpperCase()).sort((a, b) => a.assetName < b.assetName ? -1 : 1)), delay);
      }
    });
  },

  fetchByAsset(assetId) {
    return new Promise((resolve, reject) => {
      if (this.__data === null) {
        var fetchPromise = this.__fetchInProgress || fetch(this.url);
        this.__fetchInProgress = this.__fetchInProgress || fetchPromise;
        fetchPromise.then(response => response.clone().json()).then(json => {
          this.__data = json.funds.data;
          timeout(() => resolve(this.__data.filter(item => item.assetId.toUpperCase() === assetId.toUpperCase())[0]), delay);
        });
      } else {
        timeout(() => resolve(this.__data.filter(item => item.assetId.toUpperCase() === assetId.toUpperCase())[0]), delay);
      }
    });
  }
};
