import { eventBus, VisEvent } from "./eventbus";
import { Navigation } from "./navigation";
import { GTAGService } from "./gtagservice";
import { DataSource } from "./datasource";

export const GTAGHandler = {
  init() {
    return new Promise(resolve => {
      DataSource.fetch('gtagTrackingId').then(id => {
        this.id = id;
        if (!id) {
          resolve();
          return;
        }
        this.gtagService = new GTAGService(id);
  
        eventBus.$on(VisEvent.SCENE_CHANGE, ({newScene, oldScene, timeOnPreviousScreenInMilliseconds}) => {
          this.addScreenView(Navigation.getSceneTitle(newScene), Navigation.getSceneTitle(oldScene), timeOnPreviousScreenInMilliseconds);
        });

        resolve();
      });
    });
  },

  addScreenView(screenName, previousScreenName, timeOnPreviousScreenInMilliseconds) {
    if (!this.id) return;
    this.gtagService.addScreenView(screenName, previousScreenName, timeOnPreviousScreenInMilliseconds);
  },

  addFocusEvent(target, category) {
    if (!this.id) return;
    this.gtagService.addFocusEvent(target, category);
  },

  addClickEvent(target) {
    if (!this.id) return;
    this.gtagService.addClickEvent(target);
  }
};