import './switch-examples.scene.scss';

export default function SwitchExamplesScene() {
  return {
    template: `
      <div class='vertical-center text-center switch-example'>
        <label class="switch" for="checkbox1">
          <input type="checkbox" id="checkbox1" />
          <div class="slider round yellow"></div>
        </label>
        <label class="switch" for="checkbox2">
          <input type="checkbox" id="checkbox2" />
          <div class="slider round blue"></div>
        </label>
        <label class="switch" for="checkbox3">
          <input type="checkbox" id="checkbox3" />
          <div class="slider round orange"></div>
        </label>
        <label class="switch" for="checkbox4">
          <input type="checkbox" id="checkbox4" />
          <div class="slider round red"></div>
        </label>
        <label class="switch" for="checkbox5">
          <input type="checkbox" id="checkbox5" />
          <div class="slider round green"></div>
        </label>
      </div>`,
    background: '#222222',
    transition: {
      enter: 'fade',
      leave: 'fade'
    }
  };
};