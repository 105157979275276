import './floating-balls.scene.scss';
import { floatingBallsAnimation } from '../../animations/floating-balls/floating-balls.animation';
import { DataSource } from '../../scripts/datasource';
import { typewriterAnimation } from '../../animations/typewriter/typewriter.animation';
import { liftUpAnimation } from '../../animations/lift-up/lift-up.animation';
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';
import { InteractionHandler, InteractionDirection } from '../../scripts/interaction-handler';
import { Director } from '../../scripts/director';
import { GTAGHandler } from '../../scripts/gtaghandler';
import { TimelineMax } from 'gsap';
import { clearAllTimeouts, timeout } from '../../scripts/util';

export default function FloatingBallsScene() {
  return {
    template: `
    <div class="page-wrapper">
      <div id='floating-ball-bg'></div>
          <div class="floating-balls-header">
            <div class="logo-wrapper" id="floating-balls-logo-id">
              <a target="_blank" href="http://www.itware.hu"><img class="header-visualization-poc-logo" src="assets/images/itware.png"></a>
            </div>
            <div style="flex: 1 auto"></div>
            <div id="join-wrapper">
              <span>{{ text.join.text }}</span><br/>
              <a target="_blank" href="{{ text.join.twitter }}"><img src="assets/images/facebook.png" class="twitter"/><a/>
              <a target="_blank" href="{{ text.join.linkedin }}"><img src="assets/images/linkedin.png" class="linkedin"/><a/>
            </div>
          </div>
          <div class="floating-balls-content-wrapper row col-lg-8 col-md-8 col-sm-9">
            <div class="floating-balls-content" id="floating-balls-content-id">{{ text.description }}</div>
            <div class="floating-balls-content" id="floating-balls-content2-id">{{ text.description2 }}</div>
            <div class="floating-balls-content" id="floating-balls-content3-id">{{ text.description3 }}</div>
            <a target="_blank" href="{{ text.subscribe.url }}" id="subscribe-button">{{ text.subscribe.text }}</a>
          </div>
          <button id="floatingballs-arrow-top" class="scene-arrow top black">↑</button>
      </div>`,
    background: 'white',
    transition: {
      enter: 'slide',
      leave: 'slide'
    },
    text: {},
    floatingBallContentEl: null,
    floatingBallContent2El: null,
    floatingBallContent3El: null,
    subButtonEl: null,
    floatingEl: null,
    logoEl: null,
    joinEl: null,

    preload() {
      return new Promise(resolve => {
        DataSource.fetch('text').then(txt => {this.text = txt.pages.floatingBalls; resolve();});
      });
    },

    onDestroy() {
      return new Promise(resolve => {
        TweenMax.killAll();
        clearAllTimeouts();
        timeout(() => resolve());
      });
    },

    init() {
      this.initElements();
      this.animation();

      timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { Director.previousScene(); }); }, 1000);
      const topArrow = document.getElementById('floatingballs-arrow-top');
      topArrow.addEventListener('click', () => Director.previousScene());
      circleButtonAnimation(topArrow);
      const tlTop = new TimelineMax();
      tlTop.to(topArrow, 3, {top: 60, ease: Elastic.easeInOut});
      tlTop.to(topArrow, 3, {top: 65, ease: Elastic.easeInOut});
      tlTop.repeat(1000);
    },

    initElements() {
      this.floatingEl = document.getElementById("floating-ball-bg");
      this.floatingBallContentEl = document.getElementById('floating-balls-content-id');
      this.floatingBallContent2El = document.getElementById('floating-balls-content2-id');
      this.floatingBallContent3El = document.getElementById('floating-balls-content3-id');
      this.subButtonEl = document.getElementById('subscribe-button');
      this.joinEl = document.getElementById('join-wrapper');
      this.logoEl = document.getElementById('floating-balls-logo-id');
    },
    
    animation() {
      var tl = new TimelineMax();
      tl.to(this.logoEl, 1, {opacity: 1}).delay(1.5);
      tl.to(this.joinEl, 1.5, {opacity: 1}).delay(1.5);
      floatingBallsAnimation(this.floatingEl);
      typewriterAnimation(this.floatingBallContentEl, {delay: 1500, letterDelay: 10});
      typewriterAnimation(this.floatingBallContent2El, {delay: 1500 + this.text.description.length * 10, letterDelay: 10});
      typewriterAnimation(this.floatingBallContent3El, {delay: 1500 + (this.text.description.length + this.text.description2.length) * 10, letterDelay: 10});
      liftUpAnimation(this.subButtonEl, {delay: 2200});
    }
  };
};