import { TransitionDirection } from "../scripts/transition-factory";
import { TimelineMax } from "gsap";

export function slideTransition(obj, dir) {
  if (dir === TransitionDirection.ENTER) {
    enter(obj);
  } else {
    leave(obj);
  }
};

function enter(obj) {
  var tl = new TimelineMax();
  tl.to(obj, 0, {y: '100vh'}, '0');
  tl.to(obj, 1, {y: 0}, '1');
};

function leave(obj) {
  var tl = new TimelineMax();
  tl.to(obj, 1, {y: '-100vh'});
};