import { fadeTransition } from "../transitions/fade";
import { slideTransition } from "../transitions/slideup";

export const Transition = {
  FADE: 'fade',
  SLIDE: 'slide'
};

export const TransitionDirection = {
  ENTER: 'enter',
  LEAVE: 'leave'
};

export function TransitionFactory() {};

TransitionFactory.prototype.createTransition = function(transition) {
  switch(transition) {
    case Transition.SLIDE:
      return slideTransition;
    case Transition.FADE:
      return fadeTransition;
    default:
      return function noTransition(){};
  }
};