import './spinner-effect.animation.scss';
import { GTAGHandler } from '../../scripts/gtaghandler';

export function spinnerEffectAnimation(button, callback) {
  const parentEl = button.parentElement;
  button = button || {};
  createElements(parentEl, button);
  let paused = false;
  let tl = new TimelineMax();
  button.addEventListener('click', event => {
    event.preventDefault();
    event.stopImmediatePropagation();
    GTAGHandler.addClickEvent(event);
    if (paused) {
      button.play();
    } else {
      button.pause();
    }
  });
  button.resetTimer = () => {
    parentEl.dataset.progress = 0;
    tl.clear();
    tl.clear();
    tl = new TimelineMax();
    const dummy = {
      progress: 0
    };
    tl.to(dummy, 10, {progress: 100, roundProps: 'progress', ease: Power0.easeNone, onUpdate: (tween) => {
      if (parentEl.dataset.progress != Math.round(tween.progress() * 100)) {
        parentEl.dataset.progress = Math.round(tween.progress() * 100);
      }
    }, onComplete: () => callback(), onUpdateParams: ['{self}']});
    if (paused) {
      tl.pause();
    } else {
      tl.play();
    }
  };
  button.pause = () => {
    tl.pause();
    paused = true;
    button.innerText = 'play_arrow';
  };
  button.play = () => {
    paused = false;
    button.innerText = 'pause';
    tl.play();
  };

  button.resetTimer();
};

function createElements(parentEl, button) {
  const circle = document.createElement('div');
  circle.classList.add('circle');
  const maskFull = document.createElement('div');
  maskFull.classList.add('mask');
  maskFull.classList.add('full');
  const maskFill = document.createElement('div');
  maskFill.classList.add('fill');
  maskFull.appendChild(maskFill);
  circle.appendChild(maskFull);
  const maskHalf = document.createElement('div');
  maskHalf.classList.add('mask');
  maskHalf.classList.add('half');
  const fillEl = document.createElement('div');
  fillEl.classList.add('fill');
  const fillFix = document.createElement('div');
  fillFix.classList.add('fill');
  fillFix.classList.add('fix');
  maskHalf.appendChild(fillEl);
  maskHalf.appendChild(fillFix);
  circle.appendChild(maskHalf);
  const inset = document.createElement('div');
  inset.classList.add('inset');
  const percentage = document.createElement('div');
  percentage.classList.add('percentage');
  inset.appendChild(percentage);
  percentage.appendChild(button);
  parentEl.classList.add('radial-progress');
  parentEl.dataProgress = 0;
  parentEl.appendChild(circle);
  parentEl.appendChild(inset);
}