import example1Scene from "../example-1-scene/example-1.scene";
import { Director } from "../../scripts/director";

export default function Example2Scene() {
  return {
    template: `<div click="example1">example2</div>`,
    background: 'black',
    transition: {
      enter: 'fade',
      leave: 'fade'
    },

    preload() {
      console.log("example2 preload");
    },

    example1() {
      Director.toScene(example1Scene);
    }
  };
};