import './curved-text.animation.scss';
import { TimelineMax } from 'gsap';
import { timeout } from '../../scripts/util';

export function curvedTextAnimation(txtElement, opts) {
  const tl = new TimelineMax();
  opts = opts || {};
  opts.delay = opts.delay || 0;
  opts.letterDelay = opts.letterDelay || 10;
  txtElement.innerHTML = txtElement.innerText.replace(/([^\x00-\x80]|.)/g, "<span class='lift-letter'>$&</span>");

  timeout(() => {
    var i = 0;
    txtElement.childNodes.forEach(node => {
      animate(tl, node, opts.letterDelay);
    });
  }, opts.delay + 1000);
};

function animate(tl, node, delay) {
  tl.to(node, 0.4, {top: 0, left: 0, opacity: 1}, `=-${0.4 - delay/1000}`);
}