import './arrow-controls.scene.scss';
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';

export default function ArrowControlsScene() {
  return {
    template: `
      <div class='vertical-center text-center row'>
        <div class='col-6 text-right arrows-example leftwrap'><button id='arrow1'>←</button></div>
        <div class='col-6 text-left arrows-example'><button id='arrow2'>→</button></div>
      </div>`,
    background: '#222222',
    transition: {
      enter: 'fade',
      leave: 'fade'
    },

    init() {
      var arrow1 = document.getElementById('arrow1');
      var arrow2 = document.getElementById('arrow2');
      circleButtonAnimation(arrow1, { color: 'white' });
      circleButtonAnimation(arrow2);
    }
  };
};