import './line-by-line-reveal.animation.scss';
import { splitIntoLines, timeout } from '../../scripts/util';
import { TimelineMax } from 'gsap';

export function lineByLineReveal(txtElement, opts) {
  opts = opts || {};
  opts.delay = opts.delay || 1;
  opts.lineDelay = opts.lineDelay || 25;
  txtElement.style.opacity = 0;
  txtElement.innerHTML = txtElement.innerText.replace(/([^\x00-\x80]|.)/g, "<span>$&</span>");

  timeout(() => {
    doLineSplit(txtElement);
    txtElement.style.opacity = 1;
    var i = 0;
    txtElement.childNodes.forEach(node => {
      animate(node, opts.lineDelay * i++);
    });
  }, opts.delay);

};

function animate (node, delay) {
  timeout(() => {
    var tl = new TimelineMax();
    tl.to(node, 0.4, {top: 0, right: 0, opacity: 1});
  }, delay);
}

function doLineSplit(txtElement) {
  var lines = splitIntoLines(txtElement);
  var divLines = [];
  lines.forEach(line => {
    var div = document.createElement('div');
    div.classList.add('lbl-line');
    line.forEach(letter => {
      div.appendChild(letter);
    });
    divLines.push(div);
  });
  
  txtElement.innerHTML = "";
  divLines.forEach(div => {
    txtElement.appendChild(div);
  });
}