import './funds-pre-text-page.scene.scss';
import { DataSource } from "../../scripts/datasource";
import { InteractionHandler, InteractionDirection } from "../../scripts/interaction-handler";
import { Director } from "../../scripts/director";
import { typewriterAnimation } from '../../animations/typewriter/typewriter.animation';
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';
import { timeout } from '../../scripts/util';
import { TimelineMax, Elastic } from 'gsap';


export default function FundsPreTextPageScene() {
    return {
        template: `
        <div class="page-wrapper pre-text-wrapper">
            <div class="pre-text-header">
                <div class="logo-wrapper">
                    <a target="_blank" href="http://www.itware.hu"><img class="header-visualization-poc-logo" src="assets/images/itware.png"></a>
                </div>
                <a href="{{ text.subscribe.url }}" target="_blank" class="subscribe" id="pre-text-subscribe"><span>{{ text.subscribe.text }}</span><i class="material-icons" id="pre-text-subscribe-icon">exit_to_app</i></a>
            </div>
            <div class="pre-text-content-wrapper col-lg-8 col-md-12">
                <h2>{{ text.title }}</h2>
                <div id="pre-text-content">{{ text.description }}</div>
            </div>
            <button id="funds-arrow-top" class="scene-arrow black top">↑</button>
            <button id="funds-arrow-bottom" class="scene-arrow black bottom">↓</button>
        </div>
                `,
        background: 'white',
        contentEl: null,
        text: {},

        preload() {
            return new Promise(resolve => {
                DataSource.fetch('text').then(txt => {this.text = txt.pages.faangPreTextPage; resolve();});
            });
        },

        init() {
            this.contentEl = document.getElementById('pre-text-content');
            this.animation();
            timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { Director.nextScene(); }); }, 1000);
            timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { Director.previousScene(); }); }, 1000);
            const topArrow = document.getElementById('funds-arrow-top');
            topArrow.addEventListener('click', () => Director.previousScene());
            circleButtonAnimation(topArrow);
            const tlTop = new TimelineMax();
            tlTop.to(topArrow, 3, {top: 60, ease: Elastic.easeInOut});
            tlTop.to(topArrow, 3, {top: 65, ease: Elastic.easeInOut});
            tlTop.repeat(1000);
            const bottomArrow = document.getElementById('funds-arrow-bottom');
            bottomArrow.addEventListener('click', () => Director.nextScene());
            circleButtonAnimation(bottomArrow);
            const tlBottom = new TimelineMax();
            tlBottom.to(bottomArrow, 3, {bottom: 60, ease: Elastic.easeInOut});
            tlBottom.to(bottomArrow, 3, {bottom: 65, ease: Elastic.easeInOut});
            tlBottom.repeat(1000);
            timeout(() => {
                Director.nextScene();
            }, 4500);
        },
        
        animation() {
            typewriterAnimation(this.contentEl);
        }
    };
};