import "./bubble-menu.scene.scss";
import {DataSource} from "../../scripts/datasource";
import { bubbleMenuAnimation } from "../../animations/bubble-menu/bubble-menu.animation";
import { clearAllTimeouts, timeout } from "../../scripts/util";


export default function BubbleMenuScene() {
	return {
		template: `
		<div id="ball-menu-container">
		</div>
		`,
		background: 'black',
		data: null,

		preload() {
			return new Promise(resolve => {
				DataSource.fetch('bubbleMenu').then(menu => {this.data = menu.menuItems; resolve();});
			});
		},

		onDestroy() {
      return new Promise(resolve => {
        TweenMax.killAll();
        clearAllTimeouts();
        timeout(() => resolve());
      });
    },

		onResize() {
			this.bubbleMenu.resize();
		},

		init() {
			var menuEl = document.getElementById("ball-menu-container");
			this.bubbleMenu = bubbleMenuAnimation(menuEl, {
				data: this.data, 
				position: {
					top: 75
				}
			}).prepare().animate();
		}
	};
};
