import './factor-box.scene.scss';
import { InteractionDirection, InteractionHandler } from '../../scripts/interaction-handler';
import { Director } from '../../scripts/director';
import { Canvas, CanvasLine, CanvasRect, CanvasText } from '../../scripts/canvas';
import { TimelineMax } from "gsap";
import { DataSource } from '../../scripts/datasource';
import { blackOrWhiteBackground, blackOrWhiteColor, timeout, getColor } from '../../scripts/util';
import { accordion } from "../../scripts/accordion";
import { FactorDataSource } from "../../scripts/factordatasource";
import { FundFactorDataSource } from "../../scripts/fundfactordatasource";
import { autocomplete } from "../../scripts/autocomplete";
import { dialog } from '../../scripts/dialog';
import { GTAGHandler } from '../../scripts/gtaghandler';

export default function factorBoxScene(mode) {
  mode = mode || '';
  return {
    /*inline-html*/
    template: `
      <div id="${mode}-factor-box-container" style="transform-origin: top left; transform: scale(${window.innerWidth / 1920}); width: 1920px; height: 1080px;">
        <div id="${mode}-factor-box">
          <div id="${mode}-canvas-container">
            <div class="left" id="${mode}-label-container"></div>
            <div style="width: fit-content">
              <div id="${mode}-title-container">
                <span class="title-separator"></span>
                <span id="${mode}-title"></span>
                <span class="title-separator"></span>
              </div>
              <canvas id="${mode}-factor-box-canvas"></canvas>
              <div id="${mode}-bottom-container">
                <span id="${mode}-bottom-negative"></span>
                <span id="${mode}-bottom-zero"></span>
                <span id="${mode}-bottom-positive"></span>
              </div>
            </div>
            <div class="sep"></div>
            <div class="sep"></div>
            <div class="right" id="${mode}-separator"></div>
          </div>
          <div id="${mode}-accordion-container">
            <div id="${mode}-switch-container" class="gray-wrapper ${blackOrWhiteBackground()}">
              <div id="${mode}-stocks" style="width: 100%" static>
                <span id="${mode}-close-btn" class="close" style="display: none">&times;</span>
              <div id="${mode}-faang-switches"></div>
            </div>
          </div>
          <div id="${mode}-add-stocks" class="gray-wrapper ${blackOrWhiteBackground()}">
            <div id="${mode}-add-stock" style="width: 100%" panel="Add Stock" static>
              <input id="${mode}-factorBox-input" type="text" placeholder="Choose a company" autocomplete="off">
            </div>
          </div>
          <div id="${mode}-factor-box-bottom-cta" class="bottom-facs-cta gray-wrapper ${blackOrWhiteBackground()}" style="display: block;">
            <div>Access all Companies</div>
            <div style="display: flex">
              <a href="http://www.itware.hu">
                <div class="cta-button">
                  Sign-In
                </div>
              </a>
              <div style="flex: 1 auto"></div>
              <a href="http://www.itware.hu">
                <div class="cta-button">
                  Subscribe
                </div>
              </a>
            </div>
          </div>
        </div> 
        <div id="${mode}-factor-box-overlay" class="${blackOrWhiteBackground()}" style="display: none"></div>
        <div id="${mode}-factor-box-modal-content" class="modal-content">
          {{ text.tooManyAssetswarning }}
        </div>
        <div id="${mode}-factor-box-modal-cta" class="modal-content">
        <div class="bottom-facs-cta gray-wrapper ${blackOrWhiteBackground()}" style="display: block;">
          <div class="title">Access all Companies</div>
          <div class="header">The selected company is available for FaCS Clients.</div>
          <div style="display: flex">
            <a href="http://www.itware.hu">
              <div class="cta-button">
                Sign-In
              </div>
            </a>
            <div style="flex: 1 auto"></div>
            <a href="http://www.itware.hu">
              <div class="cta-button">
                Subscribe
              </div>
            </a>
          </div>
        </div>
      </div>
      </div>`,
    background: blackOrWhiteBackground(),
    transition: {
      enter: 'slide', leave: 'slide'
    },

    data: [],
    legend:[],
    chartTicks: [],
    canvasCharts: [],
    canvasChartsShown: [],
    preloadedCharts: [],
    baseMargin: 5,
    inverseScale: 0,
    needsUpdate: false,
    chartWidth: 12,
    margin: 20,
    max: 0,
    bgCanvas: null,
    stopRender: false,

    prevChartWidth: 12,

    methods: {},

    autocompleteApi: null,
    widthChanged: false,
    initial: true,
    overlay: null,
    dialogApi: null,
    dataSource: null,
    canvas: null,
    ctaDialogApi: null,

    preload() {
      let source = mode === 'fund' ? 'funds' : 'faangDiagram';
      this.dataSource = mode === 'fund' ? FundFactorDataSource : FactorDataSource;
      return new Promise(resolve => {
        Promise.all([DataSource.fetch(source), DataSource.fetch('text')]).then(values => {
          this.preloadedCharts = values[0].preloadedCharts;
          this.data = values[0].data;
          this.legend = values[0].legend;
          this.text = values[1].pages.faangDiagram;

          this.preloadedCharts.forEach(chart => {
            this.data.forEach(data => {
              if(chart.label === data.assetName) {
                this.canvasCharts.push(this.createChartFromData(data, chart));
                this.canvasCharts.forEach(ch =>{
                  ch.values.forEach(value => {
                    this.max = Math.max(this.max, Math.abs(value));
                  });
                });
              }
            });
          });
          /*if (this.max > 1) {
            this.canvasCharts.forEach(chart => {
              chart.values = chart.values.map(value => value / this.max);
            });
          }*/
          resolve();
        });
      });
    },

    init() {
      timeout(()=> {
        timeout(() => {
          InteractionHandler.on(InteractionDirection.DOWN, () => {
            this.stopRender = true;
            Director.nextScene();
          });
        });
        timeout(() => {
          InteractionHandler.on(InteractionDirection.UP, () => {
            this.stopRender = true;
            Director.previousScene();
          });
        });

        let factorBox = document.getElementById(mode + "-factor-box");
        let labelContainer = document.getElementById(mode + '-label-container');
        this.canvas = document.getElementById(mode + '-factor-box-canvas');
        let switchContainer = document.getElementById(mode + '-faang-switches');

        this.setFactorBoxAndScale(factorBox);
        this.drawLegends(factorBox, labelContainer);
        this.createCanvas(this.canvas);
        this.addAccordions();
        this.autocompleteApi = autocomplete(document.getElementById(mode + "-factorBox-input"), this.dataSource, document.getElementById(mode + '-factor-box-container'), document.getElementById(mode + '-factor-box-bottom-cta'), (asset) => {
          this.dataSource.fetchByAsset(asset.assetId).then((item) => {
            const validationList = this.canvasCharts.map(data => data.label);
            if (this.canvasCharts.length > 7) {
              this.dialogApi.open();
            } else if(!validationList.includes(item.assetName)) {
              let chart = this.createChartFromData(item);
              if (this.max > 1) {
                chart.values = chart.values.map(value => value / this.max);
              }
              this.canvasCharts.push(chart);
              this.addSwitch(chart, switchContainer);
              document.getElementById(`${mode}-${chart.label}-switch`).click();
            }
          });
        });
        this.autocompleteApi.setItemFilter((item) => {
          return !item.paid;
        });
        this.addEventListenerToFaangInput(switchContainer);
        this.setContainers(factorBox);
        this.draw();
        this.addInitialCharts(switchContainer);
        this.overlay = document.getElementById(mode + '-factor-box-overlay');
        const modal = document.getElementById(mode + '-factor-box-modal-content');
        this.dialogApi = dialog(document.body, modal, true);
        this.ctaDialogApi = dialog(document.body, document.getElementById(`${mode}-factor-box-modal-cta`), true);
        this.autocompleteApi.onShowCta((show) => {
          if (this.ctaDialogApi) {
            this.ctaDialogApi.open();
          }
        });
      },1001);
    },

    createOverlay(index, top, left) {
      top = top / window.innerWidth * 1920;
      left = left / window.innerHeight * 1080;

      for (let i = 0; i < 8; i++) {
        this.legend[i].background.props.opacity = i == index ? 0.3 : 0.1;
      }
      this.overlay.innerHTML = `<h4>${this.legend[index].label} Exposure</h4>`;
      this.overlay.style.display = 'block';
      this.overlay.style.opacity = 1;
      this.canvasChartsShown.sort((a, b) => parseFloat(b.values[index]) - parseFloat(a.values[index])).forEach(chart => {
        const div = document.createElement('div');
        div.style.display = 'flex';
        /*inline-html*/
        div.innerHTML = `
          <div style="background: ${chart.color}; display: block; width: 20px; height: 3px; position: relative; top: 10px;"></div>
          <span style="margin-left: 5px; margin-right: 10px">${chart.label}</span>
          <div style="flex: 1 auto"></div>
          <span>${parseFloat(chart.values[index] || 0).toFixed(2)}</span>
        `;
        // <span style="color: ${getColor(parseFloat(chart.values[index] || 0).toFixed(2), -1, 1, 1)};">${parseFloat(chart.values[index] || 0).toFixed(2)}</span>
        this.overlay.appendChild(div);
      });
      this.overlay.style.top = Math.min(top, 1080 /*window.innerHeight*/ - this.overlay.offsetHeight) + 'px';
      this.overlay.style.left = Math.min(left, 1920 /*window.innerWidth*/ - this.overlay.offsetWidth) + 'px';
    },

    createChartFromData(data, item) {
      const defaultColor = '#' + ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6);
      const defaultSwitch = 'on';

      let chart = {
        objects: [],
        values: [data.value, data.size, data.momentum, data.quality, data.yield, data.volatility, data.growth, data.liquidity],
      };

      chart.label = data.assetName;

      if(item) {
        chart.color = item.color ? item.color : defaultColor;
        chart.switch = item.switch? item.switch : defaultSwitch;
      } else {
        chart.color = defaultColor;
        chart.switch = defaultSwitch;
      }

      return chart;
    },

    setChartBaseValues(length){
      length = length || this.canvasChartsShown.length || 1;
      const newWidth = 85 / (length + 2 / length);
      const newMargin = 125 / (length + 2 / length);

      this.widthChanged = this.chartWidth !== newWidth;
      this.chartWidth = newWidth;
      this.margin = newMargin;
    },

    addInitialCharts(switchContainer){
      this.canvasCharts.forEach(chart => {
        this.addSwitch(chart, switchContainer);
        //if(chart.switch !== 'off') {
          document.getElementById(`${mode}-${chart.label}-switch`).click();
        //}
      });
      timeout(() => this.initial = false, 1000);
    },

    addEventListenerToFaangInput(switchContainer){
      let input = document.getElementById(mode + '-factorBox-input');
      input.click();
    },

    addAccordions(){
      let stocks = document.getElementById(mode + '-switch-container');
      let stocksPanel = document.getElementById(mode + '-stocks');
      stocksPanel.setAttribute('panel', mode === 'fund' ? 'Compare Funds' : 'Compare Stocks');
      stocks.appendChild(stocksPanel);
      accordion(stocks, mode);

      let addCompany = document.getElementById(mode + '-add-stocks');
      let addCompanyPanel = document.getElementById(mode + '-add-stock');
      addCompanyPanel.setAttribute('panel', mode === 'fund' ? 'Add fund' : 'Add stock');
      addCompany.appendChild(addCompanyPanel);
      accordion(addCompany, mode);
    },

    setFactorBoxAndScale(factorBox){
      document.getElementById(mode + "-title").innerHTML = this.text.factorTitle.replace('{{mode}}', this.mode === 'stock' ? 'Stocks' : 'Funds');
      document.getElementById(mode + "-bottom-negative").innerHTML = this.text.negative;
      document.getElementById(mode + "-bottom-zero").innerHTML = this.text.zero;
      document.getElementById(mode + "-bottom-positive").innerHTML = this.text.positive;

      factorBox.style.width = '1920px'; //window.innerWidth + 'px';
      factorBox.style.height = '1080px'; // window.innerHeight + 'px';
      factorBox.style.background = blackOrWhiteBackground();
      factorBox.style.color = blackOrWhiteColor();

      this.inverseScale = factorBox.clientHeight <= factorBox.clientWidth ? factorBox.clientHeight : factorBox.clientWidth;
    },

    drawLegends(factorBox, labelContainer) {
      this.legend.forEach(label => {
        let legendFrame = document.createElement('div');
        legendFrame.className = 'circle-label';
        labelContainer.appendChild(legendFrame);

        let legend = document.createElement('div');
        legend.setAttribute('class', 'circle');
        legend.style.backgroundColor = label.color;
        legend.style.backgroundImage = "url(" + label.image + ")";
        legendFrame.appendChild(legend);

        let legendText = document.createElement('span');
        legendText.innerText = label.label;
        legendFrame.appendChild(legendText);
      });
    },

    addSwitch(chart, switchContainer) {
/*      // RESPONSIVE
      const switchScale = this.inverseScale / 25;
      const pseudoMargin = this.inverseScale / 180;*/

      const switchScale = 36;
      const pseudoMargin = this.inverseScale / 180;

      chart.div = document.createElement('div');
      chart.div.setAttribute('class', 'stock');
      chart.div.setAttribute('id', `${mode}-${chart.label}-stock`);

      let label = document.createElement('label');
      label.setAttribute('class', 'switch');
      label.setAttribute('for', `${mode}-${chart.label}-switch`);
      label.style.height = switchScale + 'px';
      label.style.width = switchScale * 2 + this.baseMargin + 3 + 'px';
      chart.div.appendChild(label);

      let input = document.createElement('input');
      input.setAttribute('type', 'checkbox');
      input.checked = !!chart.switch;
      input.setAttribute('id', `${mode}-${chart.label}-switch`);
      label.appendChild(input);

      let div = document.createElement('div');
      div.setAttribute('class', 'sliders');
      div.style.backgroundColor = chart.color;
      label.appendChild(div);

      let pseudo = document.createElement('div');
      pseudo.setAttribute('id', `${mode}-pseudo-${chart.label}`);
      pseudo.setAttribute('class', 'pseudo');
      pseudo.style.width = switchScale - pseudoMargin + 'px';
      pseudo.style.height = switchScale - pseudoMargin + 'px';
      pseudo.style.marginLeft = '3px';
      div.appendChild(pseudo);

      let text = document.createElement('div');
      text.innerHTML = chart.label;
      text.className = "factor-asset-switch-label";
      chart.div.appendChild(text);

      let removeContainer = document.createElement('span');
      removeContainer.style.marginLeft = 'auto';
      chart.div.appendChild(removeContainer);

      let removeButton = document.createElement('i');
      removeButton.setAttribute('id', `${mode}-${chart.label}-remove`);
      removeButton.setAttribute('class', 'material-icons');
      removeButton.style.cursor = 'pointer';
      removeButton.innerHTML = 'remove_circle_outline';
      removeContainer.appendChild(removeButton);

      switchContainer.appendChild(chart.div);

      document.getElementById(`${mode}-${chart.label}-switch`).click();
      this.addEventListenerToSwitch(chart, this.canvas, input, switchScale, pseudoMargin);
      this.addEventListenerToSwitchRemove(chart, this.canvas, input, removeButton);

      if (this.autocompleteApi) {
        this.autocompleteApi.recalculateHeight();
      }
    },

    addEventListenerToSwitch(chart, canvas, input, switchScale, pseudoMargin) {
      input.addEventListener('change', () => {
        if(input.checked) {
          chart.switch = 'on';
          document.getElementById(mode + '-pseudo-' + chart.label).style.transform =
              "translateX(" + (switchScale + pseudoMargin + 2) + "px)";

          chart.draw = false;
          this.addChart(chart, canvas);
          let changed = this.initial || this.widthChanged;
          this.moveCharts(canvas, !changed);
          timeout(() => {
            chart.draw = true;
            this.reScale();
            this.moveCharts(canvas);
            this.animateChart(chart, canvas, input, false, false);
          }, changed ? 500 : 0);

        } else {
          chart.switch = 'off';
          document.getElementById(mode + '-pseudo-' + chart.label).style.transform = "translateX(0)";

          if (this.canvasChartsShown.map(c => c.label).includes(chart.label)) {
            this.animateChart(chart, canvas, input, true, false);
          }
        }
      });
    },

    addEventListenerToSwitchRemove(chart, canvas, input, removeButton) {
      removeButton.addEventListener("click", (event) => {
        GTAGHandler.addClickEvent(event);
        this.animateChart(chart, canvas, input, false, true);
        document.getElementById(`${mode}-${chart.label}-stock`).remove();
        if (this.autocompleteApi) {
          this.autocompleteApi.recalculateHeight();
        }
      });
    },

    animateChart(chart, canvas, input, off, del) {
      let tl = new TimelineMax();
      let self = this;

      if(off) {
        this.reScale();
        tl = new TimelineMax({onComplete: () => {
            self.canvasChartsShown.splice(self.canvasChartsShown.indexOf(chart), 1);
            chart.objects.forEach(object => {
              delete this.bgCanvas.objects[object.id];
            });
            chart.objects = [];
            self.moveCharts(canvas);
          }});
      }

      if (del) {
        tl = new TimelineMax({onComplete: () => {
            self.canvasCharts.splice(self.canvasCharts.indexOf(chart), 1);
            chart.objects.forEach(object => {
              delete this.bgCanvas.objects[object.id];
            });
            if(chart.switch === 'on') {
              self.canvasChartsShown.splice(self.canvasChartsShown.indexOf(chart), 1);
              self.moveCharts(canvas);
            }
          }});
        input.checked = false;
      }

      /*chart.objects.forEach((line, j) => {
        chart.animating = true;
        tl.to(line.coords, 0.6, {
          2: (canvas.width/2) + (input.checked ? chart.values[j] * this.bgCanvas.nativeCanvas.width / 2 / this.max : 0),
        }, `=-${j?0.6:0}`);
      });*/
      tl.to(chart.currentValues, 0.6, {
        0: input.checked ? chart.values[0] : 0,
        1: input.checked ? chart.values[1] : 0,
        2: input.checked ? chart.values[2] : 0,
        3: input.checked ? chart.values[3] : 0,
        4: input.checked ? chart.values[4] : 0,
        5: input.checked ? chart.values[5] : 0,
        6: input.checked ? chart.values[6] : 0,
        7: input.checked ? chart.values[7] : 0
      });
    },

    moveCharts(canvas, noanim) {
      this.setChartBaseValues();
      this.canvasChartsShown.forEach((chart, index) => {
        chart.objects.forEach((ob, i) => {
          if (noanim) {
            ob.coords[1] = (i * (canvas.height - 20) / 8) + this.margin + (index * this.chartWidth);
            ob.coords[3] = (i * (canvas.height - 20) / 8) + this.margin + (index * this.chartWidth);
          } else {
            let tl = new TimelineMax();
            tl.to(ob.coords, 0.6, {
              1: (i * (canvas.height - 20) / 8) + this.margin + (index * this.chartWidth),
              3: (i * (canvas.height - 20) / 8) + this.margin + (index * this.chartWidth)
            });
          }
        });

        chart.objects.forEach(o => {
          if (chart.draw) {
            let tl = new TimelineMax();
            tl.to(o.props, 0.6, {width: this.chartWidth - 2});
          }
        });

      });
    },

    reScale() {
      let max = 0;
      this.canvasChartsShown.forEach(chart => {
        if (chart.switch === 'on') {
          chart.values.forEach(value => {
            if (Math.abs(value) > max) {
              max = Math.abs(value);
            }
          });
        }
      });
      max = Math.ceil(max * 4) / 4 + 0.05;
      const tl = new TimelineMax();
      tl.to(this, 0.6, { max: max });
    },

    createCanvas(canvas) {
      canvas.width = 1240;
      canvas.height = 900;
      canvas.style.backgroundColor = 'rgba(230, 230, 230, 0.1)';
      canvas.addEventListener('mousemove', (e) => {
        this.createOverlay(Math.floor(e.offsetY / ((canvas.height - 20) / 8)), e.clientY + 20, e.clientX + 20);
      });
      canvas.addEventListener('mouseleave', (e) => {
        this.overlay.style.display = 'none';
      });

      this.bgCanvas = new Canvas(canvas);

      for (let i = -3; i <= 3; i++) {
        for (let j = 0; j < 4; j++) {
          const n = i + j * 0.25;
          const x = n * canvas.width / 2 / this.max + canvas.width / 2;
          let canvasText = new CanvasText('' + n, {
            top: canvas.height - 15,
            left: x,
            color: 'rgba(64, 64, 64, 1)'
          });
          let canvasLine = new CanvasLine([x, 0, x, canvas.height - 20], {
            color: 'rgba(64, 64, 64, 1)',
            width: 1
          });
          this.chartTicks.push({
            line: canvasLine,
            label: canvasText
          });
          this.bgCanvas.add(canvasLine);
          this.bgCanvas.add(canvasText);
        }
      }

      this.legend.forEach((o, i) => {
        const rect = new CanvasRect([0, i * (canvas.height - 20)/8, canvas.width, (canvas.height - 20)/8], {
          color: o.color,
          opacity: 0.1
        });
        this.bgCanvas.add(rect);
        o.background = rect;
      });
      this.bgCanvas.render();
    },

    setContainers(factorBox) {
      let canvasContainer = document.getElementById(mode + "-canvas-container");
      let titleContainer = document.getElementById(mode + "-title-container");
      let bottomContainer = document.getElementById(mode + "-bottom-container");

      canvasContainer.style.width = factorBox.clientWidth + 'px';
      canvasContainer.style.height = factorBox.clientHeight + 'px';
      canvasContainer.style.height = factorBox.clientHeight - titleContainer.clientHeight - bottomContainer.clientHeight - 93 + 'px';
    },

    addChart(chart, canvas) {
      if(this.canvasChartsShown.map(c=>c.label).includes(chart.label) || chart.switch === 'off') {
        return;
      }
      chart.currentValues = [0, 0, 0, 0, 0, 0, 0, 0];
      this.setChartBaseValues(this.canvasChartsShown.length + 1);
      chart.values.forEach((value, i) => {
        let line = new CanvasLine(
            [
              canvas.width / 2,
              (i * (canvas.height - 20) / 8) + this.margin + 0,
              canvas.width / 2,
              (i * (canvas.height - 20) / 8) + this.margin + 0
            ], {
              color: chart.color,
              width: this.chartWidth - 2
            });
        chart.objects.push(line);
        this.bgCanvas.add(line);
      });
      this.canvasChartsShown.push(chart);
    },

    draw() {
      const renderFrame = () => {
        this.canvasCharts.forEach(chart => {
          const canvas = this.bgCanvas.nativeCanvas;         
          for (let i = -3; i <= 3; i++) {
            for (let j = 0; j < 4; j++) {
              const n = i + j * 0.25;
              const x = n * canvas.width / 2 / this.max + canvas.width / 2;
              let line = this.chartTicks[(i + 3) * 4 + j].line;
              let label = this.chartTicks[(i + 3) * 4 + j].label;
              line.tl = line.tl || new TimelineMax();
              label.tl = label.tl || new TimelineMax();
              line.coords = [x, 0, x, canvas.height - 20];
              label.props.left = x - label.width / 2;
              if (this.max > 2 && (j == 1 || j == 3)) {
                if (!line.hidden) {
                  line.hidden = true;
                  line.tl.to(line.props, 0.6, { color: 'rgba(64, 64, 64, 0)' });
                  label.tl.to(label.props, 0.6, { color: 'rgba(64, 64, 64, 0)' });
                }
              } else {
                if (line.hidden) {
                  line.hidden = false;
                  line.tl.kill();
                  line.tl.to(line.props, 0.6, { color: 'rgba(64, 64, 64, 1)' });
                  label.tl.to(label.props, 0.6, { color: 'rgba(64, 64, 64, 1)' });
                }
              }
            }
          }
          this.canvasChartsShown.forEach(chart => {
            chart.objects.forEach((line, j) => {
              line.coords[2] = (canvas.width/2) + (chart.currentValues[j] * this.bgCanvas.nativeCanvas.width / 2 / this.max);
            });
          });
          //if (chart.animating) {
            this.bgCanvas.render();
            //this.needsUpdate = true;
          //}
        });
        /*if (this.needsUpdate) {
          this.needsUpdate = false;
        }*/
        requestAnimationFrame(renderFrame);
      };
      requestAnimationFrame(renderFrame);
    },
  };
}

export function factorBoxStockScene(mode) {
  return new factorBoxScene(mode);
}

export function factorBoxFundScene(mode) {
  return new factorBoxScene(mode);
}
