import './overlay.animation.scss';
import { Director } from '../../scripts/director';
import { circleButtonAnimation } from '../circle-button/circle-button.animation';

const CSS_CLASS = {
  OVERLAY: {
    OPEN: "overlay-open",
    CLOSED: "overlay-closed",
    WRAPPER: "overlay-wrapper"
  }
};

export function overlayAnimation(element, opts) {
  opts = opts || {};
  opts.defaultOpen = opts.defaultOpen || false;
  opts.classes = opts.classes || "";
  opts.color = opts.color || "black";

  var wrapperDiv = document.createElement('div');
  var classes = ["col-12", "col-lg-4", CSS_CLASS.OVERLAY.WRAPPER, (opts.defaultOpen ? CSS_CLASS.OVERLAY.OPEN : CSS_CLASS.OVERLAY.CLOSED), opts.classes, opts.color].join(" ");
  wrapperDiv.className = classes;
  var closeBtn = createCloseButton();
  closeBtn.onclick = () => { close(wrapperDiv); };
  wrapperDiv.appendChild(closeBtn);
  circleButtonAnimation(closeBtn, { color: opts.color });

  insert(wrapperDiv, element);
  
  return {
    open: function() {
      return open(wrapperDiv);
    },
    close: function() {
      return close(wrapperDiv);
    },
    toggle: function() {
      return toggle(wrapperDiv);
    }
  };
}

function open(wrapperDiv) {
  wrapperDiv.classList.remove(CSS_CLASS.OVERLAY.CLOSED);
  wrapperDiv.classList.add(CSS_CLASS.OVERLAY.OPEN);
}

function close(wrapperDiv) {
  wrapperDiv.classList.remove(CSS_CLASS.OVERLAY.OPEN);
  wrapperDiv.classList.add(CSS_CLASS.OVERLAY.CLOSED);
}

function toggle(wrapperDiv) {
  if (wrapperDiv.classList.contains(CSS_CLASS.OVERLAY.OPEN)) {
    return close(wrapperDiv);
  }

  return open(wrapperDiv);
}

function insert(wrapper, element) {
  element.parentNode.insertBefore(wrapper, element);
  wrapper.appendChild(element);
  Director.getCurrentNode().appendChild(wrapper);
}

function createCloseButton() {
  var closeBtn = document.createElement("button");
  closeBtn.type = "button";
  closeBtn.className = "float-right";
  closeBtn.innerText = "×";
  return closeBtn;
}