import { eventBus, VisEvent } from "./eventbus";
import { Navigation } from "./navigation";
import { isMobile, isPortrait, clearAllTimeouts, timeout } from "./util";
import { TransitionFactory, TransitionDirection } from "./transition-factory";
import { TimelineMax } from "gsap";
import { Help } from './help';

const ROOT_ELEMENT_ID = 'visualization-poc';

export const Director = {
  init({ scenes, routes, rootElement }) {
    this.scenes = scenes;
    this.transitionFactory = new TransitionFactory();

    this.app = document.getElementById(rootElement);
    var initScene = this.initRoutes(routes);
    this.currentScene = null;
    this.waitForLandscape().then(() => {
      this.toScene(initScene);
  
      window.onresize = (evt) => {
        if (this.currentScene && typeof this.currentScene.onResize === 'function') {
          this.currentScene.onResize(evt);
        }
      };
    });
  },

  initRoutes(routes) {
    var href = window.location.href;
    var currentRoute = href.substring(href.lastIndexOf("#"));

    for (var prop in routes) {
      if (routes[prop].scene && routes[prop].param) {
        routes[prop].scene.$$param = routes[prop].param;
        routes[prop] = routes[prop].scene;
      }
      routes[prop].$$route = prop;
    }

    window.onpopstate = (event) => {
      if (!event.state) {
        return;
      }

      this.toScene(routes[event.state]);
    };

    return routes[currentRoute] || routes['#'];
  },

  waitForLandscape() {
    return new Promise(resolve => {
      if (isMobile() && isPortrait()) {
        window.addEventListener('orientationchange', () => {
          resolve();
        });
        return;
      }

      resolve();
    });
  },

  instantiate(scene) {
    var instantBgChange = this.currentScene === null;
    this.changeScene(scene);
    this.setOverflow(scene);
    this.attachClickHandlers(this.app, scene);
    scene.background && this.setBackground(scene.background, instantBgChange);
  },

  setOverflow(scene) {
    document.body.style.overflow = scene.overflow ? scene.overflow : 'hidden';
  },

  changeScene(scene) {
    var currentNode = this.app.childNodes[0];
    this.transitionLeave(currentNode, this.currentScene);
    this.currentScene = scene;
    var sceneElement = this.createElement(scene);
    this.app.appendChild(sceneElement);

    if (scene.injectCanvas && this.canvas) {
      this.app.childNodes[this.app.childNodes.length - 1].appendChild(this.canvas);
    }

    if (scene.init) {
      scene.init(this.renderer, scene.$$param);
    }

    this.transitionEnter(currentNode ? this.app.childNodes[1] : this.app.childNodes[0], scene);
      setTimeout(() => {
        currentNode && this.app.childNodes[0].remove();
        Help.show(ROOT_ELEMENT_ID);
      }, 1000);
  },

  createElement(scene) {
    var main = document.createElement('main');
    main.innerHTML = this.mustache(this.mustache(scene.template.trim(), scene), scene); // double mustache to support mustaches in labels
    
    if (scene.transition && scene.transition.enter) {
      main.classList.add(scene.transition.enter + "-enter");
    }

    return main;
  },

  mustache(str, scene) {
    return str.replace( /\{\{(.*?)\}\}/g, (mustacheStr, prop) => {
      prop = prop.trim();
      var toReturn = "";
      try {
        toReturn = prop.split('.').reduce((o,i)=>o[i], scene);
        if (toReturn === undefined) {
          toReturn = "";
          throw new Error('property is undefined');
        }
      } catch (e) {
        console.warn(`Error occured during variable replacement, prop is ${prop}`, e, scene);
      }

      return toReturn;
    });
  },

  toScene(scene) {
    if (!this.currentScene || this.currentScene.$$route !== scene.$$route) {
      this.callOnDestroy().then(() => {
        var sceneInstance = typeof scene === 'function' ? new scene(scene.$$param) : scene;
        var preload = (sceneInstance.preload && sceneInstance.preload()) || null;
        var oldScene = this.currentScene;
  
        Promise.resolve(preload).then(() => {
          sceneInstance.$$id = scene.$$id;
          sceneInstance.$$route = scene.$$route;
          this.changeRoute(sceneInstance);
          this.instantiate(sceneInstance);
          var timeOnPreviousScreenInMilliseconds = this.getTimeOnScene();
          this.lastSceneChange = new Date();
          this.setDataAttributeOnWrapper();
          eventBus.$emit(VisEvent.SCENE_CHANGE, { newScene: sceneInstance, oldScene, timeOnPreviousScreenInMilliseconds });
        });
      });
    }
  },

  getTimeOnScene() {
    if (!this.lastSceneChange) return null;
    return new Date().getTime() - this.lastSceneChange.getTime();
  },

  setDataAttributeOnWrapper(subtitle) {
    const title = Navigation.getSceneTitle(this.currentScene, subtitle);
    this.app.setAttribute('data-current-scene', title);
  },

  callOnDestroy() {
    return new Promise(resolve => {
      TweenMax.killAll();
      clearAllTimeouts();
      if (this.currentScene && typeof this.currentScene.onDestroy === "function") {
        Promise.resolve(this.currentScene.onDestroy()).then(() => {
          timeout(() => resolve());
        });
      } else {
        timeout(() => resolve());
      }
    });
  },

  changeRoute(newScene) {
    var href = window.location.href;
    href = href.substring(href.lastIndexOf('/'));
    window.history.pushState(newScene.$$route, newScene.$$route, newScene.$$route);
  },

  transitionLeave(node, scene) {
    if (!scene) return;
    var leaveTransition = scene.transition && scene.transition.leave ? scene.transition.leave : null;
    this.transitionFactory.createTransition(leaveTransition)(node, TransitionDirection.LEAVE);
  },

  transitionEnter(node, scene) {
    if (!scene) return;
    var enterTransition = scene.transition && scene.transition.enter ? scene.transition.enter : null;
    this.transitionFactory.createTransition(enterTransition)(node, TransitionDirection.ENTER);
  },

  attachClickHandlers(el, scene) {
    var clickAttr = el.attributes.click;
    if (clickAttr) {
      var method = scene[clickAttr.nodeValue];
      if (method) {
        var previousOnClick = el.onclick;
        el.onclick = () => { 
          previousOnClick && previousOnClick();
          method.apply(scene);
        };
      }
    }
  
    if (el.children) {
      for (var i = 0; i < el.children.length; i++) {
        this.attachClickHandlers(el.children[i], scene);
      }
    }
  },
  
  setBackground(bg, instantBgChange) {
    var tl = new TimelineMax();
    tl.to(this.app, instantBgChange ? 0 : 1, {backgroundColor: bg});
  },

  getCurrentNode() {
    return this.app.childNodes[this.app.childNodes.length-1];
  },

  findNextScene(keys) {
    var go = false;
    for (var i in keys) {
      var key = keys[i];
      if (go) {
        return Navigation.idNavMap[key].scene;
      }

      if (key === this.currentScene.$$id) {
        go = true;
      }
    }
  },

  previousScene() {
    var prev = this.findNextScene(Object.keys(Navigation.idNavMap).reverse());
    prev && this.toScene(prev);
  },

  nextScene() {
    var next = this.findNextScene(Object.keys(Navigation.idNavMap));
    next && this.toScene(next);
  }
};
