import './facs-sectors-model.scss';
import { DataSource } from "../../scripts/datasource";
import { typewriterAnimation } from '../../animations/typewriter/typewriter.animation';
import { lineByLineReveal } from '../../animations/line-by-line-reveal/line-by-line-reveal.animation';
import { InteractionDirection, InteractionHandler } from '../../scripts/interaction-handler';
import { Director } from '../../scripts/director';
import { timeout } from '../../scripts/util';

export function baseFacsSectorsScene(background, preloadData) {

        this.template = `
        <div class="page-wrapper facs-sectors-page-wrapper" id="facs-sectors-page-wrapper-id">
            <div class="facs-sectors-page-header" id="facs-sectors-page-header-id">
                <div class="logo-wrapper"><a target="_blank" href="http://www.itware.hu"><img class="header-visualization-poc-logo" id="facs-sectors-page-logo" src="{{ text.logo }}"></a></div>
                <a href="{{ text.subscribe.url }}" target="_blank" class="subscribe"><span>{{ text.subscribe.text }}</span><i class="material-icons">exit_to_app</i></a>
            </div>
            <div class="facs-sectors-content-wrapper">
                <div id="facs-sectors-content-id" class="facs-sectors-content col-lg-6 col-md-12">{{ text.description }}</div>
                <div class="progress-bar-wrapper col-lg-6 col-md-12">
                    <div class="progress-bar-content">
                        <div class="progress-bar-text" id="prog-bar-negative">{{ text.barText.negative }}</div>
                        <div class="facs-sectors-exposure-bar scale col-lg-8 col-md-8" id="progress-bar">
                            <div class="exposure-bar">
                                <div class="red1 first base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-r2">
                                <div class="red2 base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-r3">
                                <div class="red3 base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-y1">
                                <div class="orange base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-y2">
                                <div class="yellow base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-g1">
                                <div class="green1 base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-g2">
                                <div class="green2 base-bar"></div>
                            </div>
                            <div class="exposure-bar" id="exposure-bar-g3">
                                <div class="green3 last base-bar"></div>
                            </div>
                        </div>
                        <div class="progress-bar-text" id="prog-bar-positive">{{ text.barText.positive }}</div>
                    </div>
                    <div class="exposure-bar-label" id="exposure-bar-id">{{ text.barText.label }}</div>
                </div>
            </div>
        </div>
                `;
        this.background = background;
        this.text = {};
        this.exposureLabelEl = null;
        this.pageWrapper = null;
        this.facsTextEl = null;
        this.progBarNegative = null;
        this.progBarPositive = null;
        this.barList = [];

        this.preload = function () {
            return new Promise(resolve => {
                DataSource.fetch('text').then(txt => {this.text = txt.pages[preloadData]; resolve();});
            });
        };

        this.init = function () {
            this.initElements();
            if (background !== 'white') {
                this.pageWrapper.classList.add('dark');
            }
            this.animation();

            timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { Director.nextScene(); }); }, 1000);
            timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { Director.previousScene(); }); }, 1000);
        };
        
        this.initElements = function () {
            this.pageWrapper = document.getElementById('facs-sectors-page-wrapper-id');
            this.barList = document.getElementsByClassName('base-bar');
            this.exposureLabelEl = document.getElementById('exposure-bar-id');
            this.progBarNegative = document.getElementById('prog-bar-negative');
            this.progBarPositive = document.getElementById('prog-bar-positive');
            this.facsTextEl = document.getElementById('facs-sectors-content-id');
        };
        
        this.loadBar = function () {
            for (let i = 0; i < 8; i++) {
                timeout(() => {
                    this.barList[i].classList.add('animate-bar');
                }, 200 * i);
            }
        };

        this.animation = function () {
            typewriterAnimation(this.facsTextEl);
            timeout(() => this.loadBar(), 2000);
            lineByLineReveal(this.progBarNegative, {delay: 2000});
            lineByLineReveal(this.exposureLabelEl, {delay: 2400});
            lineByLineReveal(this.progBarPositive, {delay: 3000});
        };
};