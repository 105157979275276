import './faang-chart.scene.scss';
import { Canvas, CanvasLine, CanvasText, CanvasRect } from '../../scripts/canvas';
import { TimelineMax } from "gsap";
import { InteractionDirection, InteractionHandler } from '../../scripts/interaction-handler';
import { Director } from '../../scripts/director';
import { DataSource } from '../../scripts/datasource';
import { FactorDataSource } from '../../scripts/factordatasource';
import { timeout, blackOrWhiteColor, blackOrWhiteBackground, blackOrWhiteColorWithOpacity } from '../../scripts/util';
import { autocomplete } from '../../scripts/autocomplete';
import { GTAGHandler } from '../../scripts/gtaghandler';
import { accordion } from "../../scripts/accordion";

export default function FaangChartScene() {
	return {
    height: window.innerHeight,
    template: `
    <div id="faang-chart" style="color: ${blackOrWhiteColor()}">
      <div class="title-container">
        <div class="factor title factortitle">
          <p>{{text.factorTitle}}</p>
        </div>
      </div>
      <div class="scene-container">
        <div id="labels" class="factor"> </div>
        <div class="chart title">
          <h3>{{text.title}}</h4>
        </div>        
        <div id="faang-canvas-container" class="chart">
          <div id="faang-overlay-tooltip" class="overlay-tooltip"><img src="/assets/images/move.png"/></div>
        </div>
      </div>
      <div id="accordion-container">
        <div id="stock-container" class="gray-wrapper ${blackOrWhiteBackground()}">
          <div id="company-stocks" style="width: 100%" panel="Stocks" collapsed>
            <span id="close-btn" class="close" style="display: none">&times;</span>
            <div id="faang-switches" class="stock" > </div>
          </div>
       </div>
       <div id="add-company" class="gray-wrapper ${blackOrWhiteBackground()}">
          <div id="add" style="width: 100%" panel="Add Stock" collapsed>
            <input id="faang-company-input" type="text" placeholder="Choose a company" autocomplete="off">
          </div>
       </div>
      </div>  
    </div>`,
    background: blackOrWhiteBackground(),
    transition: {
      enter: 'slide',
      leave: 'slide'
    },

    methods: {},

    scene: null,
    camera: null,
    renderer: null,
    resizeTimer: null,
    bgCanvas: null,
    data: [],
    text: [],
    charts: [],
    chartsShown: [],
    legendRowHeight: 94,
    legendSize: 76,
    labelHeight: 80,
    switchLineHeight: '36px',
    stopRender: false,
    needsUpdate: false,
    middle: window.innerWidth * 0.6 / 2,

    addChartsToPreload() {
      this.data.data.forEach(item => {
        if(this.data.preloadedChartNames.includes(item.assetName)) {
          const chart = {
            assetId: item.assetId,
            shares: item.shares,
            price: item.price,
            weight: item.weight,
            gicsSector: item.gicsSector,
            gicsSectorCode: item.gicsSectorCode,
            label: item.assetName,
            values: [item.value, item.size, item.momentum, item.quality, item.yield, item.volatility, item.growth, item.liquidity
            ]
          };
          if (!item.color) {
            chart.color = '#' + ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6);
          } else {
            chart.color = item.color;
          }
          if(item.switch) {
            chart.switch = item.switch;
          }
          this.charts.push(chart);
        }
      });
    },

    preload() {
      return new Promise(resolve => {
        Promise.all([DataSource.fetch('faangDiagram'), DataSource.fetch('text')]).then(values => {
          this.data = values[0];
          let max = 0;

          this.addChartsToPreload();

          this.charts.forEach(chart => {
            chart.values.forEach(value => {
              max = Math.max(max, Math.abs(value));
            });
          });

          if (max > 1) {
            this.charts.forEach(chart => {
              chart.values = chart.values.map(value => value / max);
            });
          }

          this.text = values[1].pages.faangDiagram;
          resolve();
        });
      });
    },

    init() {
      setTimeout(()=> {
          InteractionHandler.on(InteractionDirection.DOWN, () => {
            this.stopRender = true;
            Director.nextScene();
          });
        }, 1000);
        timeout(() => {
          InteractionHandler.on(InteractionDirection.UP, () => {
            this.stopRender = true;
            Director.previousScene();
          });
        }, 1000);

        this.setSceneCameraControls();
        this.calculateLegendSize();
        this.createModal();

        let addCompany = document.getElementById('add-company');
        addCompany.appendChild(document.getElementById('add'));
        let stocks = document.getElementById('stock-container');
        stocks.appendChild(document.getElementById('company-stocks'));

        let ac = accordion(stocks);
        accordion(addCompany);

        autocomplete(document.getElementById("faang-company-input"), FactorDataSource);
        this.drawLegend();
        this.draw3dSceneBackground();
        this.draw();

        this.createCharts(0);

        this.charts.forEach((chart, i) => {
          if (chart.switch && chart.switch === 'off') {
            document.getElementById(`${chart.label}-switch`).click();
          }
        });
        this.introAnimation(this.controls, ac);
        setTimeout(()=>{
          ac.toggle(0);
        },1800);
    },

    introAnimation(controls) {
      timeout(() => {
        const tl = new TimelineMax();
        const dummy = { v: 0 };
        tl.to(dummy, 2, { v: 10, onUpdate: tween => {
            controls.rotateLeft(-Math.sin(tween.progress() * 10) / 40 * (1 - tween.progress()));
            controls.rotateUp(-Math.cos(tween.progress() * 10) / 40 * (1 - tween.progress()));
          }, onUpdateParams: ['{self}']}, '+=2');
        const tooltip = document.getElementById('faang-overlay-tooltip');
        tl.to(tooltip, 1, {opacity: 1, onComplete: () => {
            controls.addEventListener('change', () => {
              tl.to(tooltip, 1, {opacity: 0});
            });
          }});
      }, 1000);
    },

    drawLegend() {
      const labelContainer = document.getElementById('labels');

      this.data.legend.forEach((label, lidx) => {
        const legendFrame = document.createElement('div');
        legendFrame.className = 'circle-label';
        labelContainer.appendChild(legendFrame);

        const legend = document.createElement('div');
        legend.className = 'circle-' + this.legendSize;
        legend.style.backgroundColor = label.color;
        legend.style.backgroundImage = "url(" + label.image + ")";
        legendFrame.appendChild(legend);

        const legendText = document.createElement('span');
        legendText.innerText = label.label;
        legendFrame.appendChild(legendText);

        const tl = new TimelineMax();
        const delay = 1 + lidx * 0.1;
        tl.to(legendFrame, 0.4, {left: '50%'}, `=+${delay}`);
        tl.to(legendFrame, 1, {opacity: 1}, '=-0.4');
      });
    },

    calculateLegendSize() {
      if (this.data.legend.length * 94 > window.innerHeight - this.labelHeight) {
        if (this.data.legend.length * 76 > window.innerHeight - this.labelHeight) {
          this.legendRowHeight = 44;
          this.legendSize = 'sm';
        } else {
          this.legendRowHeight = 76;
          this.legendSize = 'md';
        }
      } else {
        this.legendRowHeight = 96;
        this.legendSize = 'bg';
      }
    },

    setCanvas(bgCanvas) {
      const width = window.innerWidth * 0.6;
      const height = window.innerHeight - this.labelHeight;

      bgCanvas.nativeCanvas.width = width * 4;
      bgCanvas.nativeCanvas.height = height * 4;
      bgCanvas.add(new CanvasRect([0, 0, width * 4, height * 4 - 400 + 4*18], {color: blackOrWhiteColorWithOpacity(0.03)}));

      for (let i = 1; i < 6; i++) {
        bgCanvas.add(new CanvasLine([i * width * 4 / 6, 0, i * width * 4/ 6, height * 4 - 400 + 4*18], {
          color: blackOrWhiteColorWithOpacity(0.2),
          width: 4
        }));
      }

      bgCanvas.add(new CanvasText(this.text.negative, {
        top: (height - this.labelHeight) * 4,
        left: 50,
        size: 18 * 4,
        color: blackOrWhiteColorWithOpacity(0.6)
      }));

      bgCanvas.add(new CanvasText(this.text.zero, {
        top: (height - this.labelHeight) * 4,
        left: width * 2,
        origin: 'center',
        size: 18 * 4,
        color: blackOrWhiteColorWithOpacity(0.6)
      }));

      bgCanvas.add(new CanvasText(this.text.positive, {
        top: (height - this.labelHeight) * 4,
        left: width * 4 - 50,
        origin: 'right',
        size: 18 * 4,
        color: blackOrWhiteColorWithOpacity(0.6)
      }));
      bgCanvas.render();
    },

    draw3dSceneBackground() {
      this.bgCanvas = new Canvas(document.createElement('canvas'));
      this.setCanvas(this.bgCanvas);

      const texture = new THREE.Texture(this.bgCanvas.nativeCanvas);
      texture.magFilter = THREE.NearestFilter;
      texture.minFilter = THREE.LinearFilter;
      texture.generateMipmaps = false;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

      const bgGeo = new THREE.PlaneGeometry(10 * this.camera.aspect, 10);
      const bgMat = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
      if (!this.bgMesh) {
        this.bgMesh = new THREE.Mesh(bgGeo, bgMat);
      } else {
        this.bgMesh.geometry.dispose();
        this.bgMesh.geometry = bgGeo;
        this.bgMesh.material.dispose();
        this.bgMesh.magFilter = bgMat;
      }
      // bgGeo.scale(1,1,this.camera.aspect);
      this.bgMesh.receiveShadow = true;
      this.bgMesh.position.set(0, 0, -0.1);
      this.bgMesh.material.map.needsUpdate = true;
      this.scene.add(this.bgMesh);
    },

    createCharts(fromIdx) {
      this.charts.forEach((chart, idx) => {
        if (idx < fromIdx) {
          return;
        }
        chart.timeline = new TimelineMax({ onUpdate: () => { this.needsUpdate = true; }});
        createHtmlForSwitch(chart);
        createCanvasForChart(chart, this.middle, this.labelHeight);
        createLineForChart(chart, this.middle, this.legendRowHeight);
        this.addEventListenerToSwitch(chart, this.middle);
        this.addEventListenerToRemoveButton(chart, this.middle);
        animateChart(fromIdx,idx, chart, this.switchLineHeight, this.camera, this.renderer, this.scene);
        document.getElementById(`${chart.label}-switch`).click();
      });

      function createHtmlForSwitch(chart) {
        chart.div = document.createElement('div');
        chart.div.innerHTML = `
        <div id="${chart.label}-stock" style="white-space: nowrap; display: flex; margin-top: 5px">
          <label class="switch" for="${chart.label}-switch">
            <input type="checkbox" id="${chart.label}-switch"/>
            <div class="slider round" style="background-color: ${chart.color};"></div>
          </label>
          <span style="color: ${blackOrWhiteColor()}; display: inline-block; vertical-align: top; font-size: 12pt; margin-left: 24px">${chart.label}</span>
          <div style="flex: 1 auto"></div>
          <div style="display: inline-block; margin: auto; vertical-align: top">
            <i id="${chart.label}-remove" style="padding-top: 5px; cursor: pointer" class="material-icons">remove_circle_outline</i>
          </div>
        </div>`;
        document.getElementById('faang-switches').appendChild(chart.div);
      }

      function createCanvasForChart(chart, middle, labelHeight) {
        chart.htmlcanvas = document.createElement('canvas');
        chart.htmlcanvas.width = window.innerWidth * 0.6;
        chart.htmlcanvas.height = window.innerHeight - labelHeight;
        chart.canvas = new Canvas(chart.htmlcanvas);
        chart.centerLine = new CanvasLine([middle, 0, middle, chart.htmlcanvas.height - labelHeight], {
          color: blackOrWhiteColorWithOpacity(0.24),
          width: 1
        });
        chart.canvas.add(chart.centerLine);
      }

      function createLineForChart(chart, middle, legendRowHeight) {
        chart.objects = [];
        chart.values.forEach((value, i) => {
          const line = new CanvasLine([middle, (i + 0.25) * legendRowHeight, middle, (i + 0.25 ) * legendRowHeight], {
            lineCap: 'round',
            cropLineCap: true,
            color: chart.color,
            width: 12
          });
          chart.objects.push(line);
          chart.canvas.add(line);
        });
        chart.canvas.render();
      }

      function animateChart(fromidx, idx, chart, switchLineHeight, camera, renderer, scene) {
        const tl = new TimelineMax();
        const delay = fromIdx ? 0 : 1 + idx * 0.2;

        if (window.innerHeight <= 600) {
          if (window.innerHeight <= 425) {
            switchLineHeight = '16px';
          } else {
            switchLineHeight = '24px';
          }
        }
        tl.to(chart.div, 0.4, {opacity: 1}, `=+${delay}`);
        chart.div.children[0].children[1].style.lineHeight = '60px';
        tl.to(chart.div.children[0].children[1], 0.4, {lineHeight: switchLineHeight}, '=-0.4');

        const texture = new THREE.Texture(chart.htmlcanvas);
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.LinearFilter;
        texture.generateMipmaps = false;
        texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

        const chartGeo = new THREE.PlaneGeometry(10 * camera.aspect, 10);
        const chartMat = new THREE.MeshBasicMaterial({ map: texture, opacity: 0, transparent: true});
        chart.mesh = new THREE.Mesh(chartGeo, chartMat);
        chart.mesh.receiveShadow = true;
        chart.mesh.position.set(0, 0, 0/*idx / 5*/);
        scene.add(chart.mesh);
      }
    },

    addEventListenerToSwitch(chart, middle) {
    let checkbox = document.getElementById(`${chart.label}-switch`);
    checkbox.addEventListener('click', (event) => {
      GTAGHandler.addClickEvent(event);
      chart.animating = true;
      chart.objects.forEach((obj, i) => {
        const tl = new TimelineMax();
        tl.to(obj.coords, 1, {2: middle + (checkbox.checked ? chart.values[i] * middle * 0.95 : 0)});
      });
      if (checkbox.checked) {
        clearTimeout(chart.timeoutId);
        chart.timeline.kill();
        if (this.chartsShown.indexOf(chart) === -1) {
          this.chartsShown.push(chart);
          chart.mesh.position.z = this.chartsShown.indexOf(chart) * 0.2;
        }
        chart.timeline.to(chart.mesh.material, 0.2, {opacity: 1}, '-1');
      } else {
        chart.timeoutId = timeout(() => {
          this.chartsShown.splice(this.chartsShown.indexOf(chart), 1);
          chart.timeline.kill();
          chart.timeline.to(chart.mesh.material, 0.2, {opacity: 0}, '-1');
          this.chartsShown.forEach((chart, idx) => {
            chart.timeline.kill();
            chart.timeline.to(chart.mesh.position, 1, {z: idx * 0.2});
          });
        }, 1000);
      }
    });
  },

    addEventListenerToRemoveButton(chart, middle) {
    let removeButton = document.getElementById(`${chart.label}-remove`);
    removeButton.addEventListener("click", (event) => {
      GTAGHandler.addClickEvent(event);
      const tl = new TimelineMax({
        onComplete: () => {
          chart.animating = false;
          this.chartsShown.splice(this.chartsShown.indexOf(chart), 1);
          this.charts.splice(this.charts.indexOf(chart), 1);
          chart.timeline.kill();
          chart.timeline.to(chart.mesh.material, 0.2, {opacity: 0}, '-1');
          this.chartsShown.forEach((item, idx) => {
            item.timeline.kill();
            item.timeline.to(item.mesh.position, 1, {z: idx * 0.2});
          });
        }
      });
      chart.objects.forEach((obj, i) => {
        chart.animating = true;
        tl.to(obj.coords, 1, {2: middle}, `=-${i?1:0}`);
      });
      document.getElementById(`${chart.label}-stock`).remove();
    });
  },

    setSceneCameraControls() {
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera( 2, (window.innerWidth * 0.6) / (window.innerHeight - this.labelHeight), 10, 1000 );
      this.camera.position.set(0,0,280);
      this.camera.lookAt(0, 0, 0);
      this.scene.add(new THREE.AmbientLight(0xffffff));

      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      const rh = window.innerHeight - this.labelHeight;
      const rw = window.innerWidth * 0.6;
      this.rendererAspect = rw / rh;
      this.renderer.setSize( 960, 960 * this.rendererAspect);

      this.renderer.domElement.style.transformOrigin = 'top left';
      this.renderer.domElement.style.transform = `scale(${window.innerWidth / 1600}, ${(window.innerHeight - this.labelHeight) / (960 * this.rendererAspect)}`;
      this.renderer.domElement.style.position = 'absolute';

      const container = document.getElementById('faang-canvas-container');
      container.appendChild(this.renderer.domElement);
      container.style.left = `${(window.innerWidth - 960 * window.innerWidth / 1600) / 2}px`;
      container.style.width = `${960 * window.innerWidth / 1600}px`;
      this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
      this.controls.addEventListener('change', () => {
        this.needsUpdate = true;
      });
      const maxRotation = 30;
      this.controls.minPolarAngle = Math.PI / 2 - Math.PI * maxRotation / 180;
      this.controls.maxPolarAngle = Math.PI / 2 + Math.PI * maxRotation / 180;
      this.controls.minAzimuthAngle = -Math.PI * maxRotation / 180;
      this.controls.maxAzimuthAngle = Math.PI * maxRotation / 180;
      this.controls.enableZoom = false;
      this.controls.enablePan = false;
    },

    createModal() {
      let input = document.getElementById("faang-company-input");
      input.style.background = blackOrWhiteBackground();
      input.style.color = blackOrWhiteColor();

      input.addEventListener('change', (e) => {
        FactorDataSource.fetchByAsset(input.value).then(item => {
          const chart = {
            label: item.assetName,
            color: '#' + ('000000' + Math.random().toString(16).slice(2, 8)).slice(-6),
            switch: 'on',
            values: [item.value, item.size, item.momentum, item.quality, item.yield, item.volatility, item.growth, item.liquidity
            ]
          };

          const validationList = this.charts.map(data => data.label);

          if(!validationList.includes(chart.label) && this.charts.length < 10) {
            this.charts.push(chart);
            this.createCharts(this.charts.length - 1);
          }
        });
      });

      document.getElementById('add-company').addEventListener('click', (e) => {
        e.stopPropagation();
        input.value = '';
        input.click();
      });
    },

    draw() {
      const renderFrame = (time) => {
        this.charts.forEach(chart => {
          if (chart.animating) {
            chart.canvas.render();
            chart.mesh.material.map.needsUpdate = true;
            this.needsUpdate = true;
          }
        });
        this.controls.update();
        if (this.needsUpdate) {
          this.renderer.render(this.scene, this.camera);
          this.needsUpdate = false;
        }
        if (!this.stopRender) {
          requestAnimationFrame(renderFrame);
        }
      };
      requestAnimationFrame(renderFrame);
    },

    /* onResize() {

  const width = window.innerWidth * 0.6;
  const height = window.innerHeight - this.labelHeight;

  this.renderer.domElement.style.transformOrigin = 'top left';
  this.renderer.domElement.style.transform = `scale(${width / 960}, ${height / (960 * this.rendererAspect)}`;

  this.charts.forEach(chart => {
    const middle = width / 2;
    chart.canvas.resize( width, height );
    chart.objects.forEach((line, idx) => {
      line.coords = [middle, (idx + 0.25) * this.legendRowHeight, middle, (idx + 0.25) * this.legendRowHeight];
    });

    chart.centerLine.coords = [middle, 0, middle, chart.htmlcanvas.height - this.labelHeight];
  });
  // this.bgCanvas.resize( width, height );
  this.camera.aspect = width/height;

  this.calculateLegendSize(height);
  this.draw3dSceneBackground();

  clearTimeout(this.resizeTimer);
  this.resizeTimer = timeout(() => {

    this.charts.forEach(chart => {
      const middle = chart.htmlcanvas.width / 2;
      let checkbox = document.getElementById(`${chart.label}-switch`);
      chart.objects.forEach((line, idx) => {
        //line.coords = [middle, (idx + 0.5)* this.legendRowHeight, middle, (idx + 0.5) * this.legendRowHeight];
        const tl = new TimelineMax();
        tl.to(line.coords, 1, {2: middle + (checkbox.checked ? chart.values[idx] * middle * 0.95  : 0)}, checkbox.checked ? '=+0.2' : '');
      });
    });

    this.calculateSwitchHeight(window.innerHeight);

    for ( let i = 0; i < this.switchesContainer.children.length; i++) {
      const div = this.switchesContainer.children[i];
      if (div.nodeType == 1) {
        div.children[0].children[1].style.lineHeight = this.switchLineHeight;
      }
    };


    this.drawLegend();
    this.bgMesh.geometry.scale(1, 1, this.camera.aspect);
  }, 500);
},*/
  };
}
