export function dialog(rootElement, content, appendDirectly, onCloseFn) {
  const modal = document.createElement('div');
  modal.className = 'modal';
  const modalContent = appendDirectly ? content : document.createElement('div');
  modalContent.className = 'modal-content';
  if (!appendDirectly) {
    modalContent.appendChild(content);
  }
  modal.appendChild(modalContent);
  const closeButton = document.createElement('button');
  closeButton.innerText = 'x';
  closeButton.className = 'dialog-close-button';
  closeButton.addEventListener('click', () => api.close());
  modalContent.appendChild(closeButton);
  rootElement.appendChild(modal);
  const closeFn = (event) => {
    if (event.target === modal && event.target !== modal.firstChild) {
      modal.style.display = "none";
      if (onCloseFn) {
        onCloseFn();
      }
    }
  };

  const api = {
    element: modal,
    
    content: modalContent,

    open: () => {
      modal.style.display = 'block';
      // window.addEventListener('mousedown', closeFn);
    },

    close: () => {
      modal.style.display = 'none';
      // window.removeEventListener('mousedown', closeFn);
    },

    destroy: () => {
      rootElement.removeChild(modal);
    }
  };

  return api;
}