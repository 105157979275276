import { liftUpAnimation } from "../../animations/lift-up/lift-up.animation";
import { typewriterAnimation } from "../../animations/typewriter/typewriter.animation";
import { lineByLineReveal } from "../../animations/line-by-line-reveal/line-by-line-reveal.animation";
import './sub-page.scene.scss';
import { DataSource } from "../../scripts/datasource";
import { Director } from "../../scripts/director";
import { InteractionHandler, InteractionDirection } from "../../scripts/interaction-handler";
import { circleButtonAnimation } from '../../animations/circle-button/circle-button.animation';
import { GTAGHandler } from "../../scripts/gtaghandler";
import { timeout } from "../../scripts/util";

export default function SubPageScene() {
    return {
        template: `
        <div class="page-wrapper sub-page-wrapper">
            <div class="sub-page-header" id="sub-page-header-id">
                <div class="logo-wrapper"><a target="_blank" href="http://www.itware.hu"><img class="header-visualization-poc-logo" id="sub-page-logo" src="assets/images/itware.png"></a></div>
                <a href="{{ text.subscribe.url }}" target="_blank" class="subscribe" id="sub-page-subscribe"><span>{{ text.subscribe.text }}</span><i class="material-icons">exit_to_app</i></a>
            </div>
            <div class="rightContent">
                <div class="sub-page-content-wrapper col-lg-8 col-md-12">
                    <div id="sub-page-topic" class="topic">{{ text.topic }}</div>
                    <div id="sub-page-title">{{ text.title }}</div>
                    <div id="sub-page-subtitle">{{ text.subtitle }}</div>
                    <div id="sub-page-content1">{{ text.content1 }}</div>
                    <p><div id="sub-page-content2">{{ text.content2 }}</div></p>
                </div>
            </div>
            <button id="sub-page-arrow-top" class="scene-arrow top black">↑</button>
            <button id="sub-page-arrow-bottom" class="scene-arrow bottom black">↓</button>
        </div>
                `,
        transition: {
            enter: 'fade',
            leave: 'slide'
        },
        background: 'white',
        topicEl: null,
        titleEl: null,
        content1El: null,
        content2El: null,
        logoEl: null,
        subscribeEl: null,
        text: {},

        preload() {
            return new Promise(resolve => {
                DataSource.fetch('text').then(txt => {this.text = txt.pages.subPage; resolve();});
            });
        },

        init() {
            this.getElements();
            this.animation();
            timeout(() => { InteractionHandler.on(InteractionDirection.DOWN, () => { Director.nextScene(); }); }, 1000);
            timeout(() => { InteractionHandler.on(InteractionDirection.UP, () => { Director.previousScene(); }); }, 1000);
            const topArrow = document.getElementById('sub-page-arrow-top');
			topArrow.addEventListener('click', () => Director.previousScene());
			circleButtonAnimation(topArrow);
			const tlTop = new TimelineMax();
			tlTop.to(topArrow, 3, {top: 60, ease: Elastic.easeInOut});
			tlTop.to(topArrow, 3, {top: 65, ease: Elastic.easeInOut});
			tlTop.repeat(1000);
			const bottomArrow = document.getElementById('sub-page-arrow-bottom');
			bottomArrow.addEventListener('click', () => Director.nextScene());
			circleButtonAnimation(bottomArrow);
			const tlBottom = new TimelineMax();
			tlBottom.to(bottomArrow, 3, {bottom: 60, ease: Elastic.easeInOut});
			tlBottom.to(bottomArrow, 3, {bottom: 65, ease: Elastic.easeInOut});
			tlBottom.repeat(1000);
        },

        getElements() {
            this.logoEl = document.getElementById('sub-page-logo');
            this.subscribeEl = document.getElementById('sub-page-subscribe');
            this.topicEl = document.getElementById('sub-page-topic');
            this.titleEl = document.getElementById('sub-page-title');
            this.subtitleEl = document.getElementById('sub-page-subtitle');
            this.content1El = document.getElementById('sub-page-content1');
            this.content2El = document.getElementById('sub-page-content2');
        },

        animation() {
            liftUpAnimation(this.topicEl, {delay: 200});
            liftUpAnimation(this.logoEl, {delay: 400});
            liftUpAnimation(this.subscribeEl, {delay: 400, removeActiveClassDelay: 3000});
            typewriterAnimation(this.titleEl, {delay: 1200});
            typewriterAnimation(this.subtitleEl, {delay: 2000});
            lineByLineReveal(this.content1El, {delay: 3200});
            lineByLineReveal(this.content2El, {delay: 3500});
        }
    };
};