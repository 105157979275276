import './line-by-line-reveal.scene.scss';
import { lineByLineReveal } from '../../animations/line-by-line-reveal/line-by-line-reveal.animation';

export default function LineByLineRevealScene() {
  return {
    template: `
      <div class='vertical-center text-justify'>
        <div id="linebylinetext" class="lbl-text">With all the media attention on technology and FAANG stocks, it's no wonder that the information technology sector is often considered a growth sector. But did you know that the information technology sector's growth has been in steady decline?</div>
      </div>`,
    background: 'white',

    init() {
      var animtext1 = document.getElementById("linebylinetext");
      lineByLineReveal(animtext1);
    }
  };
};