import './lift-up.scene.scss';
import { liftUpAnimation } from '../../animations/lift-up/lift-up.animation';

export default function LiftUpScene() {
  return {
    template: `
      <div class='vertical-center text-center'>
        <div id="animtext1" class="lift-up-text full-width">We make it easier for investors to get all the facts and fiugres they need to put together and</div>
        <div id="animtext2" class="lift-up-text full-width">implement a well-informed factor investing strategy. We call it MSCI FaCS and this is how it works.</div>
      </div>`,
    background: 'white',

    init() {
      var animtext1 = document.getElementById("animtext1");
      var animtext2 = document.getElementById("animtext2");
      liftUpAnimation(animtext1);
      liftUpAnimation(animtext2, { delay: 500 });
    }
  };
};