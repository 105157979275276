import { Director } from "./director";
import { eventBus, VisEvent } from "./eventbus";
import { isMobile, timeout } from "./util";
import subPageScene from "../scenes/sub-page/sub-page.scene";
import subPageFundsScene from "../scenes/sub-page-funds/sub-page-funds.scene";
import { ekgStockDiagramScene, ekgFundDiagramScene } from "../scenes/ekg-diagram/ekg-diagram.scene";
import fallingBallsScene from "../scenes/fallingballs/falling-balls.scene";
import faangPreTextPageScene from "../scenes/faang-pre-text-page/faang-pre-text-page.scene";
import fundsPreTextPageScene from "../scenes/funds-pre-text-page/funds-pre-text-page.scene";
import facsTourFirstPage from "../scenes/facs-sectors/facs-tour-first-page.scene";
import floatingBallsScene from "../scenes/floating-balls/floating-balls.scene";
import facsTextPage from "../scenes/facs-sectors/facs-text-page.scene";
import landingScene from "../scenes/landing/landing.scene";
import faangChartScene from "../scenes/faang-chart/faang-chart.scene";
import { factorBoxFundScene, factorBoxStockScene } from "../scenes/factor-box/factor-box.scene";
import { scene3d, scene3dTour } from "../scenes/3d-scene-1/3d-scene-1";

const template = `<nav id='navigation' style="transform-origin: top right; transform: scale(${window.innerWidth / 1920});"><div id="navigation-toggle" class="icon">menu</div></nav>`;
const NAV_POINT_RADIUS = 40;
const NAV_DEFAULT_HEIGHT = 40;

export const Navigation = {
  navigationRoot: null,
  navigationPoints: [],
  isOpen: false,
  idNavMap: {},
  currentSelected: null,

  init(rootElementId, navigationPoints) {
    return new Promise((resolve, reject) => {
      if (!navigationPoints || navigationPoints.length === 0) {
        reject();
        return;
      }
  
      this.navigationPoints = navigationPoints;
      var rootElement = document.getElementById(rootElementId);
      rootElement.parentElement.innerHTML += template;
      this.navigationRoot = document.getElementById('navigation');
      this.navigationRoot.style.top = `${15 * window.innerHeight / 1080}px`;
      const toggle = document.getElementById('navigation-toggle');
      this.addNavigationPoints();
      // this.navigationRoot.onmouseover = () => !isMobile() && this.open();
      // this.navigationRoot.onmouseleave = () => !isMobile() && this.close();
      toggle.onclick = () => this.toggle();
      this.open();
  
      eventBus.$on(VisEvent.SCENE_CHANGE, ({newScene}) => {
        this.selectNavigationPoint(newScene.$$id);
      });

      resolve();
    });
  },

  selectNavigationPoint(id) {
    var delay = isMobile() ? 1000 : 0;

    timeout(() => {
      this.currentSelected && this.currentSelected.$$pointElement.classList.remove('selected');
      this.currentSelected && this.currentSelected.$$titleElement.classList.remove('selected');
  
      var nav = this.idNavMap[id];
      if (!nav) return;
  
      nav.$$pointElement.classList.add('selected');
      nav.$$titleElement.classList.add('selected');
      this.currentSelected = nav;
    }, delay);
  },

  clear() {
    this.navigationRoot.childNodes.forEach(el => el.remove());
  },

  setPosition(obj, rect) {
    obj.style.top = rect.top - rect.height + 'px';

    if (window.innerWidth > 680) {
      obj.style.right = window.innerWidth - rect.left + 20 + 'px';
    } else {
      obj.style.left = rect.left + 30 + 'px';
    }
  },

  addNavigationPoints() {
    this.navigationPoints.forEach(nav => {
      var span = document.createElement('span');
      this.navigationRoot.appendChild(span);
      
      var title = document.createElement('label');
      title.innerText = nav.title;
      title.className = 'navigation-title';
      var rect = span.getBoundingClientRect();
      this.navigationRoot.parentElement.appendChild(title);
      this.setPosition(title, rect);
      nav.$$titleElement = title;
      nav.$$pointElement = span;
      this.idNavMap[nav.scene.$$id] = nav;

      if (!isMobile()) {
        span.onmouseover = () => {
          nav.$$titleElement.classList.add('show');
        }; 
  
        span.onmouseleave = () => { 
          nav.$$titleElement.classList.remove('show');
        };
      }

      span.onclick = (e) => {
        e.stopImmediatePropagation();
        Director.toScene(nav.scene);
      };
    });
  },

  open() {
    this.navigationRoot.style.height = NAV_POINT_RADIUS * this.navigationPoints.length-1 + 'px';
    this.isOpen = true;

    if (isMobile()) {
      timeout(() => {
        if (!this.isOpen) {
          return;
        } 

        this.navigationPoints.forEach(nav => {
          nav.$$titleElement.classList.add('show');
          var rect = nav.$$pointElement.getBoundingClientRect();

          if (window.innerWidth > 680) {
            nav.$$titleElement.style.right = window.innerWidth - rect.left + 20 + 'px';
            if (!this.isSelected(nav)) {
              nav.$$titleElement.style.top = rect.top - rect.height + 'px';
            } else {
              nav.$$titleElement.style.top = rect.top - 3.6 + 'px';
            }
          } else {
            nav.$$titleElement.style.left = rect.left + 30 + 'px';
            nav.$$titleElement.style.top = rect.top - rect.height + 'px';
          }
          
          nav.$$titleElement.style.opacity = 1;
        });
      }, 1000);
    }
  },

  isSelected(nav) {
    return this.currentSelected && this.currentSelected.scene && this.currentSelected.scene.$$id === nav.scene.$$id;
  },

  close() {
    this.navigationRoot.style.height = NAV_DEFAULT_HEIGHT + 'px';
    this.isOpen = false;
    isMobile() && this.hideTitles();
  },

  hideTitles() {
    this.navigationPoints.forEach(nav => {
      nav.$$titleElement.style.opacity = 0;
      nav.$$titleElement.style.top = "";
    });
  },

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  },

  getSceneTitle(scene, subtitle) {
    if (!scene) return null;
    var nav = this.idNavMap[scene.$$id];
    if (!nav) return null;
    return subtitle ? nav.subtitle : nav.title;
  }
};

export const NAVIGATION_KEY_MAP = {
  landingScene: landingScene,
  subPageScene: subPageScene,
  ekgStockDiagramScene: ekgStockDiagramScene,
  subPageFundsScene: subPageFundsScene,
  ekgFundDiagramScene: ekgFundDiagramScene,
  facsTextPageScene: facsTextPage,
  fallingBallsScene: fallingBallsScene,
  faangPreTextPageScene: faangPreTextPageScene,
  fundsPreTextPageScene: fundsPreTextPageScene,
  facsTourFirstPage: facsTourFirstPage,
  floatingBallsScene: floatingBallsScene,
  //faangChartScene: faangChartScene,
  factorBoxStockScene: factorBoxStockScene,
  factorBoxFundScene: factorBoxFundScene,
  scene3d: scene3d,
  scene3dTour: scene3dTour,
};

export function navigationConfigToScenes(config) {
  var scenes = [];

  config.forEach(item => {
    scenes.push({
      title: item.title,
      subtitle: item.subtitle,
      scene: NAVIGATION_KEY_MAP[item.scene]
    });
  });

  return scenes;
};