export class GTAGService {
  constructor(gtagTrackingId) {
    var script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtagTrackingId}');`;
    document.head.appendChild(script);
  }

  addScreenView (screenName, previousScreenName, timeOnPreviousScreenInMilliseconds) {
    waitForGoogleAnalytics().then(() => {
      var customEvent = this.createEvent('Vis3DPageViewEvent', {
        screenName,
        previousScreenName,
        timeOnPreviousScreenInMilliseconds
      });
      
      this.fireEvent(customEvent);
    });
  }

  addFocusEvent(target, category) {
    waitForGoogleAnalytics().then(() => {
      var customEvent = this.createEvent('Vis3DFocusEvent', {
        target,
        category
      });
      
      this.fireEvent(customEvent);
    });
  }

  addClickEvent(target) {
    waitForGoogleAnalytics().then(() => {
      var customEvent = this.createEvent('VisClickEvent', target);
      
      this.fireEvent(customEvent);
    });
  }

  createEvent(name, details) {
    return new CustomEvent(name, {
      detail: details
    });
  }

  fireEvent(event) {
    window.dispatchEvent(event);
  }
}

function waitForGoogleAnalytics() {
  return new Promise(periodicAnalyticsCheck);
}

function periodicAnalyticsCheck(resolve) {
  if (window.ga && window.ga.getAll) {
    resolve();
    return;
  }

  setTimeout(() => {
    periodicAnalyticsCheck(resolve);
  }, 200);
}