import { TimelineMax } from "gsap";
import { blackOrWhiteBackground } from "./util";

export function tab(content, prefix) {
  const onSelectedIndexChangedCallbacks = [];
  const onContentCollapsedCallbacks = [];
  const onContentExpandedCallbacks = [];
  const tabs = content.querySelectorAll('[tab]');
  const tabHeader = document.createElement('div');
  const contentWrapper = document.createElement('div');

  let selectedIndex = 0;
  let collapsed = false;
  
  const selectTab = (index) => {
    selectedIndex = index;
    let lastWidth = content.offsetWidth;
    tabs.forEach((tab, idx) => {
      tab.style.display = idx === selectedIndex ? 'block' : 'none';
    });
    tabHeader.childNodes.forEach((item, idx) => {
      if (idx === index) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
    onSelectedIndexChangedCallbacks.forEach(callbackFn => callbackFn(selectedIndex));
    setTimeout(() => {
      if (content.offsetWidth < lastWidth) {
        //content.style.minWidth = lastWidth + 'px';
      }
      //content.style.right = '75px';
    });
  };

  tabHeader.className = 'tab-header';
  tabs.forEach((tab, idx) => {
    contentWrapper.appendChild(tab);
    tab.classList.add('tab-content');
    const item = document.createElement('div');
    item.id = `${prefix ? prefix + '-' : ''}${tab.getAttribute('tab').toLowerCase().replace(/ /g, '-')}-tab`;
    item.innerHTML = tab.getAttribute('tab');
    item.className = `tab-header-item ${blackOrWhiteBackground()}`;
    item.addEventListener('click', () => {
      selectTab(idx);
      if (collapsed) {
        api.expandContent();
      }
    });
    tabHeader.appendChild(item);
  });

  content.insertBefore(contentWrapper, content.childNodes[0]);
  content.insertBefore(tabHeader, content.childNodes[0]);
  selectTab(0);

  const api = {
    onSelectedIndexChanged: (callbackFn) => {
      onSelectedIndexChangedCallbacks.push(callbackFn);
    },

    onContentCollapsed: (callbackFn) => {
      onContentCollapsedCallbacks.push(callbackFn);
    },

    onContentExpanded: (callbackFn) => {
      onContentExpandedCallbacks.push(callbackFn);
    },

    collapseContent: () => {
      collapsed = true;
      contentWrapper.style.display = 'block';
      contentWrapper.style.height = contentWrapper.offsetHeight + 'px';
      contentWrapper.style.overflow = 'hidden';
      contentWrapper.tl = contentWrapper.tl || new TimelineMax();
      contentWrapper.tl.kill();
      contentWrapper.tl.to(contentWrapper, 0.4, { height: 0 });
      onContentCollapsedCallbacks.forEach(callbackFn => callbackFn(selectedIndex));
    },

    expandContent: () => {
      collapsed = false;
      contentWrapper.tl = contentWrapper.tl || new TimelineMax();
      contentWrapper.tl.kill();
      contentWrapper.tl.to(contentWrapper, 0.4, {
        height: tabs[selectedIndex].offsetHeight,
        onComplete: () => {
          contentWrapper.style.height = '';
        }
      });
      onContentExpandedCallbacks.forEach(callbackFn => callbackFn(selectedIndex));
    },

    isCollapsed: () => {
      return collapsed;
    },

    getSelectedIndex: () => {
      return selectedIndex;
    }
  };

  return api;
}