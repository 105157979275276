export const conf = {
	//base value = 1
	ballmovementx : 150,
	gravity: 3,
	drag: 0.1,
	density: 1.6,
	fps : 1/60,
	acceleration: 9.81,
	friction: 0.95,
	collisionOnWall: -0.7,
	ballCollisionFriction: 0.7,
	massCollision: 1
};