import '../styles/index.scss';
import './polyfills';
import '../vendor/js/jquery-3.1.1.min.js';
import { Director } from './director';
import { scene3d, scene3dTour, scene3dAdmin } from "../scenes/3d-scene-1/3d-scene-1";

import * as OrbitControls from '../vendor/js/controls/OrbitControls.js';
import * as TrackballControls from '../vendor/js/controls/TrackballControls.js';
import * as PointerLockControls from '../vendor/js/controls/PointerLockControls.js';
import * as CopyShader from '../vendor/js/shaders/CopyShader';
import * as FXAAShader from '../vendor/js/shaders/FXAAShader';
import * as EffectComposer from '../vendor/js/postprocessing/EffectComposer';
import * as RenderPass from '../vendor/js/postprocessing/RenderPass';
import * as ShaderPass from '../vendor/js/postprocessing/ShaderPass';
import * as OutlinePass from '../vendor/js/postprocessing/OutlinePass';
import { iNoBounceInit } from '../vendor/js/inobounce.js';
import example2Scene from '../scenes/example-2-scene/example-2.scene';
import example1Scene from '../scenes/example-1-scene/example-1.scene';
import { DataSource } from './datasource';
import { FactorDataSource } from './factordatasource';
import fallingBallsScene from '../scenes/fallingballs/falling-balls.scene';
import curvedTextScene from '../scenes/curved-text/curved-text.scene';
import bubbleMenuScene from '../scenes/bubble-menu/bubble-menu.scene';
import liftUpScene from '../scenes/lift-up/lift-up.scene';
import buttonFillScene from '../scenes/button-fill/button-fill.scene';
import typewriterScene from '../scenes/typewriter/typewriter.scene';
import lineByLineRevealScene from '../scenes/line-by-line/line-by-line-reveal.scene';
import floatingBallsScene from '../scenes/floating-balls/floating-balls.scene';
import switchExamplesScene from '../scenes/switch-examples/switch-examples.scene';
import arrowControlsScene from '../scenes/arrow-controls/arrow-controls.scene';
import { ekgFundDiagramScene, ekgStockDiagramScene } from '../scenes/ekg-diagram/ekg-diagram.scene';
import landingScene from '../scenes/landing/landing.scene';
import faangChartScene from '../scenes/faang-chart/faang-chart.scene';
import subPageScene from '../scenes/sub-page/sub-page.scene';
import subPageFundsScene from '../scenes/sub-page-funds/sub-page-funds.scene';
import { InteractionHandler } from './interaction-handler';
import spinnerEffectScene from '../scenes/spinner-effect/spinner-effect.scene';
import faangPreTextPageScene from '../scenes/faang-pre-text-page/faang-pre-text-page.scene';
import { BrowserChecker } from './browser-checker';
import facsTextPageScene from '../scenes/facs-sectors/facs-text-page.scene';
import { Navigation, navigationConfigToScenes } from './navigation';
import { Help } from './help';
import { portraitBlock } from './portrait-block';
import { requestFullscreen } from './request-fullscreen';
import { GTAGHandler } from './gtaghandler';
import { factorBoxFundScene, factorBoxStockScene } from "../scenes/factor-box/factor-box.scene";
import { FundFactorDataSource } from './fundfactordatasource';
import fundsPreTextPageScene from '../scenes/funds-pre-text-page/funds-pre-text-page.scene';

iNoBounceInit(window);
iNoBounce.enable();

const routes = {
  '#': landingScene,
  '#example2': example2Scene,
  '#interact': scene3d,
  '#heatmap-tour': {
    scene: scene3dTour,
    param: 'tour'
  },
  '#3d-admin': {
    scene: scene3dAdmin,
    param: 'tour'
  },
  '#consumer-discretionary-facs': fallingBallsScene,
  '#curved': curvedTextScene,
  '#bubble': bubbleMenuScene,
  '#liftup': liftUpScene,
  '#buttonfill': buttonFillScene,
  '#typewriter': typewriterScene,
  '#linebyline': lineByLineRevealScene,
  '#contact': floatingBallsScene,
  '#switches': switchExamplesScene,
  '#arrows': arrowControlsScene,
  '#historical-exposure-of-stocks': {
    scene: ekgStockDiagramScene,
    param: 'stock'
  },
  '#ekg-example': {
    scene: ekgFundDiagramScene,
    param: 'fund'
  },
  '#introduction': landingScene,
  //'#faang-factor-box': faangChartScene,
  '#bar-chart-example': {
    scene: factorBoxStockScene,
    param: 'stock'
  },
  '#fund-factor-box': {
    scene: factorBoxFundScene,
    param: 'fund'
  },
  '#why': subPageScene,
  '#the-facs-report-funds': subPageFundsScene,
  '#spinner-effect': spinnerEffectScene,
  '#facs-on-faang': faangPreTextPageScene,
  '#facs-on-funds': fundsPreTextPageScene,
  '#facs-text-page': facsTextPageScene,
  '#heatmap': scene3dTour,
};

const ROOT_ELEMENT_ID = 'visualization-poc';

BrowserChecker.check().then(() => {
  
  generateIds(routes);
  DataSource.init('assets/data.json');
  FactorDataSource.init('assets/data.json');
  FundFactorDataSource.init('assets/data.json');
  portraitBlock(ROOT_ELEMENT_ID);
  InteractionHandler.init();
  document.addEventListener('fullscreenchange', (e) => {
    if (!document.fullscreenElement) {
      requestFullscreen('root');
    }
  });
  document.addEventListener('MSFullscreenChange', (e) => {
    if (!document.fullscreenElement) {
      requestFullscreen('root');
    }
  });
  GTAGHandler.init().then(() => {
    configTitle();

    Help.init().then(() => {
      Help.show(ROOT_ELEMENT_ID);
    });

    DataSource.fetch('navigation').then(nav => {
      const NAVIGATION_POINTS = navigationConfigToScenes(nav);
      Navigation.init(ROOT_ELEMENT_ID, NAVIGATION_POINTS);
      var scenes = [scene3d];
      NAVIGATION_POINTS.forEach(np => scenes.push(np.scene));
      requestFullscreen('root').then(() => {
        Director.init({ 
          scenes,
          routes,
          rootElement: ROOT_ELEMENT_ID
        });
      });
    });
  });
}).catch((e) => {
  var msg = 'Unknown error occured.';
  if (e.message === 'unsupported browser') {
    msg = 'Your browser is not supported. Please update your browser or switch to the latest version of Chrome, Edge, Firefox or Safari.';
  }

  console.log(e);
  document.getElementById(ROOT_ELEMENT_ID).innerHTML = `<div class="vertical-center text-center">${msg}</div>`;
});

function generateIds(routes) {
  Object.keys(routes).forEach(key => {
    var obj = routes[key];
    if (obj.scene && obj.param) {
      obj = obj.scene;
    }
    obj.$$id = Math.random().toString(36).substr(2, 9);
  });
}

function configTitle() {
  DataSource.fetch('title').then(title =>
    document.getElementsByTagName('title')[0].innerHTML = title
  );
}